/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { REACT_APP_GOOGLE_API_KEY } from "../../constants/env";
import swal from "sweetalert";

let autoComplete;
let focusedOut;
const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(
  updateQuery,
  updateLat,
  updateLng,
  handleCoordonee,
  restaurantLocation,
  handleChoosePlaceWithPicker
) {
  let placeSelected = false;

  autoComplete = new window.google.maps.places.Autocomplete(
    document.getElementById("adressMaps"),

    {
      //types: ["(cities)"],
      //regions: "administrative_area_level_1",
      types: ["address"],
      componentRestrictions: { country: "fr" },
    }

    //{ componentRestrictions: { country: "fr" } }
  );

  autoComplete.setFields([
    "address_components",
    "formatted_address",
    "geometry",
  ]);
  autoComplete.addListener("place_changed", () => {
    console.log("this is place changed");
    handlePlaceSelect(
      updateQuery,
      updateLat,
      updateLng,
      handleCoordonee,
      restaurantLocation,
      handleChoosePlaceWithPicker
    );
  });
  new window.google.maps.event.addDomListener(
    document.getElementById("adressMaps"),
    "focusout",
    function () {
      window.google.maps.event.trigger(this, "focus", {});
      window.google.maps.event.trigger(this, "keydown", {
        keyCode: 13,
      });
    }
  );
}
function handleAdress(
  addressObject,
  updateQuery,
  updateLat,
  updateLng,
  handleCoordonee,
  restaurantLocation
) {
  const addressComponent = addressObject.address_components;
  const query = addressObject.formatted_address;
  const lat = addressObject.geometry.location.lat();
  const lng = addressObject.geometry.location.lng();
  const p1Lat = restaurantLocation.lat;
  const p1Lng = restaurantLocation.lng;

  const p1 = new window.google.maps.LatLng(p1Lat, p1Lng);
  const p2 = new window.google.maps.LatLng(lat, lng);

  const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
    p1,
    p2
  );

  updateQuery(query);
  updateLat(lat);
  updateLng(lng);

  const city = addressComponent.find(
    (elem) => elem.types.includes("locality") === true
  );
  const zipCode = addressComponent.find(
    (elem) => elem.types.includes("postal_code") === true
  );

  handleCoordonee(
    query,
    lat,
    lng,
    distance,
    city ? city.long_name : "",
    zipCode ? zipCode.long_name : ""
  );
}
async function handlePlaceSelect(
  updateQuery,
  updateLat,
  updateLng,
  handleCoordonee,
  restaurantLocation,
  handleChoosePlaceWithPicker
) {
  const addressObject = autoComplete.getPlace();
  if (addressObject.address_components) {
    handleAdress(
      addressObject,
      updateQuery,
      updateLat,
      updateLng,
      handleCoordonee,
      restaurantLocation
    );
  } else if (
    addressObject.address_components === undefined &&
    document.getElementById("adressMaps").value.length > 0
  ) {
    console.log(addressObject);
    console.log("condition 2 adress object is not correct");
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode(
      {
        address: document.getElementById("adressMaps").value,

        componentRestrictions: { country: "fr" },
      },
      function (results, status) {
        if (
          status === window.google.maps.GeocoderStatus.OK &&
          results.length > 0 &&
          results[0].types.includes("street_address") === true
        ) {
          // set it to the correct, formatted address if it's valid
          document.getElementById("adressMaps").value =
            results[0].formatted_address;

          console.log(results[0]);
          handleAdress(
            results[0],
            updateQuery,
            updateLat,
            updateLng,
            handleCoordonee,
            restaurantLocation
          );
        } else {
          handleChoosePlaceWithPicker();
        }
      }
    );
  } else {
    handleChoosePlaceWithPicker();
  }
}
function SearchLocationInput(props) {
  const query = props.formatedAdress;
  const setQuery = props.setFormatedAdress;
  //const [query, setQuery] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [location, setLocation] = useState({});
  /*
  useEffect(() => {
    if (props.formatedAdress !== "") {
      setQuery(props.formatedAdress);
    }
  }, [props.formatedAdress]);
*/
  useEffect(() => {
    //loadScript(
    //  `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_API_KEY}&libraries=places`,
    //  () =>
        handleScriptLoad(
          setQuery,
          setLat,
          setLng,
          props.handleCoordonee,
          props.restaurantLocation,
          props.handleChoosePlaceWithPicker
        )
    //);
  }, []);

  return (
    <div className="search-location-input">
      <input
        id="adressMaps"
        className="form-control"
        ref={props.register}
        onChange={(event) => setQuery(event.target.value)}
        onBlur={(event) => setQuery(event.target.value)}
        placeholder={props.placeholder}
        value={query}
        name={props.name}
      />
    </div>
  );
}

export default SearchLocationInput;
