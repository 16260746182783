import { images } from "../constants/env";
const theme = {
  topHeader: {
    backgroundColor: "#1D2228",

    topHeaderNav: {
      BgColor: "#86977b",
      aColor: "#fff",
      aHoverColor: "#fff",
      aHoverBgColor: "#5c6954",
    },
    topHeaderContacts: {
      a: {
        color: "#fff",
        hover: "#86977b",
      },
    },
  },
  navBar: {
    Menu: {
      a: {
        color: "#1D2228",
      },
    },
    NavbarToggler: {
      a: {
        color: "#1D2228",
      },
      span: {
        bgColor: "#4e4e4e",
      },
    },
    MenuToggler: {
      span: {
        bgColor: "#1D2228",
      },
    },
  },
  sideDrawerNav: {
    nav: {
      bgColor: "#fff",
    },
    list: {
      color: "#1D2228",
    },
  },
  Infographic: {
    icon: {
      color: "#86977b",
    },
    title: {
      color: "#1D2228",
    },
    text: {
      color: "#515151",
    },
  },
  HowWeDo: {
    TitleSection: {
      firstTitle: {
        color: "#86977b",
      },
      secondTitle: {
        color: "#1D2228",
      },
      text: {
        color: "#515151",
      },
    },
    ButtonSection: {
      bgColor: "#86977b",
      color: "#fff",
    },
  },
  bannerV1: {
    carousselItemText: {
      bgColor: "#f9f9f9",
      h1: {
        color: "#1d2228",
      },
      h4: {
        color: "#1d2228",
      },
      p: {
        color: "#848486",
      },
      a: {
        bgColor: "#86977b",
        color: "#fff",
      },
      mediaMaxWidth1200: {
        color: "#fff",
      },
    },
    dots: {
      bgColor: "rgba(0, 0, 0, 0.4)",
      slickActiveButtonBgColor: "red",
      listButtonBgColor: "#fff",
    },
  },
  cart: {
    modal: {
      bgColor: "#fff",
      headerCartModal: {
        h3: {
          color: "#1d2228",
        },
      },
      scroll: {
        scrollbarTrackBgColor: "#f1f1f1",
        scrollbarThumbBgColor: "#888",
        scrollbarThumbHoverBgColor: "#555",
      },
      footerCartModal: {
        bgColor: "#f9f9f9",
      },
      closeButton: {
        bgColor: "transparent",
        spanBgColor: "#1D2228",
        detailsCartItem: {
          borderColor: "#9F9E9E",
          spanBgColor: "#9F9E9E",
        },
      },
    },
    cartItem: {
      borderBottomColor: "#9f9e9e",
      mediaCartItem: {
        titleColor: "#1D2228",
        priceQuantityColor: "#848486",
      },
      detailsCartItem: {
        color: "#515151",
      },
    },
    CartTable: {
      thColor: "#fff",
      thBgColor: "#1d2228",
      tdColorBgColor: "#f9f9f9",
      quantiteInputColor: "#6e6e6e",
      quantiteInputBGColor: "#fff",
      quantiteInputBorderColor: "#e5e1dc",
      productBody: {
        titleColor: "#1d2228",
        textColor: "#848486",
      },
    },
    CartForm: {
      titleColor: "#1D2228",
      table: {
        BorderBottomColor: "rgba(0,0,0,.1)",
        thColor: "#fff",
        thBgColor: "#1D2228",
        tdBgColor: "#f9f9f9",
      },
    },
  },
  customButton: {
    primaryBgColor: "#86977b",
    primaryBgColorHover: "#5c6954",
    color: "#fff",
    boxShadow: "rgba(134, 151, 123, 0.5)",
    secondaryBgColor: "#fccc4c",
    secondaryBgColorHover: "#e8b93f",
    boxShadowSecandary: "rgba(252, 204, 76, 0.5)",
  },
  trending: {
    TitleSection: {
      subTitleColor: "#86977b",
      titleColor: "#1D2228",
      paragraphColor: "#515151",
    },

    NavigationBottom: {
      color: "#1D2228",
      borderColor: "#f9f9f9",
      onHoverColor: "#fff",
      onHoverBgColor: "#86977b",
    },
  },
  product: {
    // bgColor: "#f9f9f9",
    bgColor: "#fff",
    productTitleDescriptionColor: "#1D2228",
    productDescriptionTextColor: "#515151",
    productDescriptionPriceColor: "#1D2228",
    productDescriptionPriceBorderColor: "rgba(0,0,0,0.1)",
    productBodyBottomBorderColor: "rgba(0, 0, 0, 0.1)",
  },
  newsLetter: {
    bgImage: images.newsLetterBgImage,
    newsLetterBox: {
      bgColor: "#f9f9f9",
      titleColor: "#1d2228",
      textColor: "#515151",
      formInputColor: "#6e6e6e",
    },
  },
  footer: {
    bgColor: "#1D2228",
    titleColor: "#fff",
    listColor: "#b7b7b7",
    listColorOnHover: "#fff",
    iconColor: "#fff",
    facebookBgColor: "rgb(59, 87, 157)",
    instagramBgColor: "#e4405f",
    twitterBgColor: "rgb(44, 170, 225)",
    pinterestBgColor: "rgb(204, 33, 39)",
    paragraphTextColor: "#fff",
  },
  menu: {
    bgColor: "#86977b",
    categoryDescriptionColor: "#fff",
    iconColor: "#fff",
    borderImageColor: "rgba(255,255,255,.2)",
  },
  subHeader: {
    color: "#fff",
  },
  checkout: {
    BillingForm: {
      color: "#1D2228",
    },
    NoticeLogin: {
      bgColor: "#f9f9f9",
      textColor: "#515151",
      clickButtonColor: "#86977b",
    },
    OrderDetails: {
      thColor: "#fff",
      thBgColor: "#1D2228",
      tdBgColor: "#f9f9f9",
      tdBoerderBottomColor: "rgba(0,0,0,.1)",
      trTotalBgColor: "#1D2228",
      totalTextColor: "#fff",
      productColor: "#1D2228",
      productOnHoverColor: "#86977b",
    },
  },
  login: {
    boxShadowColor: "rgba(0, 0, 0, 0.1)",
    textColor: "#fff",
    authForm: {
      bgColor: "#f9f9f9",
      titleColor: "#1D2228",
      paragraphColor: " #515151",
      linkColor: "#1D2228",
    },
  },
  account: {
    profileImgInnerBgColor: "#86977b",
    iconBgColor: "#1D2228",
    iconColor: "#fff",
    sideBarBorderColor: "rgba(0, 0, 0, 0.06)",
    listColor: "#666",
    titleColor: "#212529",
  },
  about: {
    dotsColor: "#d1d4d8",
    descriptionSection: {
      titleColor: "#86977b",
      subTitleColor: "#1D2228",
      paragraphColor: "#515151",
    },
  },
  description: {
    productContent: {
      titleColor: "#1D2228",
      paragraphColor: "#515151",
    },
    formGroup: {
      labelColor: "#1D2228",
    },
    QuantityBgColor: "#fff",
    QuantityBorderColor: "rgba(0,0,0,.1)",
    list: {
      firstSpan: "#1D2228",
      secondSpan: "#848486",
    },
    relatedProductsTitle: "#1D2228",
  },
  constactUs: {
    infoBox: {
      bgColor: "#f9f9f9",
      titleColor: "#1d2228",
    },
    formSection: {
      textColor: "#515151",
      input: {
        borderColor: "#efefef",
        color: "#6e6e6e",
        backgroundColor: "#fff",
      },
    },
  },
  notFound: {
    color: "#515151",
  },
  images: {
    auth: images.auth,
  },
  globalStyle: {
    formControl: {
      placeholderColor: "#a5a5a5",
      borderColor: "#e5e1dc",
      color: "#6e6e6e",
      backgroundColor: "#fff",
    },
    formControlFocus: {
      borderColor: "#efefef",
    },
    linkHoverColor: "#86977b",
  },
  Preloader: {
    bgColor: "#fff",
    borderColor: "#FCCC4C",
  },
  ProductOption: {
    bgColor: "#fff",
    boxShaddow: "rgba(0, 0, 0, 0.15)",
    bBProductInformation: "#ebebeb",
    colorTitle: "#3e3e3e",
    colorPrice: "#707070",
    footerBorderColor: "#e5e5e5",
    quantityButtonColor: "blue",
    quantityIconColor: "#848486",
    requiredItemBGColor: "rgba(112, 112, 112, 0.1)",
    requiredItemColor: "#276fbf",
    borderColor: "rgb(235, 235, 235)",
  },
};
export default theme;
