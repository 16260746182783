/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Table } from "../../Checkout/OderDetails/style";
//import {ProductElement} from './style';
import { API, images } from "../../../constants/env";
import moment from "moment";
import "moment/locale/fr";
import { useParams } from "react-router-dom";
import { CheckoutText, MyAccountText } from "../../../constants/texts";
import { getToken } from "../../../services/LoadToken";
const OrderDetail = (props) => {
  const [order, setOrder] = useState([]);
  const [contentProducts, setContentProducts] = useState([]);

  let { id } = useParams();
  React.useEffect(() => {
    const fetchOrder = async () => {
      const token = getToken();
      await fetch(API.me.getOrderId(id), {
        method: "GET",
        headers: {
          "x-auth-token": token,
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
          } else {
            setOrder(response);
            setContentProducts(response.content);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };
    fetchOrder();
  }, [props.user]);
  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <div className="invoice-title">
                  <h4 className="float-right font-size-16">
                    <strong>
                      {MyAccountText.orderDetails.COMMANDE_TEXT} #{" "}
                      {order.reference}
                    </strong>
                  </h4>
                  <h3 className="mt-0">
                    <img src={images.logo} alt="logo" height={32} />
                  </h3>
                </div>
                <hr />
                {/*
                  <div className="row">
                  <div className="col-6">
                    <address>
                      <strong>Information Client:</strong>
                      <br />
                      {`${order.client? order.client.firstName: ''} ${order.client ? order.client.lastName : ''}`}
                      <br />
                      Email: {order.client ? order.client.email : ''}
                      <br />
                      Tel: {order.client? order.client.phone : ''}
                      <br />
                    </address>
                  </div>
                
                </div>
                  
                  */}

                <div className="row">
                  <div className="col-6 mt-4">
                    <address>
                      <strong>
                        {MyAccountText.orderDetails.PAYEMENT_MODE} :
                      </strong>
                      <br />
                      {order.paymentGateway}
                    </address>
                    <div />
                  </div>
                  <div className="col-6 mt-4 text-right">
                    <address>
                      <strong>
                        {MyAccountText.orderDetails.DELIVERY_DATE}:
                      </strong>
                      <br />
                      {moment(order.date)
                        .locale("fr")
                        .format("ddd DD MMMM YYYY HH:MM")}
                      <br />
                      <br />
                    </address>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div>
                  <div className="p-2">
                    <h3 className="font-size-16">
                      <strong>{MyAccountText.orderDetails.PRODUCT_LIST}</strong>
                    </h3>
                  </div>
                  <div>
                    <div className="table-responsive">
                      <Table className="table">
                        <thead>
                          <tr>
                            <th>{CheckoutText.orderDetails.PRODUCT_TEXT}</th>

                            <th>
                              {
                                CheckoutText.orderDetails
                                  .PRICE_AND_QUANTITY_TEXT
                              }{" "}
                              PRICE_AND_QUANTITY_TEXT
                            </th>

                            <th>{CheckoutText.orderDetails.TOTAL_TEXT}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {contentProducts.map((elem) => {
                            return (
                              <tr key={elem._id}>
                                <td data-title="Product">
                                  <div>
                                    <div>
                                      <h6>
                                        {" "}
                                        <a href="/">{elem.product.name}</a>{" "}
                                      </h6>
                                    </div>
                                  </div>
                                </td>

                                <td data-title="Quantity">
                                  {Number(elem.product.price).toFixed(2)}$ x
                                  {elem.qte}
                                </td>
                                <td data-title="Total">
                                  {" "}
                                  <strong>
                                    {Number(
                                      elem.product.price * elem.qte
                                    ).toFixed(2)}
                                    $
                                  </strong>{" "}
                                </td>
                              </tr>
                            );
                          })}

                          <tr className="total">
                            <td>
                              <h6 className="mb-0">
                                {CheckoutText.orderDetails.BIG_TOTAL}
                              </h6>
                            </td>
                            <td></td>

                            <td>
                              {" "}
                              <strong>{order.total}$</strong>{" "}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
