import styled from 'styled-components';

const BillingsCaontainer = styled.div`
    margin-top:20px;
    h4{
        margin: 0 0 20px;
        font-family: 'Oswald', serif;
        font-weight: 700;
        color: ${(props)=>props.theme.checkout.BillingForm.color};
    }
    label{
        margin-bottom: 10px;
        font-weight: 600;
        color: ${(props)=>props.theme.checkout.BillingForm.color};
    }
`;

const TimePicker = styled.div`
    display: flex;
    flex-direction: column;
    input{
        text-align: center;
    }
    button{
        border: none;
        outline: 0;
        background: 0 0;
        text-align: center;
        display: inline-block;
       text-align:center;

    }
    button span{
        display:block;
        transform: rotate(90deg);
        font-size: 25px;
        text-align: center;
    }
`

const LoaderPuff = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export {BillingsCaontainer, TimePicker, LoaderPuff};