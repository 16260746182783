import React, { useState, useEffect } from "react";
import {
  ContactUsContainer,
  MapsContainer,
  ContactContainer,
  ContactSection,
  ContactFormSection,
  InfoBox,
  TitleSection,
  LinkButton,
  InformationSpan,
} from "./style";
import { ContactUsText } from "../../constants/texts";
import { StyledButton } from "../CustomButton/style";
import MapComponent from "../MapComponent/index";
import { useForm } from "react-hook-form";
import { formErrors } from "../../constants/errors";
import { API, maps } from "../../constants/env";
import { contactUsSendMessage } from "../../constants/succesMessage";
import { Error } from "../FormError/style";
function Contact(props) {
  const [showAlertErrors, setShowAlertErrors] = useState(false);
  const [serverErrors, setserverErrors] = useState("");
  const [showSucces, setShowSucces] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data, e) => {
    const dataRequest = {
      firstName: data.fname,
      lastName: data.lname,
      email: data.email,
      subject: data.subject,
      body: data.message,
    };
    fetch(API.message.send, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataRequest),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          setserverErrors(response.message);
          setShowAlertErrors(true);
        } else {
          setShowSucces(true);
          e.target.reset();
          setTimeout(() => {
            setShowSucces(false);
          }, 3000);
          //await setTimeout(setShowSucces(false), 5000);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const weeksDay = ContactUsText.WEEKSDAY.split("_");
  return (
    <ContactUsContainer>
      <MapsContainer>
        <MapComponent
          drag={false}
          lat={props.location && props.location.lat ? props.location.lat : 0}
          lng={props.location && props.location.lng ? props.location.lng : 0}
        />
        <LinkButton
          className="maps primary"
          href={maps(props.location.lat, props.location.lng).url}
          target="_blank"
        >
          {props.content.buttonmaps ? props.content.buttonmaps : ""}
        </LinkButton>
      </MapsContainer>
      <ContactContainer>
        <ContactSection>
          <div className="container">
            <div className="row">
              <div className="col-xl-6">
                <InfoBox>
                  <i className="flaticon-location"></i>

                  <h5>
                    {props.content.box1Title ? props.content.box1Title : ""}
                  </h5>
                  <InformationSpan width="70%">
                    {props.siteInfo
                      ? props.siteInfo.adress
                      : ContactUsText.ADRESSE1_TEXT}
                  </InformationSpan>
                  <InformationSpan>
                    <a
                      href={`tel:${
                        props.siteInfo
                          ? props.siteInfo.phone
                          : ContactUsText.PHONE_NUMBER_TEXT
                      }`}
                    >
                      {props.siteInfo
                        ? props.siteInfo.phone
                        : ContactUsText.PHONE_NUMBER_TEXT}
                    </a>
                  </InformationSpan>
                  <InformationSpan>
                    <a
                      href={`mailto:${
                        props.siteInfo
                          ? props.siteInfo.email
                          : ContactUsText.MAIL_ADRESSE_TEXT
                      }`}
                    >
                      {props.siteInfo
                        ? props.siteInfo.email
                        : ContactUsText.MAIL_ADRESSE_TEXT}
                    </a>
                  </InformationSpan>
                </InfoBox>
              </div>
              <div className="col-xl-6">
                <InfoBox>
                  <i className="flaticon-online-booking"></i>
                  <h5>
                    {" "}
                    {props.content.box2Title ? props.content.box2Title : ""}
                  </h5>
                  {props.siteInfo ? (
                    props.siteInfo.planningOpenClose &&
                    props.siteInfo.planningOpenClose.length > 0 ? (
                      props.siteInfo.planningOpenClose.map((openClose, i) => (
                        <span>
                          {weeksDay[i]} {" : "} {openClose.from} {"-"}{" "}
                          {openClose.to}
                        </span>
                      ))
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                </InfoBox>
              </div>
            </div>
          </div>
        </ContactSection>
        <ContactFormSection>
          <div className="container">
            <TitleSection>
              <h2> {props.content.formTitle ? props.content.formTitle : ""}</h2>
              <p>
                {" "}
                {props.content.formDescription
                  ? props.content.formDescription
                  : ""}
              </p>
            </TitleSection>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="form-group col-lg-6">
                  <input
                    type="text"
                    placeholder={ContactUsText.FIRST_NAME_PALCEHOLDER}
                    className="form-control"
                    name="fname"
                    ref={register({
                      required: true,
                    })}
                  />
                  <Error>{errors.fname && formErrors.fnameRequired}</Error>
                </div>
                <div className="form-group col-lg-6">
                  <input
                    type="text"
                    placeholder={ContactUsText.LAST_NAME_PLACEHOLDER}
                    className="form-control"
                    name="lname"
                    ref={register({ required: true })}
                  />
                  <Error>{errors.lname && formErrors.lnameRequired}</Error>
                </div>
                <div className="form-group col-lg-12">
                  <input
                    type="email"
                    placeholder={ContactUsText.EMAIL_PLACEHOLDER}
                    className="form-control"
                    name="email"
                    ref={register({
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: formErrors.mailAdressValidity,
                      },
                    })}
                  />
                  <Error>{errors.email && errors.email.message}</Error>
                </div>
                <div className="form-group col-lg-12">
                  <input
                    type="text"
                    placeholder={ContactUsText.SUBJECT_PLACEHOLDER}
                    className="form-control"
                    name="subject"
                    ref={register({ required: true })}
                  />
                  <Error>{errors.subject && formErrors.subjectRequire}</Error>
                </div>
                <div className="form-group col-lg-12">
                  <textarea
                    name="message"
                    className="form-control"
                    placeholder={ContactUsText.MESSAGE_PLACEHOLDER}
                    ref={register({
                      required: true,
                    })}
                    rows="8"
                  ></textarea>
                  <Error>{errors.message && formErrors.messageRequired}</Error>
                </div>
              </div>
              {showAlertErrors ? (
                <div className="alert alert-danger" role="alert">
                  {serverErrors}
                </div>
              ) : (
                <div />
              )}
              {showSucces ? (
                <div className="alert alert-success" role="alert">
                  {contactUsSendMessage}
                </div>
              ) : (
                <div />
              )}
              <StyledButton className="primary" type="submit">
                {props.content.contentButton ? props.content.contentButton : ""}
              </StyledButton>
            </form>
          </div>
        </ContactFormSection>
      </ContactContainer>
    </ContactUsContainer>
  );
}

export default Contact;
