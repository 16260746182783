import styled from 'styled-components';
import {StyledButton} from '../CustomButton/style'
const ButtonContainer = styled.div`
    padding: 10px 10px;
    float: right;
`
const SaveButton = styled(StyledButton)`
    margin-left: 10px;
`

export {ButtonContainer, SaveButton}