import React, { useState } from "react";
import {
  LoginContainer,
  AuthDescription,
  AuthForm,
  AuthDescriptionContent,
  LoginSection,
} from "../Login/style";
import { API, STATIC } from "../../constants/env";
import { RegisterText } from "../../constants/texts";
import { Link } from "react-router-dom";
import { StyledButton } from "../CustomButton/style";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { registerFormErrors } from "../../constants/errors";
import { Error } from "../FormError/style";

const Register = (props) => {
  const [showAlertErrors, setShowAlertErrors] = useState(false);
  const [serverErrors, setserverErrors] = useState("");
  const { register, handleSubmit, errors } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  let history = useHistory();
  const [buttonWidth, setButtonWidth] = useState("");
  React.useEffect(() => {
    const width = document.getElementById("update").offsetWidth;
    setButtonWidth(width + "px");
  }, []);

  const onSubmit = async (data) => {
    setIsLoading(true);
    const dataRequest = {
      firstName: data.fname,
      lastName: data.lname,
      phone: data.phone,
      email: data.email,
      password: data.password,
    };

    fetch(API.auth.register, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataRequest),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          setserverErrors(registerFormErrors.failRegister);
          setShowAlertErrors(true);
          setIsLoading(false);
        } else {
          props.handleLogin(
            { email: data.email, password: data.password },
            history
          );
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
      });
  };
  return (
    <LoginSection>
      <div className="container">
        <LoginContainer>
          <AuthDescription
            image={
              props.content.image ? STATIC.images(props.content.image) : ""
            }
          >
            <AuthDescriptionContent>
              <i className="flaticon-chili"></i>
              <h2>
                {props.content.titleImage ? props.content.titleImage : ""}
              </h2>
              <p>
                {props.content.subTitleImage ? props.content.subTitleImage : ""}
              </p>
            </AuthDescriptionContent>
          </AuthDescription>
          <AuthForm>
            <h2>{props.content.title ? props.content.title : ""}</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              {showAlertErrors ? (
                <div className="alert alert-danger" role="alert">
                  {serverErrors}
                </div>
              ) : (
                <div />
              )}

              <div className="form-group">
                <input
                  type="text"
                  className="form-control form-control-light"
                  placeholder={RegisterText.FIRST_NAME_PLACEHOLDER}
                  name="fname"
                  ref={register({ required: true })}
                />
                <Error>
                  {errors.fname && registerFormErrors.fnameRequired}
                </Error>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control form-control-light"
                  placeholder={RegisterText.LAST_NAME_PLACEHOLDER}
                  name="lname"
                  ref={register({ required: true })}
                />
                <Error>
                  {errors.lname && registerFormErrors.lnameRequired}
                </Error>
              </div>
              <div className="form-group">
                <input
                  type="tel"
                  className="form-control form-control-light"
                  placeholder={RegisterText.PHONE_PLACEHOLDER}
                  name="phone"
                  ref={register({ required: true })}
                />
                <Error>
                  {errors.phone && registerFormErrors.phoneRequired}
                </Error>
              </div>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control form-control-light"
                  placeholder={RegisterText.EMAIL_PLACEHOLDER}
                  name="email"
                  ref={register({
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: registerFormErrors.mailAdressValidity,
                    },
                  })}
                />
                <Error>{errors.email && errors.email.message}</Error>
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control form-control-light"
                  placeholder={RegisterText.PASSWORD_PLACEHOLDER}
                  name="password"
                  ref={register({
                    required: {
                      value: true,
                      message: registerFormErrors.passwordRequired,
                    },
                    min: {
                      value: 6,
                      message: registerFormErrors.passwordLength,
                    },
                  })}
                />
                <Error>{errors.password && errors.password.message}</Error>
              </div>
              <StyledButton
                type="submit"
                className={`login primary ${isLoading ? "loading" : ""}`}
                disabled={isLoading ? true : false}
                width={buttonWidth}
                id="update"
              >
                {" "}
                {isLoading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : props.content.buttonContent ? (
                  props.content.buttonContent
                ) : (
                  ""
                )}
              </StyledButton>

              <p>
                {RegisterText.HAVE_ACCOUNT}{" "}
                <Link to="/login" className="forget-password">
                  {" "}
                  {RegisterText.LOGIN}
                </Link>{" "}
              </p>
            </form>
          </AuthForm>
        </LoginContainer>
      </div>
    </LoginSection>
  );
};

export default Register;
