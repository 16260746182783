import styled from 'styled-components';

const NotFoundContainer = styled.div`
    position: relative;
    padding: 100px 15px;
    overflow: hidden;
    display: flex;
    justify-content: center;

`
const NotFoundText = styled.div`
    text-align: center;
    h1 {
        font-size: 50px;
    line-height: 58px;
    }
    p {
        font-size: 15px;
        line-height: 28px;
        margin-bottom: 15px;
        color: ${props => props.theme.notFound.color};

    }
`

export {NotFoundContainer, NotFoundText}