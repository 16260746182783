import React, { useState, useEffect } from "react";

import CartTable from "../../components/Cart/CartTable/index";
import CartForm from "../../components/Cart/CartForm/index";
import { connect } from "react-redux";
import { setSubHeader } from "../../store/actions/stickyNavbarAction";
import { images } from "../../constants/env";
import { getProducts, getTotal, getQuantityTotal } from "../../store/Selectors";
import { SubHeaderText } from "../../constants/texts";
import { API, STATIC } from "../../constants/env";
import {
  removeItem,
  addQuantity,
  subtractQuantity,
} from "../../store/actions/cartActions";
const CartPage = (props) => {
  const menus = [
    { link: "/", title: SubHeaderText.HOME },
    { link: "/products", title: SubHeaderText.cart.TITLE },
  ];
  const [content, setContent] = useState(
    localStorage.getItem("CART") ? JSON.parse(localStorage.getItem("CART")) : {}
  );

  useEffect(() => {
    const fetchData = () => {
      fetch(API.content.load, {
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: "CART",
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          console.log("this is slides data ");
          console.log(response);
          if (JSON.stringify(response) !== localStorage.getItem("CART")) {
            localStorage.setItem("CART", JSON.stringify(response));
            setContent(response);
          }
        })
        .catch((e) => {});
    };
    fetchData();
  }, []);
  React.useEffect(() => {
    props.setSubHeader(
      true,
      content.title,
      STATIC.images(content.subHeaderImage),
      menus
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  //to remove the item completely
  const handleRemove = (id) => {
    props.removeItem(id);
  };
  //to add the quantity
  const handleAddQuantity = (id) => {
    props.addQuantity(id);
  };
  //to substruct from the quantity
  const handleSubtractQuantity = (id) => {
    props.subtractQuantity(id);
  };

  return (
    <div>
      <div className="container">
        <CartTable
          items={props.items}
          removeItem={handleRemove}
          addQuantity={handleAddQuantity}
          subtractQuantity={handleSubtractQuantity}
        />
        <CartForm total={props.total} content={content} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    // items: state.cartReducer.addedItems,
    items: getProducts(state),

    quantityTotal: getQuantityTotal(state),
    //  total: state.cartReducer.total,
    total: getTotal(state).toFixed(2),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSubHeader: (show, title, bgImage, menus) => {
      dispatch(setSubHeader(show, title, bgImage, menus));
    },
    removeItem: (item) => {
      dispatch(removeItem(item));
    },
    addQuantity: (id) => {
      dispatch(addQuantity(id));
    },
    subtractQuantity: (id) => {
      dispatch(subtractQuantity(id));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CartPage);
