import React from "react";
import { CartFormContainer, Table, TextArea } from "./style";
import { StyledLink } from "../../CustomButton/style";
import { TableText } from "../../../constants/texts";
import { withRouter } from "react-router-dom";

const CartForm = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [remarque, setRemarque] = React.useState("");
  const onHandleChange = (e) => {
    setRemarque(e.target.value);
  };
  return (
    <CartFormContainer>
      <div className="offset-lg-6 col-lg-6">
        <h4>{TableText.cartForm.TITLE}</h4>
        <Table>
          <tbody>
            <tr>
              <th>{TableText.cartForm.TOTAL}</th>
              <td>
                {" "}
                <b>{props.total} €</b>{" "}
              </td>
            </tr>
          </tbody>
        </Table>
        <label>Remarque</label>
        <TextArea
          className="form-control"
          rows="3"
          value={remarque}
          onChange={onHandleChange}
        ></TextArea>
        <StyledLink
          className="primary btn-block"
          to={{
            pathname: "/checkout",
            state: {
              remarque: remarque,
            },
          }}
        >
          {props.content ? props.content.contentButton : ""}
        </StyledLink>
      </div>
    </CartFormContainer>
  );
};

export default withRouter(CartForm);
