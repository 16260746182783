/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  CartModalContainer,
  HeaderCartModal,
  BodyCartModal,
  FooterCartModal,
  CloseButton,
} from "./style";
import { CartModalText } from "../../../constants/texts";
import { StyledLink } from "../../CustomButton/style";
import CartItem from "../CartItem/index";
function CartModal(props) {
  let drawerClasses;
  if (props.showValue) {
    drawerClasses = "drawer-transtion";
  }
  /*
  React.useEffect(()=>{
      let produits;
     
      props.items.forEach((item)=>{
      const productElem = props.products.find(elem => elem._id === item._id)
        produits.push(productElem);
      });
      console.log('taw taw taw ');
      console.log(produits);


  }, [props.items, props.products])
*/
  return (
    <CartModalContainer className={drawerClasses}>
      <HeaderCartModal>
        <h3>{CartModalText.HEADER_TITLE}</h3>
        <CloseButton onClick={props.close}>
          <span></span>
          <span></span>
        </CloseButton>
      </HeaderCartModal>
      <BodyCartModal>
        {props.items.length > 0 ? (
          props.items.map((elem, index) => {
            return (
              <CartItem
                key={index}
                picture={elem.picture}
                name={elem.name}
                quantity={elem.quantity}
                price={elem.price}
                item={elem}
                removeItem={props.removeItem}
                closeModal={props.close}
                index={index}
              />
            );
          })
        ) : (
          <p>{CartModalText.NOTHING_TEXT}</p>
        )}
      </BodyCartModal>
      <FooterCartModal>
        <h4>
          {CartModalText.FOOTER_TOTAL} <span>{props.total}€</span>
        </h4>

        <StyledLink
          className="btn-custom cart-modal"
          to="/cart"
          onClick={props.close}
        >
          {CartModalText.BUTTON_TEXT}
        </StyledLink>
      </FooterCartModal>
    </CartModalContainer>
  );
}

export default CartModal;
