import styled from "styled-components";
import { Link } from "react-router-dom";
const StyledLink = styled(Link)`
  cursor: pointer;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  position: relative;
  background-color: ${(props) => props.theme.customButton.primaryBgColor};
  line-height: 24px;
  border: 0;
  color: ${(props) => props.theme.customButton.color};
  font-size: 14px;
  font-weight: 600;
  padding: 12px 30px;
  transition: 0.3s;
  text-transform: uppercase;
  border-radius: 25px;
  z-index: 1;
  box-shadow: 4px 3px 24px ${(props) => props.theme.customButton.boxShadow};

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: ${(props) =>
      props.theme.customButton.primaryBgColorHover};
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 25px;
    z-index: -1;
    transition: 0.3s;
    transform-origin: center;
    transform: scale(0);
  }

  &:hover::before,
  &:focus::before {
    transform: scale(1);
  }

  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.customButton.color};
  }
  &:hover,
  &:focus {
    color: ${(props) => props.theme.customButton.color}
    outline: none;
  }
  &.primary {
    background-color: ${(props) => props.theme.customButton.primaryBgColor};
    box-shadow: 4px 3px 24px ${(props) => props.theme.customButton.boxShadow};
  }
  &.secondary {
    background-color: ${(props) => props.theme.customButton.secondaryBgColor};
    box-shadow: 0 3px 24px ${(props) =>
      props.theme.customButton.boxShadowSecandary};
  }
  &.secondary::before {
    background-color: ${(props) =>
      props.theme.customButton.secondaryBgColorHover};
  }
  &.maps {
    position: absolute;
    bottom: 0px;
    left: 0;
    margin: 15px;
    z-index: 300;
  }
  &.btn-sm {
    padding: 8px 20px;
    font-size: 12px;
  }

  &.checkout-form {
    font-size: 14px;
    margin: 20px 0;
    width: 100%;
  }

  &.login {
    margin: 20px 0;
  }
  &.cart-modal {
    @media (max-width: 768px) {
      width: 100%;
      margin-top: 20px;
    }
  }
  &.notice-login {
    box-shadow: none;
  }
`;
const StyledButton = styled.button`
  cursor: pointer;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  position: relative;
  background-color: ${(props) => props.theme.customButton.primaryBgColor};
  line-height: 24px;
  border: 0;
  color: ${(props) => props.theme.customButton.color};
  font-size: 14px;
  font-weight: 600;
  padding: 12px 30px;
  transition: 0.3s;
  text-transform: uppercase;
  border-radius: 25px;
  z-index: 1;
  box-shadow: 4px 3px 24px ${(props) => props.theme.customButton.boxShadow};

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: ${(props) =>
      props.theme.customButton.primaryBgColorHover};
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 25px;
    z-index: -1;
    transition: 0.3s;
    transform-origin: center;
    transform: scale(0);
  }

  &::after {
    content: "";
    width: 0;
    height: 0;
    background-color: ${(props) =>
      props.theme.customButton.primaryBgColorHover};
    position: absolute;
    top: 100%;
    left: 100%;
    border-radius: 25px;
    z-index: -1;
    transition: 0.3s;
    transform-origin: center;
    transform: scale(0);
  }

  /*
  &:hover::after,
  &:active::after,
  &:focus::after {
    transform: scale(1);
  }
  */

  &:hover::before {
    transform: scale(1);
  }

  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.customButton.color};
  }
  &:hover,
  &:focus {
    color: ${(props) => props.theme.customButton.color};
    outline: none;
  }
  &.primary {
    background-color: ${(props) => props.theme.customButton.primaryBgColor};
    box-shadow: 4px 3px 24px ${(props) => props.theme.customButton.boxShadow};
  }
  &.secondary {
    background-color: ${(props) => props.theme.customButton.secondaryBgColor};
    box-shadow: 0 3px 24px
      ${(props) => props.theme.customButton.boxShadowSecandary};
  }
  &.secondary::before {
    background-color: ${(props) =>
      props.theme.customButton.secondaryBgColorHover};
  }
  &.btn-sm {
    padding: 8px 20px;
    font-size: 12px;
  }

  &.checkout-form {
    margin: 20px 0;
  }

  &.login {
    font-size: 14px;
    margin: 20px 0;
  }
  &.secondary {
    background-color: ${(props) => props.theme.customButton.secondaryBgColor};
    box-shadow: 0 3px 24px rgba(255, 204, 76, 0.5);
    font-size: 14px;
    font-weight: 600;
  }
  &.secondary::before {
    background-color: ${(props) =>
      props.theme.customButton.secondaryBgColorHover};
  }
  &.auth-form {
    margin-top: 20px;
  }
  &.loading {
    opacity: 0.7;
    width: ${(props) => props.width};
  }
  &.customize {
    margin-top: 10px;
  }
  &.notice-login {
    box-shadow: none;
  }
  &.cancel {
    width: 90%;
    background-color: transparent;
    box-shadow: none;
    border: 1px solid ${(props) => props.theme.customButton.primaryBgColorHover};
    color: ${(props) => props.theme.customButton.primaryBgColorHover};
    &:hover::before {
      transform: scale(0);
    }
    @media (max-width: 991px) {
      width: auto;
    }
  }
  &.addToCart {
    width: 90%;
    box-shadow: none;
    @media (max-width: 991px) {
      width: auto;
    }
  }
  &.mapsModal{
    width: 100%;
  }
`;

export { StyledButton, StyledLink };
