const getToken = () => {
  let token;
  if (sessionStorage.getItem("id_token")) {
    token = sessionStorage.getItem("id_token");
  } else {
    token = localStorage.getItem("id_token");
  }
  return token;
};

export { getToken };
