/* eslint-disable array-callback-return */
import React, { useState } from "react";
import {
  ContainerDescription,
  ProductThumb,
  ProductContent,
  PriceWrapper,
  ActionForm,
  Icon,
  FormGroup,
  Quantity,
  List,
  RelatedContainer,
  ProductContainer,
  OptionBox,
  OptionTitle,
  VariationItem,
  LabelInput,
  Input,
} from "./style";
import { StyledButton } from "../CustomButton/style";
import { DescriptionText } from "../../constants/texts";
import { STATIC } from "../../constants/env";
import Product from "../Product/index";
import { ProductOptionsText } from "../../constants/texts";
import { RequiredItem, ProductInstructions } from "../ProductOptions/style";
import { v4 as uuidv4 } from "uuid";
import { optionError } from "../../constants/errors";
function Description(props) {
  const [quantity, setQuantity] = useState(1);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedSupplements, setSelectedSupplements] = useState([]);
  const [instructions, setInstructions] = useState("");
  const [errors, setErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [requiredOptions, setRequiredOptions] = useState([]);

  React.useEffect(() => {
    let arrayOptions = [];
    props.product.options.map((elem) => {
      if (elem.requiredItems > 0) {
        arrayOptions.push(elem);
      }
    });
    setRequiredOptions(arrayOptions);
  }, [props.product]);
  const handleAddQuantity = () => {
    setQuantity((prevState) => prevState + 1);
  };
  const handleSubQuantity = () => {
    if (quantity > 0) {
      setQuantity((prevState) => prevState - 1);
    }
  };
  const onOptionChange = (e, option, item) => {
    let options = [...selectedOptions];

    if (e.target.type === "radio") {
      const optionSelected = {
        _id: option._id,
        name: e.target.name,
        requiredItems: option.requiredItems,
        items: [item],
      };
      if (selectedOptions.length > 0) {
        const findedElement = options.findIndex((x) => x._id === option._id);
        if (findedElement === -1) {
          setSelectedOptions([...selectedOptions, optionSelected]);
        } else {
          options[findedElement] = optionSelected;
          setSelectedOptions(options);
        }
      } else {
        setSelectedOptions([...selectedOptions, optionSelected]);
      }
    }
    if (e.target.type === "checkbox") {
      if (selectedOptions.length > 0) {
        const findedElement = options.findIndex((x) => x._id === option._id);
        if (findedElement === -1) {
          const optionSelected = {
            _id: option._id,
            name: e.target.name,
            requiredItems: option.requiredItems,
            items: [item],
          };
          setSelectedOptions([...selectedOptions, optionSelected]);
        } else {
          const oldItems = options[findedElement].items;
          const findedItem = oldItems.findIndex((x) => x._id === item._id);
          if (findedItem === -1) {
            options[findedElement].items.push(item);
            setSelectedOptions(options);
          } else {
            if (options[findedElement].items.length === 1) {
              options.splice(findedElement, 1);
              setSelectedOptions(options);
            } else {
              const items = options[findedElement].items;
              items.splice(findedItem, 1);
              options[findedElement].items = items;

              setSelectedOptions(options);
            }
          }
        }
      } else {
        const optionSelected = {
          _id: option._id,
          name: e.target.name,
          requiredItems: option.requiredItems,
          items: [item],
        };
        setSelectedOptions([...selectedOptions, optionSelected]);
      }
    }
  };
  const onSupplementChange = (e, supplement, item) => {
    let supplements = [...selectedSupplements];

    if (selectedSupplements.length > 0) {
      const findedElement = supplements.findIndex(
        (x) => x._id === supplement._id
      );
      if (findedElement === -1) {
        const supplementSelected = {
          _id: supplement._id,
          name: e.target.name,
          items: [item],
        };
        setSelectedSupplements([...selectedSupplements, supplementSelected]);
      } else {
        const oldItems = supplements[findedElement].items;
        const findedItem = oldItems.findIndex((x) => x._id === item._id);
        if (findedItem === -1) {
          supplements[findedElement].items.push(item);
          setSelectedSupplements(supplements);
        } else {
          if (supplements[findedElement].items.length === 1) {
            supplements.splice(findedElement, 1);
            setSelectedSupplements(supplements);
          } else {
            const items = supplements[findedElement].items;
            items.splice(findedItem, 1);
            supplements[findedElement].items = items;

            setSelectedSupplements(supplements);
          }
        }
      }
    } else {
      const supplementSelected = {
        _id: supplement._id,
        name: e.target.name,
        items: [item],
      };
      setSelectedSupplements([...selectedSupplements, supplementSelected]);
    }
  };

  const handleChangeQuantity = (e) => {
    setQuantity(e.target.value);
  };

  function createMarkup() {
    return { __html: props.product ? props.product.description : "" };
  }

  const handleAddToCart = (e) => {
    e.preventDefault();
    let ok = false;
    if (requiredOptions.length > 0 && selectedOptions.length === 0) {
      setErrors(true);
      setErrorMessage(optionError.noOption);
    } else if (selectedOptions.length > 0 && requiredOptions.length > 0) {
      for (let [i, option] of requiredOptions.entries()) {
        const resultIndex = selectedOptions.findIndex(
          (elem) => elem.name === option.name
        );
        if (resultIndex === -1) {
          setErrors(true);
          setErrorMessage(
            `${optionError.requiredSpecificOption} ${option.requiredItems} ${option.name}`
          );
          break;
        } else {
          if (
            selectedOptions[resultIndex].requiredItems >
            selectedOptions[resultIndex].items.length
          ) {
            setErrors(true);
            setErrorMessage(
              `${optionError.requiredSpecificOption} ${selectedOptions[resultIndex].requiredItems} ${option.name}`
            );
            break;
          }
          if (i + 1 === requiredOptions.length) {
            if (
              selectedOptions[resultIndex].requiredItems <=
              selectedOptions[resultIndex].items.length
            ) {
              ok = true;
            }
          }
        }
      }
    } else {
      ok = true;
    }
    if (ok) {
      setErrors(false);
      const product = {
        key_id: uuidv4(),
        _id: props.product._id,
        selectedOptions: selectedOptions,
        selectedSupplements: selectedSupplements,
        quantity,
        instructions,
      };
      props.addToCart(product);
    }
  };
  const calcTotal = () => {
    let total = props.product.price;
    selectedOptions.forEach((elem) => {
      elem.items.forEach((item) => (total += item.price));
    });
    selectedSupplements.forEach((elem) => {
      elem.items.forEach((item) => (total += item.price));
    });

    return total * quantity;
  };

  return (
    <>
      <ContainerDescription>
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <ProductThumb>
                <img
                  src={
                    props.product ? STATIC.images(props.product.picture) : ""
                  }
                  alt={props.product ? props.product.name : ""}
                />
              </ProductThumb>
            </div>
            <div className="col-md-7">
              <ProductContent>
                <h1>{props.product ? props.product.name : ""}</h1>
                <PriceWrapper>
                  <p>
                    {Number(props.product ? props.product.price : 0).toFixed(2)}
                    €
                  </p>
                </PriceWrapper>
                <p dangerouslySetInnerHTML={createMarkup()} />
              </ProductContent>
              <div className="row">
                {props.product.options.map((elem, index) => {
                  return (
                    <div className="col-lg-6 col-12">
                      <OptionBox>
                        <OptionTitle>
                          <h5>{elem.name}</h5>
                          {elem.requiredItems > 0 && (
                            <RequiredItem>
                              {elem.requiredItems}{" "}
                              {ProductOptionsText.REQUIRED_TEXT}
                            </RequiredItem>
                          )}
                        </OptionTitle>

                        {elem.items.map((element, index) => (
                          <VariationItem>
                            <div
                              className={`custom-control ${
                                elem.requiredItems === 1
                                  ? "custom-radio"
                                  : "custom-checkbox"
                              }`}
                              key={index}
                            >
                              <Input
                                id={`${elem.name}_${element.name}`}
                                type={
                                  elem.requiredItems === 1
                                    ? "radio"
                                    : "checkbox"
                                }
                                name={elem.name}
                                value={element.name}
                                onChange={(e) =>
                                  onOptionChange(e, elem, element)
                                }
                              />
                              <LabelInput
                                for={`${elem.name}_${element.name}`}
                                className="form-check-label"
                                type={
                                  elem.requiredItems === 1
                                    ? "radio"
                                    : "checkbox"
                                }
                              >
                                <span>{element.name}</span>
                              </LabelInput>
                            </div>
                            {element.price > 0 && (
                              <span>+ €{element.price}</span>
                            )}
                          </VariationItem>
                        ))}
                      </OptionBox>
                    </div>
                  );
                })}
                {props.product.supplements.map((elem, index) => {
                  return (
                    <div className="col-lg-6 col-12">
                      <OptionBox>
                        <OptionTitle>
                          <h5>{elem.name}</h5>
                        </OptionTitle>

                        {elem.items.map((element, index) => (
                          <VariationItem>
                            <div
                              className="custom-control custom-checkbox"
                              key={index}
                            >
                              <Input
                                id={`${elem.name}_${element.name}`}
                                type="checkbox"
                                name={elem.name}
                                value={element.name}
                                onChange={(e) =>
                                  onSupplementChange(e, elem, element)
                                }
                              />
                              <LabelInput
                                for={`${elem.name}_${element.name}`}
                                className="form-check-label"
                                type={
                                  elem.requiredItems === 1
                                    ? "radio"
                                    : "checkbox"
                                }
                              >
                                <span>{element.name}</span>
                              </LabelInput>
                            </div>
                            {element.price > 0 && (
                              <span>+ €{element.price}</span>
                            )}
                          </VariationItem>
                        ))}
                      </OptionBox>
                    </div>
                  );
                })}
              </div>
              <ProductInstructions className="description">
                <h3>{ProductOptionsText.SPECIAL_INSTRUCTIONS_TEXT}</h3>
                <p>{ProductOptionsText.SPECIAL_INSTRUCTIONS_PARAGRAPH}</p>
                <textarea
                  className="form-control"
                  placeholder={
                    ProductOptionsText.SPECIAL_INSTRUCTIONS_PLACEHOLDER
                  }
                  onChange={(e) => setInstructions(e.target.value)}
                ></textarea>
              </ProductInstructions>
              {errors && (
                <div className="alert alert-danger" role="alert">
                  {errorMessage}
                </div>
              )}
              <ActionForm>
                <FormGroup>
                  <label>{DescriptionText.QUANTITY_TEXT} </label>
                  <Quantity>
                    <span onClick={() => handleSubQuantity()}>
                      <i className="fas fa-minus"></i>
                    </span>
                    <input
                      type="number"
                      value={quantity}
                      onChange={handleChangeQuantity}
                    ></input>
                    <span onClick={() => handleAddQuantity()}>
                      <i className="fas fa-plus"></i>
                    </span>
                  </Quantity>
                </FormGroup>

                <StyledButton
                  onClick={(e) => {
                    e.preventDefault();
                    // props.addToCartByQuantity(props.product, quantity)
                    handleAddToCart(e);
                  }}
                >
                  {DescriptionText.BUTTON_TEXT}
                  {Number(calcTotal()).toFixed(2)}€
                  <Icon className="fas fa-shopping-cart"></Icon>
                </StyledButton>
              </ActionForm>

              <List>
                <li>
                  <span>{DescriptionText.CATEGORY_TEXT}</span>
                  <span>
                    {props.product
                      ? props.product.category
                        ? props.product.category.name
                        : ""
                      : ""}
                  </span>
                </li>
              </List>
            </div>
          </div>
        </div>
      </ContainerDescription>
      <RelatedContainer>
        <div className="container">
          <h3>{DescriptionText.SUGGESTION_TITLE}</h3>
          <div className="row">
            {props.suggestion.map((elem) => (
              <ProductContainer className="col-lg-4 col-md-6" key={elem._id}>
                <Product
                  item={elem}
                  img={STATIC.images(elem.picture)}
                  title={elem.name}
                  text={elem.description}
                  price={elem.price}
                  add={() => props.addToCart(elem)}
                  handleShowOptions={props.handleShowOptions}
                />
              </ProductContainer>
            ))}
          </div>
        </div>
      </RelatedContainer>
    </>
  );
}

export default Description;
