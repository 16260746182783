import React, { useState } from 'react'
import { CheckoutContainer } from './style';
import OrderDetails from './OderDetails/index';
import BillingsForm from './BillingsForm/index';
import NoticeLogin from './NoticeLogin/index';
const Checkout = (props) => {
    const [selectedDelivery, setSelectedDelivery] = useState(true);
    const [deliveryPrice, setDeliveryPrice] = useState(0);
    return (
        <CheckoutContainer>
            <div className="container">
                <div className="row">
                    <div className="col-xl-7">
                        <NoticeLogin
                        />
                        <BillingsForm
                            products={props.items}
                            remarque={props.remarque}
                            destroyCart={props.destroyCart}
                            user={props.user}
                            selectedDelivery={selectedDelivery}
                            setSelectedDelivery={setSelectedDelivery}
                            setDeliveryPrice={setDeliveryPrice}
                        />
                    </div>
                    <div className="col-xl-5">
                        <OrderDetails
                            items={props.items}
                            total={props.total}
                            selectedDelivery={selectedDelivery}
                            deliveryPrice={deliveryPrice}
                        />
                    </div>
                </div>
            </div>
        </CheckoutContainer>
    )
}

export default Checkout
