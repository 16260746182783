/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { GlobalStyle } from "./components/GlobalStyle";
import { ThemeProvider } from "styled-components";
import Footer from "./components/Footer/index";
import theme from "./theme/index";
import HeaderComponent from "./components/Header/index";
import ScrollToTop from "./services/ScrollToTop";
import SubHeader from "./components/SubHeader";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { fetchProducts } from "./store/actions/productsActions";
import { verify_product_cart } from "./store/actions/cartActions";
import { connect } from "react-redux";
import { routes } from "./constants/routes";
import { addToCart, removeItem } from "./store/actions/cartActions";
import PrivateRoute from "./services/PrivateRoute";
import { getProducts, getTotal, getQuantityTotal } from "./store/Selectors";
import { REACT_APP_GOOGLE_API_KEY } from "./constants/env";

function App(props) {
  const handleRemoveItem = (item) => {
    props.removeItem(item);
  };
  React.useEffect(() => {
    props.fetchProducts();
  }, []);
  
  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_API_KEY}&libraries=geometry,places&sensor=false&v=3`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  React.useEffect(() => {
    const doOperation = async () => {
      let deleted = false;
      let finalProducts = [];
      if (props.products.length > 0) {
        const state = await localStorage.getItem("state");

        const newData = await JSON.parse(state);
        newData.cartReducer.addedItems.forEach((element) => {
          const currentproduct = props.products.find(
            (item) => item._id === element._id
          );
          if (currentproduct === undefined) {
            deleted = true;
          } else {
            const product = {
              _id: currentproduct._id,
              quantity: element.quantity,
              selectedOptions: element.selectedOptions,
              selectedSupplements: element.selectedSupplements,
              instructions: element.instructions,
            };
            finalProducts.push(product);
          }
        });
        if (deleted === true) {
          props.verify_product_cart(finalProducts);
        }
      }
    };
    doOperation();
  }, [props.products]);

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <ScrollToTop />

        <GlobalStyle />
        <HeaderComponent
          class={props.showSubHeader ? "header-absolute" : ""}
          home={props.showSubHeader}
          items={props.items}
          total={props.total}
          quantityTotal={props.quantityTotal}
          removeItem={handleRemoveItem}
          isAuthenticated={props.isAuthenticated}
          products={props.products}
        />
        {props.showSubHeader ? (
          <SubHeader title={props.title} bgImage={props.bgImage} menus={props.menus}/>
        ) : (
          <></>
        )}

        <Switch>
          {routes.map((elem, index) => {
            return elem.path === "/account" ||
              elem.path === "/login" ||
              elem.path === "/register" ||
              elem.path === "/reset" ||
              elem.path === "/reset-password/:id" ? (
              <PrivateRoute
                key={index}
                path={elem.path}
                isAuthenticated={props.isAuthenticated}
              >
                <elem.main />
              </PrivateRoute>
            ) : (
              <Route
                key={index}
                path={elem.path}
                exact={elem.exact}
                children={<elem.main />}
              />
            );
          })}
          <Redirect to="/404" />
        </Switch>

        <Footer />
      </ThemeProvider>
    </Router>
  );
}

const mapStateToProps = (state) => {
  return {
    showSubHeader: state.stickyNavbarReducer.subHeader,
    title: state.stickyNavbarReducer.title,
    items: getProducts(state),
    quantityTotal: getQuantityTotal(state),
    total: getTotal(state).toFixed(2),
    isAuthenticated: state.authReducer.isAutentificated,
    bgImage: state.stickyNavbarReducer.bgImage,
    menus: state.stickyNavbarReducer.menus,
    products: state.productsReducer.productsList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (item) => {
      dispatch(addToCart(item));
    },
    removeItem: (item) => {
      dispatch(removeItem(item));
    },
    fetchProducts: () => {
      dispatch(fetchProducts());
    },
    verify_product_cart: (items) => {
      dispatch(verify_product_cart(items));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
