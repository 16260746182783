import React from "react";
import {
  TrendingContainer,
  TitleSection,
  SliderSection,
  NavigationButtonContainer,
  NavigateButton,
  GlobalStyle,
  NoButton,
  ProductSwiperContainer,
} from "./style";
import Product from "../Product/index";
import "swiper/css/swiper.css";
import Swiper from "react-id-swiper";
import { connect } from "react-redux";
import { addToCart } from "../../store/actions/cartActions";
import { STATIC } from "../../constants/env";
const Trending = (props) => {
  const [swiper, updateSwiper] = React.useState(null);
  const [productsLength, setProductsLength] = React.useState(0);
  React.useEffect(() => {
    setProductsLength(props.productsList.length);
  }, [props.productsList]);
  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };
  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  const params = {
    slidesPerView: 3,
    spaceBetween: 30,
    pagination: false,

    breakpoints: {
      1024: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      640: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
    },
  };
  return (
    <div className="container">
      <GlobalStyle />
      <TrendingContainer>
        <TitleSection className="text-center">
          <h5>{props.subtitle}</h5>
          <h2>{props.title}</h2>
          <p>{props.paragraph}</p>
        </TitleSection>
        <SliderSection>
          <Swiper {...params} getSwiper={updateSwiper} shouldSwiperUpdate>
            {props.productsList.map((elem) => (
              <ProductSwiperContainer key={elem._id}>
                <Product
                  item={elem}
                  img={STATIC.images(elem.picture)}
                  title={elem.name}
                  text={elem.description}
                  price={elem.price}
                  handleShowOptions={props.handleShowOptions}
                  //  addToCart={props.addToCart}
                />
              </ProductSwiperContainer>
            ))}
          </Swiper>
          {productsLength > 3 ? (
            <NavigationButtonContainer>
              <NavigateButton onClick={goPrev}>
                <i className="fas fa-arrow-left"></i>
              </NavigateButton>
              <NavigateButton onClick={goNext}>
                <i className="fas fa-arrow-right"></i>
              </NavigateButton>
            </NavigationButtonContainer>
          ) : (
            <NoButton />
          )}
        </SliderSection>
      </TrendingContainer>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (item) => {
      dispatch(addToCart(item));
    },
  };
};
export default connect(null, mapDispatchToProps)(Trending);

/*
{
  products.map((elem)=>
  <Product 
    key={elem.id}
    img={elem.img}
    title={elem.title}
    text={elem.text}
    price={elem.price}
  />
  )
}
*/
/*
<div style={{backgroundColor:'red',height:'200px'}}>Slide #1</div>
                  <div style={{backgroundColor:'blue',height:'200px'}}>Slide #2</div>
                  <div style={{backgroundColor:'yellow',height:'200px'}}>Slide #3</div>
                  <div style={{backgroundColor:'green',height:'200px'}}>Slide #4</div>
                  <div style={{backgroundColor:'gray',height:'200px'}}>Slide #5</div>
*/
