import React from 'react';
import { TopHeaderText } from '../../../constants/texts';
import { TopHeaderContainer, TopHeaderInner, TopHeaderContacts, TopHeaderNav, Linkto, PhoneLink } from './style';
const TopHeader = (props) => {
    return (
        <TopHeaderContainer>
            <div className="container">
                <TopHeaderInner>
                    <TopHeaderContacts>
                        <PhoneLink className="p-0" href={`tel:${props.storeInformation.phone}`}><i className="fas fa-phone mr-2"></i>{props.storeInformation.phone}</PhoneLink>

                    </TopHeaderContacts>
                    <TopHeaderNav>
                        <Linkto className="header-cta" to="/products">{TopHeaderText.BUTTON_TEXT}</Linkto>
                    </TopHeaderNav>
                </TopHeaderInner>
            </div>
        </TopHeaderContainer>
    )
}

export default TopHeader
