import React, { useState } from "react";
import { NoticeLoginContainer, FormContainer, GlobalStyle } from "./style";
import { StyledButton } from "../../CustomButton/style";
import { CheckoutText } from "../../../constants/texts";
import { useForm } from "react-hook-form";
import { Error } from "../../FormError/style";
import { formErrors } from "../../../constants/errors";
import { loginUser } from "../../../store/actions/authActions";
import { connect } from "react-redux";
import { loginFormErrors } from "../../../constants/errors";
import { succesLoginMessage } from "../../../constants/succesMessage";
import { CSSTransitionGroup } from "react-transition-group"; // ES6
import { LoginText } from "../../../constants/texts";

const NoticeLogin = (props) => {
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [rememberMe, setRememberMe] = React.useState(false);

  const { register, handleSubmit, errors } = useForm();
  const onSubmit = async (data) => {
    props.loginUser(data, rememberMe);
    if (props.isAutentificated) {
      setShowLoginForm(!showLoginForm);
    }
  };
  const handleChange = (e) => {
    setRememberMe(!rememberMe);
  };
  return (
    <>
      <GlobalStyle />
      {!props.isAutentificated && (
        <NoticeLoginContainer>
          <p>
            {CheckoutText.noticeLogin.PARAGRAPH_TEXT}
            <button onClick={() => setShowLoginForm(!showLoginForm)}>
              {CheckoutText.noticeLogin.BUTTON_TEXT}
            </button>
          </p>
        </NoticeLoginContainer>
      )}

      <FormContainer className={showLoginForm ? "show" : "notShow"}>
        <CSSTransitionGroup
          transitionName="slide"
          transitionEnterTimeout={400}
          transitionLeaveTimeout={400}
        >
          {showLoginForm && (
            <form onSubmit={handleSubmit(onSubmit)}>
              {props.errorMessage && (
                <div className="alert alert-danger" role="alert">
                  {loginFormErrors.serverError}
                </div>
              )}
              {props.isAutentificated && (
                <div className="alert alert-success" role="alert">
                  {succesLoginMessage}
                </div>
              )}
              {!props.isAutentificated && (
                <div className="row">
                  <div className="col-xl-6 form-group">
                    <label>{CheckoutText.noticeLogin.EMAIL_LABEL}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      placeholder={CheckoutText.noticeLogin.EMAIL_PLACEHOLDER}
                      defaultValue=""
                      ref={register({
                        required: {
                          value: true,
                          message: formErrors.emailRequired,
                        },
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: formErrors.mailAdressValidity,
                        },
                      })}
                    />
                    <Error>{errors.email && errors.email.message}</Error>
                  </div>
                  <div className="col-xl-6 form-group">
                    <label>{CheckoutText.noticeLogin.PASSWORD_LABEL}</label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      placeholder={
                        CheckoutText.noticeLogin.PASSWORD_PLACEHOLDER
                      }
                      defaultValue=""
                      ref={register({ required: true })}
                    />
                    <Error>
                      {errors.password && formErrors.passwordRequired}
                    </Error>
                  </div>

                  <div className="col-12 form-group">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        checked={rememberMe}
                        onChange={handleChange}
                        id="rememberMe"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="rememberMe"
                      >
                        {LoginText.REMEMBER_ME_LABEL}
                      </label>
                    </div>
                  </div>

                  <div className="col-12">
                    <StyledButton
                      className="btn-sm notice-login m-b-10"
                      type="submit"
                    >
                      Login
                    </StyledButton>
                  </div>
                </div>
              )}
            </form>
          )}
        </CSSTransitionGroup>
      </FormContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMessage: state.authReducer.errorMessage,
    isAutentificated: state.authReducer.isAutentificated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (creds, rememberMe) => {
      dispatch(loginUser(creds, rememberMe));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NoticeLogin);
