exports.checkoutFormErrors = {
  fnameRequired: "Nom obligatoire",
  lnameRequired: "Prénom obligatoire",
  phoneRequired: "Numéro de téléphone obligatoire",
  adresseRequired: "Adresse obligatoire",
  villeRequired: "Ville obligatoire",
  postalCodeRequired: "Code postal obligatoire",
  mailAdressValidity: "Adresse email non valide",
  serverError: "Hors créneau horaire de livraison",
  noProduct: "Sélectionnez des produits",
  delivery: "désole livraison indisponible pour le moment!",
  order: "désole commande indisponible pour le moment!",
  timeDelivery: "Heure de livraison indisponible",
  canDilevery: "Vous pouvez faire la livraison entre",
  timePickup: "Heure de pickup indisponible.",
  canPickup: "Vous pouvez emportez votre commande entre",
};
exports.registerFormErrors = {
  fnameRequired: "Nom obligatoire",
  lnameRequired: "Prénom obligatoire",
  phoneRequired: "Numéro de téléphone obligatoire",
  passwordRequired: "Mot de passe obligatoire",
  passwordLength: "Mot de passe trop court",
  mailAdressValidity: "Adresse email non valide",
  failRegister: "Numéro de télephone ou adresse email déjà utilisée",
};
exports.loginFormErrors = {
  emailRequired: "Adresse email obligatoire",
  emailValidity: "Adresse email non valide",
  passwordRequired: "Mot de passe obligatoire",
  serverError: "Adresse email ou mot de passe incorrect",
};

exports.formErrors = {
  fnameRequired: "Nom obligatoire",
  lnameRequired: "Prénom obligatoire",
  phoneRequired: "Numéro de téléphone obligatoire",
  adresseRequired: "Adresse obligatoire",
  villeRequired: "Ville obligatoire",
  postalCodeRequired: "Code postal obligatoire",
  mailAdressValidity: "Adresse email non valide",
  emailRequired: "Adresse email obligatoire",
  passwordRequired: "Mot de passe obligatoire",
  passwordLength: "Mot de passe trop court",
  passwordIsNotIdentique: "Mot de passe non identique",
  jetonRequired: "Jeton obligatoire",
  subjectRequire: "Objet obligatoire",
  messageRequired: "Message obligatoire",
};
exports.resetPassword = {
  errorServer: "url non valide",
};
exports.optionError = {
  noOption: "Ajoutez des options obligatoires",
  requiredOption: "Vous devez ajouter des ",
  requiredSpecificOption: "Vous devez ajouter au moins ",
};
