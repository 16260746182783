import {SET_SUB_HEADER} from './action-types/cart-actions';
export const setSubHeader = (show, title, bgImage, menus) =>{
    console.log(menus)
    return{
        type: SET_SUB_HEADER,
        payload: {
            show, 
            title, 
            bgImage,
            menus
        }
    }
}