/* eslint-disable no-unused-vars */
import React, { useCallback, useRef } from "react";
import Swiper from "react-id-swiper";
import {
  MenuCategories,
  CategoryItem,
  CategoryImage,
  CategoryDescription,
  ConatinerCategories,
  NavigationButton,
  ListProducts,
  ProductContainer,
  FilterContainer,
} from "./style";
import "swiper/css/swiper.css";
import Product from "../Product/index";
import { API, STATIC } from "../../constants/env";
const Menu = (props) => {
  const [swiper, updateSwiper] = React.useState(null);
  const [currentItem, setCurrentItem] = React.useState(0);
  /*
  React.useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(
        API.products.list
      )
        .then((response) => response.json())
        .then((json) => {
          return json;
        })
        .catch((error) => {
          console.error(error);
        });
      setProductsList(data);
    };
    fetchData();
  }, []);


  const filterProducts =() => {
    let toShow = [];
    productsList.forEach((product) => {
      if (product.category._id === selectedCategory) {
        toShow.push(product);
      }
    });
    //console.log(toShow);
    setProductsToshow(toShow);
  }


  React.useEffect(() => {
    let cats = [{}];
    productsList.forEach((product) => {
      const indexCategory = cats.findIndex(
        (c) => c._id === product.category._id
      );
      if (indexCategory === -1) {
        cats.push({
          _id: product.category._id,
          name: product.category.name,
          picture: STATIC.images(product.category.picture),
        });
      }
    });
    cats.sort((a, b) => (a.name > b.name ? 1 : -1));
    setSelectedCategory(cats[0]._id);
    cats.pop();
    console.log(cats)
    setCategories(cats);
    //
    filterProducts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsList , selectedCategory]);
  
  
  React.useEffect(() => { 
    setSelectedCategory(selectedCategory);
}, [ selectedCategory]);
*/
  /*
const setActiveCategory =(cat) => {
    
  setSelectedCategory(cat._id);
  /// filter 
}
*/

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };
  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };
  const params = {
    slidesPerView: 5,
    spaceBetween: 40,
    pagination: false,
    breakpoints: {
      1024: {
        slidesPerView: 5,
        spaceBetween: 40,
      },
      768: {
        slidesPerView: 4,
        spaceBetween: 40,
      },
      640: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      320: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
    },
  };
  return (
    <>
      <MenuCategories>
        <div className="container">
          <ConatinerCategories>
            <NavigationButton onClick={goPrev}>
              <i className="fas fa-arrow-left"></i>
            </NavigationButton>

            <Swiper getSwiper={updateSwiper} {...params} shouldSwiperUpdate>
              {props.categories.map((elem, index) => (
                <div key={elem._id}>
                  <CategoryItem
                    onClick={() => {
                      props.selectCategory(elem._id);
                      setCurrentItem(index);
                    }}
                    className={currentItem === index ? "active-item" : ""}
                  >
                    <CategoryImage>
                      <img src={elem.picture} alt="category" />
                    </CategoryImage>
                    <CategoryDescription>
                      <h6>{elem.name}</h6>
                    </CategoryDescription>
                  </CategoryItem>
                </div>
              ))}
            </Swiper>

            <NavigationButton onClick={goNext}>
              <i className="fas fa-arrow-right"></i>
            </NavigationButton>
          </ConatinerCategories>
        </div>
      </MenuCategories>
      <FilterContainer>
        <label>trier par: </label>

        <div className="form-group">
          <select
            className="form-control"
            onChange={(e) => props.handleChange(e)}
            default=""
          >
            <option value="-" default>
              -
            </option>
            <option value="asc">Prix croissant</option>
            <option value="desc">Prix decroissant</option>
          </select>
        </div>
      </FilterContainer>
      <ListProducts>
        <div className="container">
          <div className="row">
            {console.log(`${STATIC.images(props.productsToShow[0].picture)}`)}
            {props.productsToShow.map((elem) => (
              <ProductContainer className="col-lg-4 col-md-6" key={elem._id}>
                <Product
                  item={elem}
                  title={elem.name}
                  img={STATIC.images(elem.picture)}
                  text={elem.description}
                  price={elem.price}
                  add={props.addItemToCart}
                  handleShowOptions={props.handleShowOptions}
                />
              </ProductContainer>
            ))}
          </div>
        </div>
      </ListProducts>
    </>
  );
};

export default Menu;
/*

*/
