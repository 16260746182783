import React, { PureComponent } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { StyledButton } from "../CustomButton/style";
import { ButtonContainer, SaveButton } from "./style";
class CroppedImage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      src: null,
      zoom: 1,
      crop: {
        unit: "%",
        width: 600,
        aspect: 1,
      },
      file: null,
    };
    this.click = this.click.bind(this);
  }

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };
  createFile(croppeedIamge) {
    return new Promise(async (resolve) => {
      let r = await fetch(croppeedIamge);
      const blob = r.blob();

      let metadata = {
        type: "image/jpeg",
      };
      let file = new File([blob], "test.jpg", metadata);
      resolve(file);
    });
  }

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
      // this.props.fileChangedHandler(this.state.file);
    }
  }

  async click() {
    // const file = await this.createFile(this.state.croppedImageUrl)
    // console.log(file);
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        let metadata = {
          type: "image/jpeg",
        };
        let file = new File([blob], "test.jpg", metadata);
        this.setState({ file: file });

        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };
  render() {
    // eslint-disable-next-line no-unused-vars
    const { crop, src } = this.state;

    return (
      <div>
        {this.props.src && (
          <ReactCrop
            src={this.props.src}
            crop={crop}
            ruleOfThirds
            zoom={this.state.zoom}
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
            onZoomChange={this.onZoomChange}
          />
        )}
        {/*croppedImageUrl && (
          <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />
        )*/}
        <ButtonContainer>
          <StyledButton className="btn-sm" onClick={this.props.handleClose}>
            close
          </StyledButton>
          <SaveButton
            className="btn-sm"
            onClick={() => {
              this.props.fileChangedHandler(this.state.file);
              this.props.handleClose();
            }}
          >
            save
          </SaveButton>
        </ButtonContainer>
      </div>
    );
  }
}

export default CroppedImage;
