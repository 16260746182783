/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Table } from "../../Checkout/OderDetails/style";
//import {ProductElement} from './style';
import { MyAccountText } from "../../../constants/texts";
import { API } from "../../../constants/env";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/fr";
import { getToken } from "../../../services/LoadToken";
const MyOrders = (props) => {
  const [orders, setOrders] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  React.useEffect(() => {
    const fetchOrder = async () => {
      const token = getToken();
      fetch(
        API.me.listOrder +
          `?userId=${props.user._id}&page=${currentPage}&count=${3}`,
        {
          method: "GET",
          headers: {
            "x-auth-token": token,
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
          } else {
            setOrders(response.orders);
            setTotalPage(response.totalPage);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };
    fetchOrder();
  }, [props.user, currentPage]);
  const goToPage = (event, i) => {
    event.preventDefault();

    if (i !== currentPage) {
      setCurrentPage(i);
    }
  };
  const navigate = async (event, type) => {
    event.preventDefault();
    if (type === "next" && currentPage < totalPage) {
      setCurrentPage(currentPage + 1);
    }
    if (type === "previous" && currentPage >= 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  let menuItems = [];
  for (var i = 1; i <= totalPage; i++) {
    // eslint-disable-next-line no-loop-func

    menuItems.push(i);
  }

  return (
    <div>
      <h2>My orders</h2>
      <div className="table-responsive">
        <Table className="table my-orders">
          <thead>
            <tr>
              <th>{MyAccountText.myOrder.REFERENCE_TEXT}</th>
              <th>{MyAccountText.myOrder.DATE_TEXT}</th>
              <th>{MyAccountText.myOrder.TOTAL_TEXT}</th>
              <th>{MyAccountText.myOrder.SHOW_MORE_TITLE_TEXT}</th>
            </tr>
          </thead>
          <tbody>
            {
              //sam. 16 mai 2020 13:16
              orders.length > 0 ? (
                orders.map((elem) => (
                  <tr key={elem._id}>
                    <td>Ref#{elem.reference}</td>
                    <td>
                      {moment(elem.date)
                        .locale("fr")
                        .format("ddd DD MMMM YYYY HH:MM")}
                    </td>
                    <td>{elem.total}€</td>
                    <td>
                      <Link to={`${props.urlPath}/details/${elem._id}`}>
                        {MyAccountText.myOrder.SHOW_MORE_TEXT}
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <></>
              )
            }

            {/*
                  <td> 
                    <ProductElement>
                      <span>Eau pétillante 50cl x2	</span>
                      <span>2€</span>	
                    </ProductElement>
                    <ProductElement>
                      <span>Eau pétillante 50cl x2	</span>
                      <span>2€</span>	
                    </ProductElement>
                    <ProductElement>
                      <span>Eau pétillante 50cl x2	</span>
                      <span>2€</span>	
                    </ProductElement>
                  </td>
                */}
          </tbody>
        </Table>
        {totalPage > 1 && (
          <div className="d-flex justify-content-center mb-3">
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className="page-item">
                  <a
                    className="page-link"
                    onClick={(event) => navigate(event, "previous")}
                    aria-label="Previous"
                  >
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </a>
                </li>
                {menuItems.map((elem, index) => (
                  <li key={index} className="page-item">
                    <a
                      className="page-link"
                      onClick={(event) => goToPage(event, elem)}
                    >
                      {elem}
                    </a>
                  </li>
                ))}
                <li className="page-item">
                  <a
                    className="page-link"
                    onClick={(event) => navigate(event, "next")}
                    aria-label="Next"
                  >
                    <span aria-hidden="true">&raquo;</span>
                    <span className="sr-only">Next</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyOrders;
