import styled from 'styled-components';
const MenuCategories = styled.div`
    background-color: ${(props)=>props.theme.menu.bgColor};
    padding:20px;
    @media(max-width: 768px){
        padding: 0px;
    }
`
const ConatinerCategories = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    @media(max-width: 768px){
        padding: 20px 0px;
    }
`

const CategoryItem = styled.button`
   display:flex;
   flex-direction:column;
   justify-content:center;
   align-items:center;
   width: auto;
   height: auto;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
    &:focus {
        outline: none;
    };
    
    text-align:center;
    margin: auto;
    opacity: 0.9;
    &.active-item{
        opacity: 1;
    }
    &.active-item div{
        border-color: white;
    }
    
`
const CategoryImage = styled.div`
    text-align:center;
    border-radius:50%;
    margin: 0 auto;
    margin-bottom: 10px;
    height:auto;
    border: 7px solid ${(props)=>props.theme.menu.borderImageColor};
   
    img{
        border-radius: 50%;
        height: 100px;
        width: 100px;
        @media(max-width: 768px){
            height: 70px;
            width: 70px;
        }
    }
`
const CategoryDescription = styled.div`
    text-align:center;
    h6{
     color: ${(props)=>props.theme.menu.categoryDescriptionColor};
     margin: 0;
     font-size: 16px;

    }
`
const NavigationButton = styled.button`
    display:flex;
    align-content:center;
    width:100px;
    height:100px;
    background: transparent;
    border: none;
    cursor: pointer;
    box-sizing: border-box;
    &:focus {
        outline: none;
    };
    color:${(props)=>props.theme.menu.iconColor};
    font-size:30px;
    margin:0px;
    align-items: center;
    
`
const ListProducts = styled.div`
    padding: 20px 0 50px;
    text-align:center;
`

const ProductContainer = styled.div`
    margin-bottom: 20px;
`
const FilterContainer = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    padding: 20px 40px;
    @media(max-width: 768px){
            justify-content: center;
            padding: 20px 20px;

        }
    label{
        margin-right: 10px;
        margin-bottom: 14px;
        font-size: 18px;
    }
`

export {
    MenuCategories, 
    CategoryItem, 
    CategoryImage, 
    CategoryDescription, 
    ConatinerCategories, 
    NavigationButton, 
    ListProducts, 
    ProductContainer,
    FilterContainer

}