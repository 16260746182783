import React from 'react'
import {Container, ModalMain} from './style';
const CroppedImageModal = ({show, children}) => {
    const showHideClassName  = show ? "display-block" : "display-none"
    
    return (
        <Container className={showHideClassName}>
            <ModalMain>
                {children}
              
            </ModalMain>
        </Container>
    )
}

export default CroppedImageModal
