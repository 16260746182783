import React from 'react';
import {
    Route,
    Redirect,
  } from "react-router-dom";

function PrivateRoute({ isAuthenticated, children,path,  ...rest}) {
    
    return (
        <Route
            {...rest}
            render={({location})=>
            isAuthenticated  && (path === "/login"|| path === "/register" || path === "/reset" || path === "/reset-password/:id") ? 
            (
                <Redirect
                to={{
                    pathname: '/account',
                    state: { from: location}
                }}

              />  
            ): !isAuthenticated && (path === "/login"|| path === '/register' || path === "/reset" || path === "/reset-password/:id")? 
            (
               children
            ): isAuthenticated ?
            (
                children
            ) 
            : (
               <Redirect
                 to={{
                     pathname: '/login',
                     state: { from: location}
                 }}

               /> 
            )
            }
        />
    )
}

export default PrivateRoute;
