/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Header } from "./style";
import TopHeader from "./TopHeader/index";
import NavBar from "./Navbar/index";
import SideDrawer from "../SideDrawer/index";
import Backdrop from "../Backdrop/index";
import CartModal from "../Cart/Modal/index";
import Search from "../Search/index";
import { useWindowSize } from "../../hooks/useWindowSize";
import { API } from "../../constants/env";

const HeaderComponent = (props) => {
  const size = useWindowSize();

  const [show, setShow] = React.useState(false);
  const [showCartModal, setShowModal] = React.useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [headerClass, setHeaderClass] = React.useState("header-absolute");
  const [showSearch, setShowSearch] = useState(false);
  const [storeInformation, setStoreInformation] = useState({
    adress: "124 Avenue Parmentier, 75011 Paris, France",
    email: "olivier@klikx.lol",
    formatedAdress: "124 Avenue Parmentier, 75011 Paris, France",
    logo: "/statics/product-1597486560136.png",
    name: "Klikx",
    phone: "516467464",
  });
  React.useEffect(() => {
    const siteInfo = localStorage.getItem('siteInfo') ? JSON.parse(localStorage.getItem('siteInfo')) : undefined;
    if (siteInfo) {
      setStoreInformation(siteInfo);
    }
    fetch(API.settings.load, {
      method: "POST",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        key: "GENERAL",
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (!response.error){
          localStorage.setItem('siteInfo', JSON.stringify(response))
          if (JSON.stringify(siteInfo) !== JSON.stringify(response)) {
            setStoreInformation(response);
          }
        }
      })
      .catch((e) => {});
  }, []);
  React.useEffect(() => {
    const value = props.class;
    setHeaderClass(value);
    const header = document.getElementById("myNavbar");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      if (window.pageYOffset > sticky) {
        setHeaderClass("");
        header.classList.add("sticky");
      } else {
        if (props.home === false) {
          setHeaderClass("");
        } else {
          setHeaderClass("header-absolute");
        }

        header.classList.remove("sticky");
      }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, [props.class, props.home]);

  React.useEffect(() => {
    if (size.width >= 990 && show === true) {
      setShow(false);
      setShowBackdrop(false);
    }
  }, [size.width]);

  const drawerToggleClickHandler = () => {
    setShow(!show);
    setShowBackdrop(!showBackdrop);
  };
  const backdropClickHandler = () => {
    setShowBackdrop(false);
    setShowModal(false);
    setShow(false);
  };
  const CartModalClickHandler = () => {
    setShowModal(!showCartModal);
    setShowBackdrop(!showBackdrop);
  };
  const SearchClickHandler = () => {
    setShowSearch(!showSearch);
  };
  return (
    <>
      <SideDrawer siteInfo={storeInformation} showValue={show} backdropClick={backdropClickHandler} />
      <CartModal
        showValue={showCartModal}
        close={CartModalClickHandler}
        items={props.items}
        total={props.total}
        removeItem={props.removeItem}
        products={props.products}
      />

      {showBackdrop ? (
        <>
          <Backdrop backdropClick={backdropClickHandler} />
        </>
      ) : (
        <></>
      )}

      <Search show={showSearch} SearchClick={SearchClickHandler} />

      <Header>
        <TopHeader storeInformation={storeInformation}/>
        <NavBar
          storeInformation={storeInformation}
          id="myNavbar"
          drawerToggle={drawerToggleClickHandler}
          cartIconToggle={CartModalClickHandler}
          class={headerClass}
          quantityTotal={props.quantityTotal}
          isAuthenticated={props.isAuthenticated}
          SearchClick={SearchClickHandler}
        />
      </Header>
    </>
  );
};

export default HeaderComponent;
