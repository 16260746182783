import React from "react";
import { NotFoundContainer, NotFoundText } from "./style";
import { StyledLink } from "../CustomButton/style";
import { NotFoundTextPage } from "../../constants/texts";
const NotFound = () => {
  return (
    <NotFoundContainer>
      <NotFoundText>
        <h1>{NotFoundTextPage.TITLE_TEXT}</h1>
        <p>{NotFoundTextPage.CONTENT_TEXT}</p>
        <StyledLink to="/">{NotFoundTextPage.BUTTON_TEXT}</StyledLink>
      </NotFoundText>
    </NotFoundContainer>
  );
};

export default NotFound;
