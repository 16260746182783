import React from 'react'
import { Container } from './style';
const SubHeader = (props) => {
    return (
        <Container class="subheader dark-overlay dark-overlay-2" bgImage={props.bgImage}>
            <div class="container">
                <div class="subheader-inner">
                    <h1>{props.title}</h1>
                    {
                        props.menus ?
                            (<nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    {props.menus.map(
                                        (menu, i) => (
                                            <li class={`breadcrumb-item ${i === props.menus.length ? 'active' : ''}`}><a href={menu.link}>{menu.title}</a></li>
                                        )
                                    )}
                                </ol>
                            </nav>)
                            :
                            <div></div>
                    }
                </div>
            </div>
        </Container>
    )
}
export default SubHeader;