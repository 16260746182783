/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";

import {
  AccountContainer,
  Sidebar,
  ProfileImageOuter,
  ProfileImageInner,
  // Welcome,
} from "./style";
import { Switch, Route, Link, useRouteMatch } from "react-router-dom";
//import {STATIC} from '../../constants/env';
//import AccountIndex from "./AccountIndex";
import { MyAccountText } from "../../constants/texts";
import { STATIC } from "../../constants/env";
// import { images } from "../../constants/env";
import CroppedImageModal from "../CroppedImageModal/index";
import CroppedImage from "../CroppedImage/index";

import MyProfil from "./MyProfil/index";
import MyAdress from "./MyAdress/index";
import MyOrders from "./MyOders/index";
import OrderDetail from "./OrderDetail/index";
const Account = (props) => {
  let { path } = useRouteMatch();

  const [file, setFile] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(null);
  const [imageToCrop, setImageToCrop] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleModal = () => {
    setShowModal(!showModal);
  };
  const handleChangeFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImageToCrop(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
    e.target.value = null;
  };
  const cropChangedHandler = (image) => {
    let reader = new FileReader();
    let file = image;

    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
      //setImageToCrop(null);
    };

    reader.readAsDataURL(file);
  };
  return (
    <div className="container">
      <AccountContainer>
        <div className="row">
          <div className="col-lg-3 mt-4 mt-lg-0">
            <div className="d-flex justify-content-center">
              <ProfileImageOuter>
                <ProfileImageInner>
                  {
                    //STATIC.images(props.user.picture)
                  }

                  {imagePreviewUrl === null ? (
                    <img
                      src={
                        props.user.picture
                          ? STATIC.images(props.user.picture)
                          : ""
                      }
                      alt="profile"
                    />
                  ) : (
                    //<img src={images.accountProfile} alt="profile" />
                    <img src={imagePreviewUrl} alt="profile" />
                  )}

                  <span>
                    <i className="fa fa-camera"></i>
                  </span>
                </ProfileImageInner>

                <input
                  type="file"
                  id="file"
                  className="profile-image-input"
                  accept="image/*"
                  onChange={(e) => {
                    handleChangeFile(e);
                    handleModal();
                  }}
                />
              </ProfileImageOuter>
            </div>
            <CroppedImageModal show={showModal}>
              <CroppedImage
                handleClose={handleModal}
                src={imageToCrop}
                fileChangedHandler={cropChangedHandler}
              />
            </CroppedImageModal>
            <Sidebar>
              <ul>
                <li>
                  <Link to={`${path}`}>
                    {" "}
                    {MyAccountText.sideBarMenu.MY_PROFIL_TEXT}{" "}
                  </Link>
                </li>
                <li>
                  <Link to={`${path}/mon-adresse`}>
                    {" "}
                    {MyAccountText.sideBarMenu.MY_ADRESS_TEXT}
                  </Link>
                </li>
                <li>
                  <Link to={`${path}/mes-commandes`}>
                    {" "}
                    {MyAccountText.sideBarMenu.MY_ORDERS_TEXT}{" "}
                  </Link>
                </li>
                {/*
                  <li>
                  <Link to={`${path}/mes-commandes`}>
                    {" "}
                    {MyAccountText.sideBarMenu.MY_ORDERS_TEXT}{" "}
                  </Link>
                </li>  
                  */}

                <li>
                  <a onClick={props.logout}>
                    {" "}
                    {MyAccountText.sideBarMenu.LOGOUT_TEXT}{" "}
                  </a>
                </li>
              </ul>
            </Sidebar>
          </div>
          <div className="col-lg-9">
            <Switch>
              {/*
                <Route exact path={`${path}`}>
                <Welcome>
                  <h1>{MyAccountText.WELCOME_TEXT}</h1>
                </Welcome>
              </Route>
                 <Route path={`${path}/:accountId`}>
                <AccountIndex user={props.user} file={file} />
              </Route>
                */}
              <Route
                path={`${path}`}
                exact
                component={() => (
                  <MyProfil
                    file={file}
                    userUpdated={props.userUpdated}
                    setUserUpdated={props.setUserUpdated}
                  />
                )}
              />
              <Route
                path={`${path}/mon-adresse`}
                component={() => (
                  <MyAdress
                    user={props.user}
                    file={file}
                    restaurantLocation={props.restaurantLocation}
                    clientLocation={props.clientLocation}
                  />
                )}
              />
              <Route
                path={`${path}/mes-commandes`}
                component={() => (
                  <MyOrders urlPath={path} user={props.user} file={file} />
                )}
              />
              <Route
                path={`${path}/details/:id`}
                component={() => <OrderDetail />}
              />

              {/*
                              <Route path={`${path}/mes-commandes`} component={() => <MyOrders user={props.user} file={file} /> }/>

                */}
            </Switch>
          </div>
        </div>
      </AccountContainer>
    </div>
  );
};
export default Account;
