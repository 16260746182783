import React from 'react'
import {SideDrawerNav} from './style';
import {navbarRoutes} from '../../constants/routes'
import { Link } from "react-router-dom";
import { images, STATIC } from '../../constants/env';
function SideDrawer(props) {
    let drawerClasses;
    if(props.showValue){
        drawerClasses='drawer-transtion'
    }
    return (
        <SideDrawerNav className={drawerClasses}>
            <a href="/">
                <img src={props.siteInfo ? STATIC.images(props.siteInfo.logo) : images.logo} alt="logo"/>
            </a>
            <ul>
            {
              navbarRoutes.map((elem, index )=> (
                <li key={index}>
                 <Link to={elem.path} onClick = {props.backdropClick}>{elem.name}</Link>
                 </li>    
              ))
            }
           </ul>
       </SideDrawerNav>
    )
}

export default SideDrawer
