import React from 'react'
import NotFound from '../../components/NotFound/index'
const NotFoundPage = () => {
    return (
        <NotFound>
            
        </NotFound>
    )
}

export default NotFoundPage
