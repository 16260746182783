import React from "react";
import { NotFoundContainer, NotFoundText } from "../NotFound/style";
import { StyledLink } from "../CustomButton/style";
import { PayementFail } from "../../constants/texts";
function PaymentFail() {
  return (
    <NotFoundContainer>
      <NotFoundText>
        <h1>{PayementFail.TITLE_TEXT}</h1>
        <p>{PayementFail.CONTENT_TEXT}</p>
        <StyledLink to="/">{PayementFail.BUTTON_TEXT}</StyledLink>
      </NotFoundText>
    </NotFoundContainer>
  );
}

export default PaymentFail;
