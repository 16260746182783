/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  BillingsCaontainer,
  TimePicker,
  GlobalStyle,
  LoaderPuff,
} from "./style";
import { CheckoutText } from "../../../constants/texts";
import { useForm, Controller } from "react-hook-form";
import { checkoutFormErrors } from "../../../constants/errors";
import moment from "moment";
import { API, REACT_APP_GOOGLE_API_KEY } from "../../../constants/env";
import { StyledButton } from "../../CustomButton/style";
import { makeOrderMessage } from "../../../constants/succesMessage";
import { Error } from "../../FormError/style";
import { getToken } from "../../../services/LoadToken";
import swal from "sweetalert";
import MapsModal from "../../MapsModal";
import Backdrop from "../../Backdrop";
import VerifyAdress from "../../../services/VerifyAdress";
import Geocode from "react-geocode";

import {
  verifyDelivery,
  verifyDeliveryTime,
  verifyOpenClose,
  verifyOrder,
} from "../../../services/verifyTime";
import SearchLocationInput from "../../SearchLocationInput/index";
import PuffLoader from "react-spinners/PuffLoader";
const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};
const BillingsFomr = (props) => {
  const [isDilevery, setIsDelivery] = useState(true);

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    getValues,
    control,
  } = useForm();
  const [showAlertErrors, setShowAlertErrors] = useState(false);
  const [serverErrors, setserverErrors] = useState("");
  const [showSucces, setShowSucces] = useState(false);
  const [minute, setMinute] = useState("00");
  const [isLoading, setIsLoading] = useState(true);
  const [buttonWidth, setButtonWidth] = useState("");
  const [delivery, setDelivery] = useState(false);
  const [order, setOrder] = useState(false);
  const [canDeliver, setCanDeliver] = useState(false);
  const [canPickup, setCanPickup] = useState(false);
  const [restaurantLocation, setRestaurantLocation] = useState({});
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [validAdress, setValidAdress] = useState(false);
  const [zoneDistance, setZoneDistance] = useState(0);
  const [showMapModal, setShowMapModal] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [clientLocation, setClientLocation] = useState({});
  const [formatedAdress, setFormatedAdress] = useState("");
  const [address2, setAddress2] = useState("");
  const [errorProfileAdress, setErrorProfileAdress] = useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(API.settings.load, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key: "GENERAL",
        }),
      })
        .then((res) => res.json())
        .then(async (response) => {
          if (response.err) {
            console.log(response.err);
          }
          console.log(response);
          if (response.location) {
            setRestaurantLocation(response.location);
            setClientLocation(response.location);
          }
        })
        .catch((e) => console.log(e));
    };
    fetchData();
  }, []);
  React.useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(API.settings.load, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key: "DELIVERY",
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.err) {
          }
          if (response.zoneDistance) {
            //setRestaurantLocation(response.location);
            setZoneDistance(response.zoneDistance * 1000);
          }
          if (response.price > 0) {
            props.setDeliveryPrice(response.price);
          }
        })
        .catch((e) => console.log(e));
    };
    fetchData();
  }, []);
  React.useEffect(() => {
    if (document.getElementById("update")) {
      const width = document.getElementById("update").offsetWidth;
      setButtonWidth(width + "px");
    }
  }, []);
  React.useEffect(() => {
    async function fetchData() {
      const value = await verifyDelivery();
      setDelivery(value);
      if (value === false) {
        setIsDelivery(false);
      }
    }
    fetchData();
  }, []);
  React.useEffect(() => {
    async function fetchData() {
      const value = await verifyOrder();
      setIsLoading(false);
      setOrder(value);
    }
    fetchData();
  }, []);
  const VerifyAdress = async (restaurantLocation, query) => {
    let latClient;
    let lngClient;
    let addressComponent;

    // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
    Geocode.setApiKey(REACT_APP_GOOGLE_API_KEY);

    // set response language. Defaults to english.
    Geocode.setLanguage("fr");

    // set response region. Its optional.
    // A Geocoding request with region=es (Spain) will return the Spanish city.
    Geocode.setRegion("fr");

    // Enable or disable logs. Its optional.
    Geocode.enableDebug();
    // Get latidude & longitude from address.
    await Geocode.fromAddress(query).then(
      (response) => {
        addressComponent = response.results[0].address_components;
        const { lat, lng } = response.results[0].geometry.location;
        latClient = lat;
        lngClient = lng;
      },
      (error) => {
        console.error(error);
      }
    );
    const p1Lat = restaurantLocation.lat;
    const p1Lng = restaurantLocation.lng;

    const p1 = new window.google.maps.LatLng(p1Lat, p1Lng);
    const p2 = new window.google.maps.LatLng(latClient, lngClient);
    const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
      p1,
      p2
    );
    if (distance > zoneDistance) {
      setValidAdress(false);
      setErrorProfileAdress(true);
      setCity("");
      setZipCode("");
      setFormatedAdress("");
    } else {
      setValidAdress(true);
    }
  };

  React.useEffect(() => {
    if (props.user) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_API_KEY}&libraries=places,geometry`,
        () => VerifyAdress(restaurantLocation, props.user.address)
      );

      setFormatedAdress(props.user.address);
      setAddress2(props.user.address2);
      setCity(props.user.city);
      setZipCode(props.user.zipCode);
    }
  }, [props.user]);

  const handleAdd = (e, type) => {
    e.preventDefault();

    if (Number(getValues(type)) < (type === "minute" ? 59 : 23)) {
      if (
        Number(getValues(type)).toString().length < 2 &&
        getValues(type) !== "09"
      ) {
        setValue(type, "0" + (Number(getValues(type)) + 1));
      } else {
        setValue(type, Number(getValues(type)) + 1);
      }
    }
  };
  const handleSub = (e, type) => {
    e.preventDefault();
    if (Number(getValues(type)) > 0) {
      if (
        Number(getValues(type)).toString().length < 2 ||
        getValues(type) === "10"
      ) {
        setValue(type, "0" + (Number(getValues(type)) - 1));
      } else {
        setValue(type, Number(getValues(type)) - 1);
      }
    }
  };
  const handleIsDileveryChange = (event) => {
    const target = event.target;
    const value = target.name === "isDilevery" ? target.checked : target.value;
    setIsDelivery(value);
    props.setSelectedDelivery(value);
  };
  const handleCoordonee = (
    formatedAdress,
    lat,
    lng,
    distance,
    city,
    zipCode
  ) => {
    setErrorProfileAdress(false);

    setFormatedAdress(formatedAdress);
    setCity(city);
    setZipCode(zipCode);

    if (distance > zoneDistance) {
      setValidAdress(false);
      setCity("");
      setZipCode("");
      setFormatedAdress("");
      swal("Desole!", "Notre restaurant ne livre pas à votre zone!", "error")
        .then(handleShowMapsModal);

    } else {
      setValidAdress(true);
    }
  };
  const handleChoosePlaceWithPicker = () => {
    handleShowMapsModal();
  };
  const handleShowMapsModal = (item) => {
    setShowMapModal(true);
    setShowBackdrop(true);
    setFormatedAdress("");
    setZipCode("");
    setCity("");
  };
  const handleCloseOption = () => {
    setShowMapModal(false);
    setShowBackdrop(false);
  };
  const confirmAddressPicker = (formatedAdress, city, zipCode) => {
    console.log(formatedAdress, city, zipCode);
    setFormatedAdress(formatedAdress);
    setCity(city);
    setZipCode(zipCode);
    setShowMapModal(false);
    setShowBackdrop(false);
    setValidAdress(true);
  };
  const onSubmit = async (data) => {
    setIsLoading(true);

    const products = props.products.map((p) => {
      return {
        id: p._id,
        qte: p.quantity,
        selectedOptions: p.selectedOptions,
        selectedSupplements: p.selectedSupplements,
        instructions: p.instructions,
      };
    });
    moment.locale("fr");
    const testDate = moment(`${data.heure}:${data.minute}`, "HH:mm").format();
    const address = `${data.adress1} ${data.address2 ? data.address2 : ""}`;
    const toSend = {
      firstName: data.fname,
      lastName: data.lname,
      phone: data.phone,
      email: data.email,
      city: isDilevery ? data.ville : undefined,
      country: "",
      zipcode: isDilevery ? data.postalCode : undefined,
      remarque: props.remarque,
      products,
      date: testDate,
      address: isDilevery ? address : undefined,
      pickup: !isDilevery,
      justificatif: data.justification,
      paymentGateway: data.paiementMode,
    };
    const { canDeliver, planDelivery } = await verifyDeliveryTime(
      `${data.heure}:${data.minute}`
    );
    const { canPickup, planOpenClose } = await verifyOpenClose(
      `${data.heure}:${data.minute}`
    );
    const token = getToken();
    if (props.products.length === 0) {
      setShowSucces(false);
      setShowAlertErrors(true);
      setserverErrors(checkoutFormErrors.noProduct);
      setIsLoading(false);
    } else {
      if (canDeliver === false && isDilevery === true) {
        setShowSucces(false);
        setShowAlertErrors(true);
        setserverErrors(
          `${checkoutFormErrors.timeDelivery} ${
          planDelivery.from !== "" || planDelivery.to !== ""
            ? `${checkoutFormErrors.canDilevery} ${planDelivery.from}-${planDelivery.to}`
            : ``
          }`
        );
        setIsLoading(false);
      } else if (canPickup === false && isDilevery === false) {
        console.log("ya3");
        setShowSucces(false);
        setShowAlertErrors(true);
        setserverErrors(
          `${checkoutFormErrors.timePickup} ${
          planOpenClose.from !== "" || planOpenClose.to !== ""
            ? `${checkoutFormErrors.canPickup} ${planOpenClose.from}-${planOpenClose.to}`
            : ``
          }`
        );
        setIsLoading(false);
      } else {
        fetch(API.checkout.make, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
          body: JSON.stringify(toSend),
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.error) {
              if (response.message === "Delivery is not supported") {
                setserverErrors(checkoutFormErrors.serverError);
              } else {
                setserverErrors(response.message);
              }
              setShowAlertErrors(true);
              setIsLoading(false);
            } else {
              setShowAlertErrors(false);
              if (response.vads_site_id) {
                const form = document.createElement("form");
                document.body.appendChild(form);

                form.method = "POST";
                form.action = "https://secure.payzen.eu/vads-payment/";

                for (const [key, value] of Object.entries(response)) {
                  let input = document.createElement("input");
                  input.type = "hidden";
                  input.value = unescape(encodeURIComponent(value));
                  input.name = key;
                  form.appendChild(input);
                }

                let payButton = document.createElement("input");
                payButton.type = "submit";
                payButton.value = "Payer";
                payButton.name = "payer";

                form.appendChild(payButton);
                form.submit();
              } else {
                setShowSucces(true);
                props.destroyCart();
                setIsLoading(false);
              }
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            setIsLoading(false);
          });
      }
    }
  };
  return (
    <>
      {order === false && !isLoading ? (
        <div className="form-group">
          <div className="alert alert-info">{checkoutFormErrors.order}</div>
        </div>
      ) : (
          <></>
        )}
      {order === false && isLoading ? (
        <LoaderPuff>
          <PuffLoader size={60} color={"#123abc"} loading={isLoading} />
        </LoaderPuff>
      ) : (
          <></>
        )}
      <BillingsCaontainer>
        {order && (
          <>
            {showMapModal && (
              <MapsModal
                closeOption={handleCloseOption}
                location={clientLocation}
                setClientLocation={setClientLocation}
                confirmAddressPicker={confirmAddressPicker}
                zoneDistance={zoneDistance}
                restaurantLocation={restaurantLocation}
                delivery={delivery}
              />
            )}
            {showBackdrop && <Backdrop backdropClick={handleCloseOption} />}
            <h4>{CheckoutText.form.TITLE}</h4>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                {/* information personnel
            Nom, Prenom, Telephone, Email
          */}
                <div className="form-group col-xl-6">
                  <label>
                    {CheckoutText.form.FIRST_NAME_LABEL} <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder={CheckoutText.form.FIRST_NAME_PLACEHOLDER}
                    name="fname"
                    className="form-control"
                    defaultValue={props.user.firstName}
                    ref={register({ required: true })}
                  />
                  <Error>
                    {errors.fname && checkoutFormErrors.fnameRequired}
                  </Error>
                </div>
                <div className="form-group col-xl-6">
                  <label>
                    {CheckoutText.form.LAST_NAME_LABEL} <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder={CheckoutText.form.LAST_NAME_LABEL}
                    name="lname"
                    defaultValue={props.user.lastName}
                    className="form-control"
                    ref={register({ required: true })}
                  />
                  <Error>
                    {errors.lname && checkoutFormErrors.lnameRequired}
                  </Error>
                </div>
                <div className="form-group col-xl-6">
                  <label>
                    {CheckoutText.form.PHONE_LABEL} <span>*</span>
                  </label>
                  <input
                    type="tel"
                    placeholder={CheckoutText.form.PHONE_LABEL}
                    name="phone"
                    className="form-control"
                    defaultValue={props.user.phone}
                    ref={register({ required: true })}
                  />
                  <Error>
                    {errors.phone && checkoutFormErrors.phoneRequired}
                  </Error>
                </div>
                <div className="form-group col-xl-6">
                  <label>{CheckoutText.form.EMAIL_LABEL}</label>
                  <input
                    type="email"
                    placeholder={CheckoutText.form.EMAIL_PLACEHOLDER}
                    name="email"
                    className="form-control"
                    defaultValue={props.user.email}
                    ref={register({
                      required: {
                        value: true,
                        message: checkoutFormErrors.mailAdressValidity,
                      },
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: checkoutFormErrors.mailAdressValidity,
                      },
                    })}
                  />
                  <Error>{errors.email && errors.email.message}</Error>
                </div>
                {/* information personnel Nom, Prenom, Telephone, Email */}
                {/* checkbox validation importation */}
                {delivery === false ? (
                  <div className="form-group">
                  </div>
                ) : (
                    <div className="form-group col-xl-12">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="isDilevery"
                          checked={isDilevery}
                          value={isDilevery}
                          onChange={handleIsDileveryChange}
                          ref={register}
                        />

                        <label className="form-check-label">
                          {CheckoutText.form.IS_DILIVERY_TEXT}
                        </label>
                      </div>
                    </div>
                  )}
                {/* checkbox validation importation */}

                {delivery &&
                  (isDilevery ? (
                    <>
                      {/* details de livraison */}
                      <div className="form-group col-xl-12">
                        {errorProfileAdress && (
                          <div className="alert alert-warning" role="alert">
                            Desole ! Notre restaurant ne livre pas à votre zone!
                          </div>
                        )}
                      </div>

                      <div className="form-group col-xl-12">
                        <label>
                          {CheckoutText.form.ADRESS_LABEL} <span>*</span>
                        </label>
                        <SearchLocationInput
                          placeholder={CheckoutText.form.ADRESS_PLACEHOLDER}
                          handleCoordonee={handleCoordonee}
                          restaurantLocation={restaurantLocation}
                          handleChoosePlaceWithPicker={
                            handleChoosePlaceWithPicker
                          }
                          formatedAdress={formatedAdress}
                          register={
                            isDilevery ? register({ required: true }) : register
                          }
                          name="adress1"
                          setFormatedAdress={setFormatedAdress}
                        />

                        {/*
                      <input
                        type="text"
                        placeholder={CheckoutText.form.ADRESS_PLACEHOLDER}
                        name="adress1"
                        defaultValue={props.user.address}
                        className="form-control"
                        ref={
                          isDilevery ? register({ required: true }) : register
                        }
                      />
                        */}

                        <Error>
                          {errors.adress1 && checkoutFormErrors.adresseRequired}
                        </Error>
                      </div>
                      <div className="form-group col-xl-12">
                        <label>{CheckoutText.form.ADRESS2_LABEL}</label>
                        <input
                          type="text"
                          placeholder={CheckoutText.form.ADRESS2_LABEL}
                          name="adress2"
                          className="form-control"
                          ref={register}
                          defaultValue={address2}
                        />
                      </div>
                      <div className="form-group col-xl-8">
                        <label>
                          {CheckoutText.form.VILLE_LABEL} <span>*</span>
                        </label>
                        <input
                          type="text"
                          placeholder={CheckoutText.form.VILLE_LABEL}
                          name="ville"
                          onChange={e => setCity(e.target.value)}
                          value={city}
                          className="form-control"
                          required=""
                          ref={
                            isDilevery ? register({ required: true }) : register
                          }
                        />
                        <Error>
                          {errors.ville && checkoutFormErrors.villeRequired}
                        </Error>
                      </div>
                      <div className="form-group col-xl-4">
                        <label>
                          {CheckoutText.form.POSTAL_CODE_LABEL} <span>*</span>
                        </label>
                        <input
                          type="number"
                          placeholder={
                            CheckoutText.form.POSTAL_CODE_PLACEHOLDER
                          }
                          name="postalCode"
                          onChange={e => setZipCode(e.target.value)}
                          value={zipCode}
                          className="form-control"
                          ref={
                            isDilevery ? register({ required: true }) : register
                          }
                        />
                        <Error>
                          {errors.postalCode &&
                            checkoutFormErrors.postalCodeRequired}
                        </Error>
                      </div>
                      {/*
    details de livraison
    */}
                    </>
                  ) : (
                      <div />
                    ))}
                <div className="form-group col-xl-8">
                  <div className="form-check ">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="justification"
                        ref={register}
                      />
                      {CheckoutText.form.JUSTIFICATION_LABEL}
                    </label>
                  </div>
                </div>
                <div className="form-group col-xl-4">
                  <label>{CheckoutText.form.PAIEMENT_MODE}</label>
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="paiementMode"
                        value={CheckoutText.form.PAIEMENT_MODE_CHOICE1}
                        defaultChecked
                        ref={register}
                      />
                      {CheckoutText.form.PAIEMENT_MODE_CHOICE1}
                    </label>
                  </div>
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="paiementMode"
                        value={CheckoutText.form.PAIEMENT_MODE_CHOICE2}
                        ref={register}
                      />
                      {CheckoutText.form.PAIEMENT_MODE_CHOICE2}
                    </label>
                  </div>
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="paiementMode"
                        id="exampleRadios3"
                        value={CheckoutText.form.PAIEMENT_MODE_CHOICE3}
                        ref={register}
                      />
                      {CheckoutText.form.PAIEMENT_MODE_CHOICE3}
                    </label>
                  </div>
                  {/*<div className="form-check">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="paiementMode"
                        value={CheckoutText.form.PAIEMENT_MODE_CHOICE4}
                        ref={register}
                      />
                      {CheckoutText.form.PAIEMENT_MODE_CHOICE4}
                    </label>
                  </div>
                  */}
                </div>

                {/*
                 Temp
                 */}
                {delivery && isDilevery ? (
                  <label className="col-xl-12">
                    {CheckoutText.form.LIVRAISON_TIME_TEXT}
                  </label>
                ) : (
                    <label className="col-xl-12">
                      {CheckoutText.form.PASSAGE_TIME_TEXT}
                    </label>
                  )}

                <div className="col-xl-12 d-flex align-items-center">
                  <TimePicker className="w-70">
                    <button
                      onClick={(e) => {
                        handleAdd(e, "heure");
                      }}
                    >
                      <span>&#8249;</span>
                    </button>
                    <input
                      type="number"
                      placeholder="HH"
                      name="heure"
                      className="form-control"
                      max="23"
                      min="00"
                      defaultValue="12"
                      ref={register}
                    />
                    <button
                      onClick={(e) => {
                        handleSub(e, "heure");
                      }}
                    >
                      <span>&#8250;</span>
                    </button>
                  </TimePicker>
                  <div className="ml-2 mr-2">:</div>
                  <TimePicker className="w-70">
                    <button
                      onClick={(e) => {
                        handleAdd(e, "minute");
                      }}
                    >
                      <span>&#8249;</span>
                    </button>
                    <input
                      type="number"
                      placeholder="MM"
                      name="minute"
                      className="form-control"
                      max="59"
                      min="00"
                      defaultValue="00"
                      ref={register}
                    // onChange= {leadingZeros}
                    // onKeyUp= {(e)=>leadingZeros(e)}
                    // onClick= {(e)=>leadingZeros(e)}
                    />
                    <button
                      onClick={(e) => {
                        handleSub(e, "minute");
                      }}
                    >
                      <span>&#8250;</span>
                    </button>
                  </TimePicker>
                </div>

                {/*
                    Temp
            */}
              </div>

              <StyledButton
                type="submit"
                className={`primary checkout-form btn-block ${
                  isLoading && !order ? "loading" : ""
                  }`}
                disabled={isLoading && !order ? true : false}
                width={buttonWidth}
                id="update"
              >
                {isLoading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : (
                    CheckoutText.form.BUTTON_TEXT
                  )}
              </StyledButton>

              {showAlertErrors ? (
                <div className="alert alert-danger" role="alert">
                  {serverErrors}
                </div>
              ) : (
                  <div />
                )}
              {showSucces ? (
                <div className="alert alert-success" role="alert">
                  {makeOrderMessage}
                </div>
              ) : (
                  <div />
                )}
            </form>
          </>
        )}
      </BillingsCaontainer>
    </>
  );
};

export default BillingsFomr;
