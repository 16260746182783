import {
    FETCH_PRODUCTS_REQUEST,
    FETCH_PRODUCTS_SUCCESS,
    FETCH_PRODUCTS_FAILURE, 
    SET_TODAY_PRODUCTS,
    SET_CATEGRORIES,
    SELECT_CATEGORY,
    SET_PRODUCTS_TO_SHOW,
    SET_ASC_PRODUCTS_TO_SHOW,
    SET_DESC_PRODUCTS_TO_SHOW
} from '../actions/action-types/products-action';
import {STATIC} from '../../constants/env';
const intialState = {
    loading: true,
    productsList: [],
    todayProducts: [],
    categories: [],
    selectedCategory: null,
    productsToShow: [], 
    productDetails: {},
    errors: '',

}
const productsReducer = (
    state = intialState,
    action
) => {
    if(action.type === FETCH_PRODUCTS_REQUEST){
        return{
            ...state,
            loading: true,
        }

    }
    if(action.type === FETCH_PRODUCTS_SUCCESS){

        return {
            ...state,
            loading: false,
            productsList: action.payload.products,
            error: '',
        }
    }
    if(action.type === SET_TODAY_PRODUCTS){
        let productsToday = state.productsList.filter(p => !p.permanent);
        return {
            ...state,
            todayProducts: productsToday,
            error: '',
        }
    }
    if(action.type === SET_CATEGRORIES){
        let cats = [];
        state.productsList.forEach((elem)=> {
            const indexCategory = cats.findIndex((c) => c._id === elem.category._id);
            if(indexCategory === -1) {
                cats.push({
                    _id: elem.category._id,
                    name: elem.category.name,
                    displayOrder: elem.category.displayOrder,
                    picture: STATIC.images(elem.category.picture),
                })
            }
        })
        cats.sort((a, b) =>(a.displayOrder > b.displayOrder ? 1: -1));

        return {
            ...state,
            categories: cats,
        }

    }
    if(action.type ===SET_PRODUCTS_TO_SHOW ){
        let toShow = [];
        state.productsList.forEach((product) => {
            if (product.category._id === state.selectedCategory) {
              toShow.push(product);
            }
          });

        return {
            ...state,
            productsToShow: toShow
        }
    }


    if (action.type === SET_ASC_PRODUCTS_TO_SHOW) {
        const getValue = ({ price }) => price || 0;
       // let sortedProductsTohow = state.productsToShow;
       let sortedProductsTohow = [...state.productsToShow].sort((a, b) => getValue(a) - getValue(b));
        return{
            ...state,
           productsToShow: sortedProductsTohow
        }
    }
    if (action.type === SET_DESC_PRODUCTS_TO_SHOW) {
        const getValue = ({ price }) => price || 0;
        //let sortedProductsTohow = state.productsToShow;
        let sortedProductsTohow = [...state.productsToShow].sort((a, b) => getValue(b) - getValue(a));

        return{
            ...state,
            productsToShow: sortedProductsTohow
        }
    }
    if(action.type ===SELECT_CATEGORY ){
        //selectedCategory: cats[0]._id

        return {
            ...state,
            selectedCategory: action.payload.id === undefined ? state.categories[0]?state.categories[0]._id:''  : action.payload.id,
        }
    }
    
    if(action.type === FETCH_PRODUCTS_FAILURE){
        return {
            ...state,
            loading: false,
            productsList: [],
            errors: action.payload.error
        }
    } else{
        return state;
    }
    
}
export default productsReducer;


