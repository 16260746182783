import styled from "styled-components";
const ModalContainer = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  background: ${(props) => props.theme.ProductOption.bgColor};
  box-shadow: 0 2px 32px ${(props) => props.theme.ProductOption.boxShaddow};
  width: 600px;
  display: flex;
  flex-direction: column;
  max-height: 80%;
  @media (max-width: 991px) {
    width: 100%;
    height: 100%;
    max-height: 100%;
  }
`;
const ModalBody = styled.div`
  flex: 6;
 /* overflow: auto; */
  span {
    padding: 10px;
  }
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) =>
      props.theme.cart.modal.scroll.scrollbarTrackBgColor};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) =>
      props.theme.cart.modal.scroll.scrollbarThumbBgColor};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) =>
      props.theme.cart.modal.scroll.scrollbarThumbHoverBgColor};
  }
`;

const ModalHeader = styled.div`
  display: flex;
  text-align: center;
  padding: 16px;
  border-bottom: 1px solid
    ${(props) => props.theme.ProductOption.bBProductInformation};
  @media (max-width: 991px) {
    margin-top: 20px;
  }
  h2 {
    font-size: 18px;
    font-weight: 700;
    flex: 1 1 auto;
    color: ${(props) => props.theme.ProductOption.colorTitle};
    margin: 0;
    line-height: 24px;
  }
 
`;


const ModalFooter = styled.div`
  border-top: 1px solid
    ${(props) => props.theme.ProductOption.footerBorderColor};
  display: flex;
  flex: 1;
  padding: 10px;
  align-items: center;
`;
const Alert = styled.div`
  margin-right: 10px;
  margin-left: 10px;

`


export {
  ModalContainer,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Alert
  
};
