import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { setSubHeader } from "../../store/actions/stickyNavbarAction";
import { loginUser } from "../../store/actions/authActions";
import Register from "../../components/Register/index";
import { API } from "../../constants/env";
const RegisterPage = (props) => {
  const [content, setContent] = useState(
    localStorage.getItem("REGISTER")
      ? JSON.parse(localStorage.getItem("REGISTER"))
      : {}
  );

  useEffect(() => {
    const fetchData = () => {
      fetch(API.content.load, {
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: "REGISTER",
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (JSON.stringify(response) !== localStorage.getItem("REGISTER")) {
            localStorage.setItem("REGISTER", JSON.stringify(response));
            setContent(response);
          }
        })
        .catch((e) => {});
    };
    fetchData();
  }, []);
  React.useEffect(() => {
    props.setSubHeader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleLogin = async (creds, history) => {
    props.loginUser(creds, history);
  };
  return (
    <div>
      <Register handleLogin={handleLogin} content={content} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSubHeader: (show) => {
      dispatch(setSubHeader(show));
    },
    loginUser: (creds, history) => {
      dispatch(loginUser(creds, history));
    },
  };
};
export default connect(null, mapDispatchToProps)(RegisterPage);
