import React from "react";
import {
  AboutContainer,
  ImageWrapper,
  Dots,
  DescriptionSection,
} from "./style";
import { StyledLink } from "../CustomButton/style";
import { AboutText } from "../../constants/texts";
import { images, STATIC } from "../../constants/env";
function About(props) {
  return (
    <AboutContainer>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 mb-lg-30">
            <ImageWrapper>
              <img
                src={
                  props.content.subHeaderImage
                    ? STATIC.images(props.content.image)
                    : ""
                }
                alt="img"
              />
              <Dots />
            </ImageWrapper>
          </div>
          <div className="col-lg-6">
            <DescriptionSection>
              <h5>{props.content.title ? props.content.title : ""} </h5>
              <h2>{props.content.subTitle ? props.content.subTitle : ""}</h2>
              <p>
                {props.content.description ? props.content.description : ""}
              </p>

              <StyledLink to="/products">
                {props.content.buttonText ? props.content.buttonText : ""}
              </StyledLink>
            </DescriptionSection>
          </div>
        </div>
      </div>
    </AboutContainer>
  );
}

export default About;
