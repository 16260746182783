import styled from "styled-components";
const ProductOptionsContainer = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  background: ${(props) => props.theme.ProductOption.bgColor};
  box-shadow: 0 2px 32px ${(props) => props.theme.ProductOption.boxShaddow};
  width: 600px;
  display: flex;
  flex-direction: column;
  max-height: 80%;
  border-radius: 3px;
  @media (max-width: 991px) {
    width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 0;
  }
`;
const ProductOptionBody = styled.div`
  flex: 6;
  overflow: auto;
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) =>
      props.theme.cart.modal.scroll.scrollbarTrackBgColor};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) =>
      props.theme.cart.modal.scroll.scrollbarThumbBgColor};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) =>
      props.theme.cart.modal.scroll.scrollbarThumbHoverBgColor};
  }
`;
const ProductInformation = styled.div`
  padding: 16px;
  border-bottom: 1px solid
    ${(props) => props.theme.ProductOption.bBProductInformation};
`;
const ProductHeader = styled.div`
  display: flex;
  margin: 0 0 8px;
  h2 {
    font-size: 18px;
    font-weight: 700;
    flex: 1 1 auto;
    color: ${(props) => props.theme.ProductOption.colorTitle};
    margin: 0;
    line-height: 24px;
  }
  span {
    font-size: 15px;
    font-weight: 300;
    flex: 0 0 auto;
    color: ${(props) => props.theme.ProductOption.colorPrice};
    line-height: 24px;
  }
`;
const ProductDescription = styled.div`
  font-size: 14px;
  font-weight: 300;
  margin: 0;
  line-height: 15px;
  color: ${(props) => props.theme.ProductOption.colorTitle};
`;

const ProductOptionFooter = styled.div`
  border-top: 1px solid
    ${(props) => props.theme.ProductOption.footerBorderColor};
  display: flex;
  flex: 1;
  padding: 10px;
  align-items: center;
`;
const ProductInstructions = styled.div`
  margin: 0 0 24px;
  padding: 16px 16px 0;
  h3 {
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    padding: 16px 0 0;
    color: ${(props) => props.theme.ProductOption.colorTitle};
    line-height: 18px;
  }
  p {
    font-size: 14px;
    font-weight: 300;
    margin: 4px 0 16px;
    color: ${(props) => props.theme.ProductOption.colorTitle};
  }
  &.description {
    padding: 0px;
  }
`;
const ProductVariation = styled.div`
  padding: 16px 0 8px;
`;
const ProductToppingTitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  color: ${(props) => props.theme.ProductOption.colorTitle};
  margin: 0;
  padding: 0 16px;
  display: flex;
  align-items: flex-start;
  line-height: 1.18em;
  margin-bottom: 20px;
  span:first-child {
    flex: 1 1 auto;
  }
  /*
    span:last-child{
        background: rgba(39,111,191,0.1);
        color: #276FBF;
        font-size: 18px;
        font-weight: 700;
        border-radius: 2px;
        line-height: 18px;
        text-transform: uppercase;
        padding: 4px 6px 2px;
        margin: 0 0 0 8px;
        flex: 0 0 auto;
    }
    */
`;
const ProductToppingItem = styled.div`
  padding: 0 16px;
  label {
    font-size: 16px;
    font-weight: 300;
    display: flex;
    min-height: 24px;
    color: ${(props) => props.theme.ProductOption.colorTitle};
    margin: 0;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  span {
    padding: 0 2px;
  }
`;
const Quantity = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 24px;
    line-height: 22px;
    font-weight: 400;
  }
  button {
    border-radius: 200px;
    display: flex;
    padding: 0;
    border: none;
    margin: 0;
    background-color: transparent;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
`;
const QuantityContainer = styled.div`
  width: 200px;
  margin: 0px auto;
`;
const QuantiyButtonContainer = styled.span`
  color: ${(props) => props.theme.ProductOption.quantityButtonColor};
  svg {
    fill: ${(props) => props.theme.ProductOption.quantityIconColor};
  }
`;

const CancelContainer = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
const AddContainer = styled.div`
  flex: 2;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
const RequiredItem = styled.div`
  color: ${(props) => props.theme.ProductOption.requiredItemColor};
  font-size: 14px;
  font-weight: 400;
  border-radius: 2px;
  background-color: ${(props) => props.theme.ProductOption.requiredItemBGColor};
  opacity: 0.8;
  line-height: 15px;
  text-transform: uppercase;
  padding: 4px 6px;
  margin: 0 0 0 8px;
  flex: 0 0 auto;
`;
const Border = styled.div`
  border: 1px solid ${(props) => props.theme.ProductOption.borderColor};
  width: 95%;
  text-align: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 12px;
`;
const ErrorContainer = styled.div`
  width: 100%;
  margin: 0;
  text-align: center;
  & > span {
    color: #e64135;
  }
`;

export {
  ProductOptionsContainer,
  ProductOptionBody,
  ProductOptionFooter,
  ProductInformation,
  ProductHeader,
  ProductDescription,
  ProductInstructions,
  ProductVariation,
  ProductToppingTitle,
  ProductToppingItem,
  Quantity,
  QuantityContainer,
  QuantiyButtonContainer,
  CancelContainer,
  AddContainer,
  RequiredItem,
  Border,
  ErrorContainer,
};
