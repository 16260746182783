import React, { useState, useEffect } from "react";
import ResetPassword from "../../components/RestPassword/index";
import { connect } from "react-redux";
import { setSubHeader } from "../../store/actions/stickyNavbarAction";
import { API } from "../../constants/env";

const ResetPasswordPage = (props) => {
  const [content, setContent] = useState(
    localStorage.getItem("RESET")
      ? JSON.parse(localStorage.getItem("RESET"))
      : {}
  );

  useEffect(() => {
    const fetchData = () => {
      fetch(API.content.load, {
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: "RESET",
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (JSON.stringify(response) !== localStorage.getItem("RESET")) {
            localStorage.setItem("RESET", JSON.stringify(response));
            setContent(response);
          }
        })
        .catch((e) => {});
    };
    fetchData();
  }, []);
  React.useEffect(() => {
    props.setSubHeader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <ResetPassword content={content} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSubHeader: (show) => {
      dispatch(setSubHeader(show));
    },
  };
};
export default connect(null, mapDispatchToProps)(ResetPasswordPage);
