/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Menu from "../../components/Menu/index";
import { connect } from "react-redux";
import { addToCart } from "../../store/actions/cartActions";
import { setSubHeader } from "../../store/actions/stickyNavbarAction";
import { images, API, STATIC } from "../../constants/env";
import { getQuantityTotal } from "../../store/Selectors";
import {
  fetchProducts,
  selectCategory,
  setProductsToShow,
  setCategories,
  set_asc_products_to_show,
  set_desc_products_to_show,
} from "../../store/actions/productsActions";
import { SubHeaderText } from "../../constants/texts";
import Preloader from "../../components/Preloader/index";
import ProductOptions from "../../components/ProductOptions/index";
import Backdrop from "../../components/Backdrop/index";
const ProductsPage = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [showOptions, setShowOptions] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [product, setProduct] = useState({});
  const [selectValue, setSelectValue] = useState("-");
  const [content, setContent] = useState(
    localStorage.getItem("MENU") ? JSON.parse(localStorage.getItem("MENU")) : {}
  );

  useEffect(() => {
    const fetchData = () => {
      fetch(API.content.load, {
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: "MENU",
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          console.log("this is slides data ");
          console.log(response);
          if (JSON.stringify(response) !== localStorage.getItem("MENU")) {
            localStorage.setItem("MENU", JSON.stringify(response));
            setContent(response);
          }
        })
        .catch((e) => {});
    };
    fetchData();
  }, []);
  useEffect(() => {
    props.setSubHeader(
      true,
      content.title,
      STATIC.images(content.subHeaderImage)
    );
    props.fetchProducts();
  }, [content]);
  useEffect(() => {
    props.setCategories();
  }, [props.productsList]);
  useEffect(() => {
    props.selectCategory();
    props.setProductsToShow();
  }, [props.categories]);
  useEffect(() => {
    if (
      props.loading === false &&
      props.categories.length > 0 &&
      props.productsToShow.length > 0
    ) {
      setIsLoading(false);
    }
  }, [props.productsList, props.categories, props.productsToShow]);

  const handleSelectCategory = (id) => {
    props.selectCategory(id);
    props.setProductsToShow();
    if (selectValue === "asc") {
      handleSetAscProducts();
    }
    if (selectValue === "desc") {
      handleSetDescProducts();
    }
  };

  const handleShowOptions = (item) => {
    setProduct(item);
    setShowOptions(true);
    setShowBackdrop(true);
  };
  const handleCloseOption = () => {
    setShowOptions(false);
    setShowBackdrop(false);
  };

  const handleSetAscProducts = () => {
    props.set_asc_products_to_show();
  };
  const handleSetDescProducts = () => {
    props.set_desc_products_to_show();
  };
  const handleChange = (event) => {
    //this.setState({ value: event.target.value });
    if (event.target.value === "asc") {
      handleSetAscProducts();
    }
    if (event.target.value === "desc") {
      handleSetDescProducts();
    }
    if (event.target.value === "-") {
      props.setProductsToShow();
    }
    setSelectValue(event.target.value);
  };
  return (
    <div>
      {showOptions && (
        <ProductOptions
          product={product}
          closeOption={handleCloseOption}
          addToCart={props.addToCart}
        />
      )}
      {showBackdrop && <Backdrop backdropClick={handleCloseOption} />}

      {isLoading ? (
        <Preloader />
      ) : (
        <Menu
          addItemToCart={props.addToCart}
          selectCategory={handleSelectCategory}
          categories={props.categories}
          selectedCategory={props.selectedCategory}
          productsToShow={props.productsToShow}
          // productsToShow={products}
          handleShowOptions={handleShowOptions}
          setAscProducts={handleSetAscProducts}
          setDescProducts={handleSetDescProducts}
          setProductsToShow={props.setProductsToShow}
          handleChange={handleChange}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    items: state.cartReducer.addedItems,
    quantityTotal: getQuantityTotal(state),
    total: state.cartReducer.total,
    categories: state.productsReducer.categories,
    selectedCategory: state.productsReducer.selectedCategory,
    productsToShow: state.productsReducer.productsToShow,
    productsList: state.productsReducer.productsList,
    loading: state.productsReducer.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (item) => {
      dispatch(addToCart(item));
    },
    setSubHeader: (show, title, bgImage) => {
      dispatch(setSubHeader(show, title, bgImage));
    },
    fetchProducts: () => {
      dispatch(fetchProducts());
    },
    selectCategory: (id) => {
      dispatch(selectCategory(id));
    },
    setProductsToShow: () => {
      dispatch(setProductsToShow());
    },
    setCategories: () => {
      dispatch(setCategories());
    },
    set_asc_products_to_show: () => {
      dispatch(set_asc_products_to_show());
    },
    set_desc_products_to_show: () => {
      dispatch(set_desc_products_to_show());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductsPage);
