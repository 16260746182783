import React, { useState } from "react";
import { StyledButton } from "../../CustomButton/style";
import { MyAccountText } from "../../../constants/texts";
import { formErrors } from "../../../constants/errors";
import { updateAdresseMessage } from "../../../constants/succesMessage";
import { useForm } from "react-hook-form";
import { API } from "../../../constants/env";
import { Error } from "../../FormError/style";
import { getToken } from "../../../services/LoadToken";
import SearchLocationInput from "../../SearchLocationInput/index";
import { CheckoutText } from "../../../constants/texts";
import swal from "sweetalert";
import PuffLoader from "react-spinners/PuffLoader";
import { LoaderPuff } from "../../Checkout/BillingsForm/style";

const MyAdress = (props) => {
  const [isError, setIsError] = useState(false);
  const [errorServer, setErrorServer] = useState("");
  const [showSucces, setShowSucces] = useState(false);
  const { register, handleSubmit, errors, setValue } = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const [buttonWidth, setButtonWidth] = useState("");
  const [showMapModal, setShowMapModal] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [formatedAdress, setFormatedAdress] = useState("");
  const [adress2, setAdress2] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [validAdress, setValidAdress] = useState(false);
  const [user, setUser] = useState({});

  React.useEffect(() => {
    const fetchData = async () => {
      const token = getToken();

      if (token) {
        try {
          const response = await fetch(API.me.getUser, {
            headers: {
              "x-auth-token": token,
            },
          });
          const data = await response.json();
          setUser(data);
          setFormatedAdress(data.address);
          setAdress2(data.address2);
          setCity(data.city);
          setZipCode(data.zipCode);
          setIsLoading(false);
        } catch (e) {}
      } else {
      }
    };
    fetchData();
  }, []);
  React.useEffect(() => {
    if (isLoading === false) {
      const width = document.getElementById("update").offsetWidth;
      setButtonWidth(width + "px");
    }
  }, []);
  /*
  React.useEffect(() => {
    console.log("this is props.user");
    console.log(props.user);
    if (props.user) {
      setFormatedAdress(props.user.address);
      setAdress2(props.user.address2);
      setCity(props.user.city);
      setZipCode(props.user.zipCode);
    }
  }, [props.user]);
  */
  const onSubmit = async (data) => {
    setIsLoadingButton(true);

    const token = getToken();

    if (token) {
      const formData = new FormData();

      formData.append("user", props.user);
      formData.append("address", data.address1);
      formData.append("address2", data.address2);
      formData.append("city", data.ville);
      formData.append("zipCode", data.zipCode);
      if (props.file !== null) {
        formData.append("file", props.file, props.file.name);
      }
      fetch(API.me.update, {
        method: "POST",
        headers: {
          "x-auth-token": token,
        },
        body: formData,
      })
        .then((response) => response.json)
        .then((response) => {
          if (response.error) {
            setErrorServer("registerFormErrors.failRegister");
            setIsError(true);
            setIsLoadingButton(false);
          } else {
            setShowSucces(true);
            setIsLoadingButton(false);
          }
        })
        .catch((err) => {
          setIsLoadingButton(false);
        });
    }
  };
  const handleCoordonee = (
    formatedAdress,
    lat,
    lng,
    distance,
    city,
    zipCode
  ) => {
    console.log(formatedAdress);
    console.log(city);
    console.log(zipCode);
    setValue("address1", formatedAdress);
    setValue("ville", city);
    setValue("zipCode", zipCode);

    setValidAdress(true);
  };
  const handleShowMapsModal = (item) => {
    setShowMapModal(true);
    setShowBackdrop(true);
    setFormatedAdress("");
    setZipCode("");
    setCity("");
  };

  const handleChoosePlaceWithPicker = () => {
    handleShowMapsModal();
  };
  return (
    <div className="container">
      <h2>Mon Adress</h2>
      {isLoading ? (
        <LoaderPuff>
          <PuffLoader size={60} color={"#bbd047"} loading={isLoading} />
        </LoaderPuff>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyPress={(e) => {
            if (e.key == "Enter") {
              e.preventDefault();
            }
          }}
        >
          {isError ? (
            <div className="alert alert-danger" role="alert">
              {errorServer}
            </div>
          ) : (
            <div />
          )}
          {showSucces ? (
            <div className="alert alert-success" role="alert">
              {updateAdresseMessage}
            </div>
          ) : (
            <div />
          )}
          <div className="form-group row">
            <label className="col-lg-3 font-weight-bold text-dark col-form-label form-control-label text-2 required">
              {MyAccountText.myAdress.ADRESSE1_TEXT}
            </label>
            <div className="col-lg-9">
              <SearchLocationInput
                placeholder={CheckoutText.form.ADRESS_PLACEHOLDER}
                handleCoordonee={handleCoordonee}
                restaurantLocation={props.restaurantLocation}
                handleChoosePlaceWithPicker={handleChoosePlaceWithPicker}
                formatedAdress={formatedAdress}
                register={register({ required: true })}
                name="address1"
                setFormatedAdress={setFormatedAdress}
                profil={true}
              />
              {/*
            <input
              autoComplete="off"
              className="form-control"
              type="text"
              name="adresse"
              ref={register({ required: true })}
              defaultValue={props.user.address}
            
              
            />
            */}
              <Error>{errors.adresse && formErrors.adresseRequired}</Error>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-lg-3 font-weight-bold text-dark col-form-label form-control-label text-2 required">
              {MyAccountText.myAdress.ADRESSE2_TEXT}
            </label>
            <div className="col-lg-9">
              <input
                autoComplete="off"
                className="form-control"
                type="text"
                name="address2"
                ref={register}
                defaultValue={adress2}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-3 font-weight-bold text-dark col-form-label form-control-label text-2 required">
              {MyAccountText.myAdress.VILLE_TEXT}
            </label>
            <div className="col-lg-9">
              <input
                autoComplete="off"
                className="form-control"
                type="text"
                name="ville"
                ref={register({ required: true })}
                defaultValue={city}
              />
              <Error>{errors.adresse && formErrors.villeRequired}</Error>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-lg-3 font-weight-bold text-dark col-form-label form-control-label text-2 required">
              {MyAccountText.myAdress.ZIP_CODE}
            </label>
            <div className="col-lg-9">
              <input
                className="form-control"
                type="number"
                name="zipCode"
                ref={register({ required: true })}
                defaultValue={zipCode}
              />
              <Error>{errors.zipCode && formErrors.postalCodeRequired}</Error>
            </div>
          </div>

          <div className="form-group row">
            <div className="form-group col-lg-8" />
            <div className="form-group col-lg-4">
              <StyledButton
                type="submit"
                className={`float-right ${isLoadingButton ? "loading" : ""}`}
                disabled={isLoadingButton ? true : false}
                width={buttonWidth}
                id="update"
              >
                {isLoadingButton ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : (
                  MyAccountText.BUTTON_TEXT
                )}
              </StyledButton>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default MyAdress;
