import React from 'react'
import {PreloaderContainer, Ripple} from './style';
function Preloader() {
    return (
        <PreloaderContainer>
            <div>
                <Ripple className="lds-ripple">
                    <div />
                    <div />

                </Ripple>
            </div>
        </PreloaderContainer>
    )
}

export default Preloader
