/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { logoutUser } from "../../store/actions/authActions";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import Account from "../../components/Account/index";
import { API, REACT_APP_GOOGLE_API_KEY } from "../../constants/env";
import { setSubHeader } from "../../store/actions/stickyNavbarAction";
import Preloader from "../../components/Preloader/index";
import { getToken } from "../../services/LoadToken";

const AccountPage = (props) => {
  const [data, setData] = useState([]);
  const [isLoding, setIsLoding] = useState(true);
  const [restaurantLocation, setRestaurantLocation] = useState({});
  const [clientLocation, setClientLocation] = useState({});

  React.useEffect(() => {
    const fetchData = async () => {
      await fetch(API.settings.load, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key: "GENERAL",
        }),
      })
        .then((res) => res.json())
        .then(async (response) => {
          if (response.err) {
            console.log(response.err);
          }
          console.log(response);
          if (response.location) {
            setRestaurantLocation(response.location);
            setClientLocation(response.location);
          }
        })
        .catch((e) => console.log(e));
    };
    fetchData();
  }, []);
  React.useEffect(() => {
    props.setSubHeader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const token = getToken();

      if (token) {
        try {
          const response = await fetch(API.me.getUser, {
            headers: {
              "x-auth-token": token,
            },
          });
          const data = await response.json();
          setData(data);
          setIsLoding(!isLoding);
        } catch (e) {}
      } else {
      }
    };
    fetchData();
  }, []);

  let history = useHistory();
  const handleLogout = () => {
    props.logoutUser(history);
  };
  return (
    <div>
      {isLoding ? (
        <Preloader />
      ) : (
        <Account
          user={data}
          logout={handleLogout}
          restaurantLocation={restaurantLocation}
          clientLocation={clientLocation}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAutentificated: state.authReducer.isAutentificated,
    isFetching: state.authReducer.isFetching,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: (history) => {
      dispatch(logoutUser(history));
    },
    setSubHeader: (show, title, bgImage) => {
      dispatch(setSubHeader(show, title, bgImage));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AccountPage));
