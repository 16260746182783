import styled from 'styled-components';
const MyProfilContainer = styled.div`
    
    h2{
        font-size: 2em;
        font-weight: 400;
        color: ${props => props.theme.account.titleColor};

    }
`

export {MyProfilContainer, }