import styled from "styled-components";
const NewsLetterSection = styled.div`
  width: 100%;
  background-image: url(${(props) => props.theme.newsLetter.bgImage});
  background-size: cover;
  background-position: center;
  padding: 80px 0;
  @media (max-width: 991px) {
    padding: 60px 0;
  }
`;
const NewsLetterBox = styled.div`
  background-color: ${(props) => props.theme.newsLetter.newsLetterBox.bgColor};
  padding: 40px;
  border-radius: 8px;
  max-width: 600px;
  h2 {
    font-size: 42px;
    margin-bottom: 20px;
    line-height: 48px;
    color: ${(props) => props.theme.newsLetter.newsLetterBox.titleColor};
    font-weight: 700;
  }
  p{
    max-width: 600px;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 28px;
    color:${(props) => props.theme.newsLetter.newsLetterBox.textColor};

  }
  form input{
      height:50px;
      color: ${(props) => props.theme.newsLetter.newsLetterBox.formInputColor};
  }
  @media (max-width: 991px) {
    h2 {
      font-size: 26px;
      line-height: 34px;
      margin-bottom: 10px;
    }
  }
`;
export { NewsLetterSection, NewsLetterBox };
