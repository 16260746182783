/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from "react";
import {
  FooterContainer,
  FooterTop,
  FooterMiddle,
  FooterBottom,
  ListContainer,
} from "./style";
import { StyledLink } from "../CustomButton/style";
import { FooterText } from "../../constants/texts";
import { Link } from "react-router-dom";
import {
  informationFooterRoutes,
  OthersFooterRoutes,
} from "../../constants/routes";
import { connect } from "react-redux";
import { fetchProducts } from "../../store/actions/productsActions";
import { images, API, STATIC } from "../../constants/env";
const Footer = (props) => {
  const [suggestion, setSuggestion] = React.useState([]);
  const [storeInformation, setStoreInformation] = useState({
    adress: "124 Avenue Parmentier, 75011 Paris, France",
    email: "olivier@klikx.lol",
    formatedAdress: "124 Avenue Parmentier, 75011 Paris, France",
    logo: "/statics/product-1597486560136.png",
    name: "Klikx",
    phone: "516467464",
  });
  React.useEffect(() => {
    props.fetchProducts();
  }, []);
  React.useEffect(() => {
    const siteInfo = localStorage.getItem('siteInfo') ? JSON.parse(localStorage.getItem('siteInfo')) : undefined;
    if (siteInfo) {
      setStoreInformation(siteInfo);
    }
    fetch(API.settings.load, {
      method: "POST",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        key: "GENERAL",
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (!response.error){
          localStorage.setItem('siteInfo', JSON.stringify(response))
          if (JSON.stringify(siteInfo) !== JSON.stringify(response)) {
            setStoreInformation(response);
          }
        }
      })
      .catch((e) => {});
  }, []);

  React.useEffect(() => {
    if (props.productsList.length > 3) {
      const arr = getRandom(props.productsList, 3);
      setSuggestion(arr);
    }
  }, [props.productsList]);

  const getRandom = (arr, n) => {
    let result = new Array(n),
      len = arr.length,
      taken = new Array(len);
    if (n > len)
      throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
      var x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  };
  return (
    <FooterContainer>
      <div className="container">
        <FooterTop>
          <img src={storeInformation ? STATIC.images(storeInformation.logo) : images.logo} alt="logo" />
        </FooterTop>
        <FooterMiddle>
          <ListContainer className="footer-widget">
            <h5>{FooterText.information.TITLE}</h5>
            <ul>
              {informationFooterRoutes.map((elem, index) => (
                <li key={index}>
                  <Link to={elem.path}>{elem.name}</Link>
                </li>
              ))}
            </ul>
          </ListContainer>
          <ListContainer className="footer-widget">
            <h5>{FooterText.topItems.TITLE}</h5>
            <ul>
              {suggestion.map((elem) => (
                <li key={elem._id}>
                  <Link to={`/description/${elem._id}`}>{elem.name}</Link>
                </li>
              ))}
            </ul>
          </ListContainer>
          <ListContainer className="footer-widget">
            <h5>{FooterText.others.TITLE}</h5>
            <ul>
              {OthersFooterRoutes.map((elem, index) => (
                <li key={index}>
                  <Link to={elem.path}>{elem.name}</Link>
                </li>
              ))}
            </ul>
          </ListContainer>
          <ListContainer>
            <h5>{FooterText.socialMedia.TITLE}</h5>
            <ul className="social-media">
              <li>
                {" "}
                <a href="/" className="facebook">
                  {" "}
                  <i className="fab fa-facebook-f"></i>{" "}
                </a>{" "}
              </li>
              <li>
                {" "}
                <a href="/" className="instagram">
                  {" "}
                  <i className="fab fa-instagram"></i>{" "}
                </a>{" "}
              </li>
              <li>
                {" "}
                <a href="/" className="pinterest">
                  {" "}
                  <i className="fab fa-pinterest-p"></i>{" "}
                </a>{" "}
              </li>
              <li>
                {" "}
                <a href="/" className="twitter">
                  {" "}
                  <i className="fab fa-twitter"></i>{" "}
                </a>{" "}
              </li>
            </ul>
            <p>{FooterText.others.PARAGRAPH_TEXT}</p>
            {!props.isAutentificated && (
              <>
                <StyledLink to="/register" className="btn-sm notice-login">
                  {FooterText.others.BUTTON_TEXT}
                </StyledLink>

                <StyledLink to="/login" className="btn-sm ml-4 notice-login">
                  {FooterText.others.LOGIN_BUTTON_TEXT}
                </StyledLink>
              </>
            )}
          </ListContainer>
        </FooterMiddle>
        <FooterBottom>
          <p>{FooterText.footerBottom}</p>
        </FooterBottom>
      </div>
    </FooterContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    productsList: state.productsReducer.productsList,
    isAutentificated: state.authReducer.isAutentificated,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchProducts: () => {
      dispatch(fetchProducts());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
