/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { connect } from "react-redux";
import { setSubHeader } from "../../store/actions/stickyNavbarAction";
import Description from "../../components/Description/index";
import { useLocation, useHistory, useParams } from "react-router-dom";
import {
  addToCartByQuantity,
  addToCart,
} from "../../store/actions/cartActions";
import { fetchProducts } from "../../store/actions/productsActions";
import { SubHeaderText } from "../../constants/texts";
import { images } from "../../constants/env";
import Preloader from "../../components/Preloader/index";
import ProductOptions from "../../components/ProductOptions/index";
import Backdrop from "../../components/Backdrop/index";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function ProductDescription(props) {
  const [suggestion, setSuggestion] = React.useState([]);
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showOptions, setShowOptions] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [menus, setMenus] = useState([{link: '/', title: SubHeaderText.HOME}, {link: '/products', title: SubHeaderText.products.TITLE}]);

  // let query = useQuery();
  // let id = query.get("id");
  let history = useHistory();
  let { id } = useParams();

  React.useEffect(() => {
    if (product) {
      if(menus.length < 3) {
        menus.push({
          link: `/product/${product._id}`,
          title: product.name,
        });
      }
      props.setSubHeader(
        true,
        product.name,
        images.productDescriptionSubHeaderBgImage,
        menus
      );
    }
  }, [product]);

  React.useEffect(() => {
    props.fetchProducts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    /*
    if(props.productsList.length >0) {
     
      
    }*/
    const productSearch = props.productsList.find((c) => c._id === id);
    setProduct(productSearch);
    if (productSearch === undefined && props.productsList.length > 0) {
      props.setSubHeader(false);
      history.push("/404");
    }
  }, [props.productsList, id]);

  React.useEffect(() => {
    if (props.productsList.length > 3) {
      const arr = getRandom(props.productsList, 3);
      setSuggestion(arr);
    }
  }, [props.productsList]);
  React.useEffect(() => {
    if (props.loading === false && product && suggestion.length > 0) {
      setIsLoading(false);
    }
  }, [props.productsList, suggestion, product]);

  const handleAddTocartByQuantity = (item, quantity) => {
    props.addToCartByQuantity(item, quantity);
  };
  const handleAddTocart = (item) => {
    props.addToCart(item);
  };
  const handleShowOptions = (item) => {
    setProduct(item);
    setShowOptions(true);
    setShowBackdrop(true);
  };
  const handleCloseOption = () => {
    setShowOptions(false);
    setShowBackdrop(false);
  };

  const getRandom = (arr, n) => {
    let result = new Array(n),
      len = arr.length,
      taken = new Array(len);
    if (n > len)
      throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
      var x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  };

  return (
    <div>
      {showOptions && (
        <ProductOptions
          product={product}
          closeOption={handleCloseOption}
          addToCart={props.addToCart}
        />
      )}
      {showBackdrop && <Backdrop backdropClick={handleCloseOption} />}

      {isLoading ? (
        <Preloader />
      ) : (
        <Description
          handleShowOptions={handleShowOptions}
          product={product}
          addToCartByQuantity={handleAddTocartByQuantity}
          suggestion={suggestion}
          addToCart={handleAddTocart}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    product: state.productsReducer.productDetails,
    productsList: state.productsReducer.productsList,
    loading: state.productsReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSubHeader: (show, title, bgImage, menus) => {
      dispatch(setSubHeader(show, title, bgImage, menus));
    },
    addToCartByQuantity: (item, quantity) => {
      dispatch(addToCartByQuantity(item, quantity));
    },
    fetchProducts: () => {
      dispatch(fetchProducts());
    },
    addToCart: (item) => {
      dispatch(addToCart(item));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductDescription);
