import {
  ADD_TO_CART,
  REMOVE_ITEM,
  SUB_QUANTITY,
  ADD_QUANTITY,
  DESTROY_CART,
  ADD_TO_CART_BY_QUANTITY,
  VERIFY_PRODUCT_CART,
} from "./action-types/cart-actions";

// add cart action
export const addToCart = (item) => {
  return {
    type: ADD_TO_CART,
    payload: {
      item,
    },
  };
};
export const addToCartByQuantity = (item, quantity) => {
  return {
    type: ADD_TO_CART_BY_QUANTITY,
    payload: {
      item,
      quantity,
    },
  };
};

// remove item  action
export const removeItem = (index) => {
  return {
    type: REMOVE_ITEM,
    payload: {
      index,
    },
  };
};

// substruct quanitit action
export const subtractQuantity = (index) => {
  return {
    type: SUB_QUANTITY,
    payload: {
      index,
    },
  };
};

// ADD QUANTITY ACTION
export const addQuantity = (index) => {
  return {
    type: ADD_QUANTITY,
    payload: {
      index,
    },
  };
};

export const destroyCart = (item) => {
  return {
    type: DESTROY_CART,
  };
};
export const verify_product_cart = (items) => {
  return {
    type: VERIFY_PRODUCT_CART,
    payload: {
      items,
    },
  };
};
