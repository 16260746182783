import styled from "styled-components";
const Table = styled.table`
  width: 100%;
  margin-bottom: 30px;
  th {
    font-weight: 600;
    color: ${(props) => props.theme.checkout.OrderDetails.thColor};
    background-color: ${(props) => props.theme.checkout.OrderDetails.thBgColor};
    font-family: "Oswald", sans-serif;
    padding: 15px;
    vertical-align: middle;
  }
  tbody td {
    background-color: ${(props) => props.theme.checkout.OrderDetails.tdBgColor};
    border-bottom: 1px solid
      ${(props) => props.theme.checkout.OrderDetails.tdBoerderBottomColor};
    padding: 15px;
    vertical-align: middle;
  }
  tbody tr.total {
    td {
      background-color: ${(props) =>
        props.theme.checkout.OrderDetails.trTotalBgColor};
      color: ${(props) => props.theme.checkout.OrderDetails.totalTextColor};
    }
  }
  tbody h6 {
    font-size: 16px;
    margin: 0 0 5px;
    font-family: "Oswald", serif;
    font-weight: 700;
    line-height: 20px;
    color: ${(props) => props.theme.checkout.OrderDetails.totalTextColor};
  }
  a {
    color: ${(props) => props.theme.checkout.OrderDetails.productColor};
  }
  a:hover {
    color: ${(props) => props.theme.checkout.OrderDetails.productOnHoverColor};
    text-decoration: none;
    transition: 0.3s;
  }
`;
export { Table };
