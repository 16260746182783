import React from "react";
import {
  CarousselItemContent,
  CarousselItemContentImage,
  CarousselItemText,
  // Dots,
  BannerContainer,
  GlobalStyle,
} from "./style";
import { Bannerv1 } from "../../../constants/texts";
import { images } from "../../../constants/env";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
import { StyledLink } from "../../CustomButton/style";
import { STATIC } from "../../../constants/env";
const Banner = (props) => {
  const params = {
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  };

  return (
    <>
      <GlobalStyle />
      <BannerContainer>
        <Swiper {...params}>
          {props.slides && props.slides.length > 0
            ? props.slides.map((elem, index) => (
                <div key={index}>
                  <CarousselItemContent>
                    <CarousselItemContentImage
                      bgImage={STATIC.images(elem.image)}
                    />
                    <CarousselItemText bgImage={STATIC.images(elem.image)}>
                      <h1>{elem.title}</h1>
                      <h4>{elem.subTitle}</h4>
                      <p>{elem.paragraph}</p>
                      <div>
                        <StyledLink to="/products">
                          {elem.contentButton}
                        </StyledLink>
                      </div>
                    </CarousselItemText>
                  </CarousselItemContent>
                </div>
              ))
            : ""}
          {/*
          <div>
            <CarousselItemContent>
              <CarousselItemContentImage bgImage={images.bannerImage1} />
              <CarousselItemText bgImage={images.bannerImage1}>
                <h1>{Bannerv1.slide1.TITLE}</h1>
                <h4>{Bannerv1.slide1.SUBTITLE}</h4>
                <p>{Bannerv1.slide1.PARAGRAPH}</p>
                <div>
                  <StyledLink to="/products">
                    {Bannerv1.slide1.BUTTON_TEXT}
                  </StyledLink>
                </div>
              </CarousselItemText>
            </CarousselItemContent>
          </div>
          <div>
            <CarousselItemContent>
              <CarousselItemContentImage bgImage={images.bannerImage2} />
              <CarousselItemText bgImage={images.bannerImage2}>
                <h1>{Bannerv1.slide2.TITLE}</h1>
                <h4>{Bannerv1.slide2.SUBTITLE}</h4>
                <p>{Bannerv1.slide2.PARAGRAPH}</p>
                <div>
                  <a href="/">{Bannerv1.slide2.BUTTON_TEXT}</a>
                </div>
              </CarousselItemText>
            </CarousselItemContent>
          </div>
          */}
        </Swiper>
      </BannerContainer>
    </>
  );
};

export default Banner;

/* 
 <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
        <ol className="carousel-indicators">
          <li data-target="#carouselExampleIndicators" data-slide-to={0} className="active" />
          <li data-target="#carouselExampleIndicators" data-slide-to={1} />
          
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <CarousselItemContent>
              <CarousselItemContentImage />
              <div>

              </div>
              <CarousselItemText>
                  <h1>Italian Pizza Never Got Any Better</h1>
                  <h4>It is the cheese that lures us into eating</h4>
                  <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s

                  </p>
                  <div>
                    <a href="/">View all</a>
                  </div>
              </CarousselItemText>
            </CarousselItemContent>
          </div>
          <div className="carousel-item">
          <CarousselItemContent>
              <CarousselItemContentImage />
              <CarousselItemText>
                  <h1>Italian Pizza Never Got Any Better</h1>
                  <h4>It is the cheese that lures us into eating</h4>
                  <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
              
                  </p>
                  <div>
                    <a href="/">View all</a>
                  </div>
              </CarousselItemText>
            </CarousselItemContent>
          </div>
          
        </div>
        <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="sr-only">Next</span>
        </a>
      </div>


*/

/*
const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => <Dots> {dots} </Dots>,
  };
<Slider {...settings} style={{ heigh: "10px" }}>
        <div>
          <CarousselItemContent>
            <CarousselItemContentImage bgImage="/assets/image/banner/1.jpg"/>
            <CarousselItemText bgImage="/assets/image/banner/1.jpg">
              <h1>Italian Pizza Never Got Any Better</h1>
              <h4>It is the cheese that lures us into eating</h4>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s
              </p>
              <div>
                <a href="/">View all</a>
              </div>
            </CarousselItemText>
          </CarousselItemContent>
        </div>
        <div>
          <CarousselItemContent>
            <CarousselItemContentImage bgImage="/assets/image/banner/2.jpg" />
            <CarousselItemText bgImage="/assets/image/banner/2.jpg">
              <h1>Celebrating 100 Years of Cheesy Pizza</h1>
              <h4>Join our grand opening tonight for free pizza</h4>
              <p>
                
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
            
              </p>
              <div>
                <a href="/">View all</a>
              </div>
            </CarousselItemText>
          </CarousselItemContent>
        </div>
      </Slider>
*/
/*
<div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
        
        <ol className="carousel-indicators">
          <li data-target="#carouselExampleIndicators" data-slide-to={0} className="active" />
          <li data-target="#carouselExampleIndicators" data-slide-to={1} />
          
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item active">
          <CarousselItemContent>
            <CarousselItemContentImage bgImage="/assets/image/banner/1.jpg"/>
            <CarousselItemText bgImage="/assets/image/banner/1.jpg">
              <h1>Italian Pizza Never Got Any Better</h1>
              <h4>It is the cheese that lures us into eating</h4>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s
              </p>
              <div>
                <a href="/">View all</a>
              </div>
            </CarousselItemText>
          </CarousselItemContent>
          </div>
          <div className="carousel-item">
          <CarousselItemContent>
            <CarousselItemContentImage bgImage="/assets/image/banner/2.jpg" />
            <CarousselItemText bgImage="/assets/image/banner/2.jpg">
              <h1>Celebrating 100 Years of Cheesy Pizza</h1>
              <h4>Join our grand opening tonight for free pizza</h4>
              <p>
                
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
            
              </p>
              <div>
                <a href="/">View all</a>
              </div>
            </CarousselItemText>
          </CarousselItemContent>
          </div>
          
        </div>
        <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="sr-only">Next</span>
        </a>
      </div>
*/
