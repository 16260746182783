import styled from 'styled-components';

const BackdropContainer = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    background: rgba(0,0,0,0.6);
    z-index: 1000;
    
`
export {BackdropContainer};