/* eslint-disable no-unused-vars */
import React, {useState} from "react";
import { SearchContainer, Close, SearchForm, SearchProducts } from "./style";
import { ProductContainer } from "../Menu/style";
import Product from "../Product/index";

const Search = (props) => {
  return (
    <SearchContainer className={props.show ? "open" : ""}>
      <Close onClick={props.SearchClick}>
        <span></span>
        <span></span>
      </Close>

      <SearchForm className={props.show ? "open" : ""} method="post">
        <input type="text" placeholder="Search..." />
        <button type="submit" className="search-btn">
          <i className="flaticon-magnifying-glass"></i>
        </button>
      </SearchForm>
      <SearchProducts>
        <div className="container">
          <div className="row">
            { /*
            props.productsToShow.map((elem) => (
              <ProductContainer className="col-lg-4 col-md-6" key={elem._id}>
                <Product
                  item={elem}
                  title={elem.name}
                  img={`http://apisite.klikx.fr/${elem.picture}`}
                  text={elem.description}
                  price={elem.price}
                  add={props.addItemToCart}
                />
              </ProductContainer>
            ))
                */
        }
          </div>
        </div>
      </SearchProducts>
    </SearchContainer>
  );
};

export default Search;
