/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { API } from "../../constants/env";
import { connect } from "react-redux";
import { setSubHeader } from "../../store/actions/stickyNavbarAction";

import ForgetPassword from "../../components/ForgetPassword/index";
const ForgetPasswordPage = (props) => {
  const [content, setContent] = useState(
    localStorage.getItem("RESET")
      ? JSON.parse(localStorage.getItem("RESET"))
      : {}
  );

  useEffect(() => {
    const fetchData = () => {
      fetch(API.content.load, {
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: "RESET",
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (JSON.stringify(response) !== localStorage.getItem("RESET")) {
            localStorage.setItem("RESET", JSON.stringify(response));
            setContent(response);
          }
        })
        .catch((e) => {});
    };
    fetchData();
  }, []);
  React.useEffect(() => {
    props.setSubHeader(false);
  }, []);
  return (
    <div>
      <ForgetPassword content={content} />
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    setSubHeader: (show) => {
      dispatch(setSubHeader(show));
    },
  };
};
export default connect(null, mapDispatchToProps)(ForgetPasswordPage);
