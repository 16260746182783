const baseURL = "//api.latableduboulangeraubagne.fr";
const staticURL = "//api.latableduboulangeraubagne.fr/";

//const baseURL = "http://127.0.0.1:5002";
//const staticURL = "http://127.0.0.1:5002/";

exports.STATIC = {
  //  images: (imgUrl) => staticURL+(imgUrl ? imgUrl : '/no-image.png'),
  images: (imgUrl) => staticURL + imgUrl,
};

exports.API = {
  products: {
    list: baseURL + "/public/v1/products/list",
  },
  checkout: {
    make: baseURL + "/public/v1/orders/make",
  },
  auth: {
    register: baseURL + "/user/v1/auth/register", 
    login: baseURL + "/user/v1/auth/login",
    reset: baseURL + "/user/v1/auth/reset",
    resetPassword: baseURL + "/user/v1/auth/reset-password",
  },
  me: {
    getUser: baseURL + "/user/v1/me/",
    update: baseURL + "/user/v1/me/",
    updatePassword: baseURL + "/user/v1/me/password",
    listOrder: baseURL + "/user/v1/order/list",
    getOrderId: (id) => baseURL + "/user/v1/order/info/" + id,
  },
  message: {
    send: baseURL + "/public/v1/messages/send",
  },
  settings: {
    load: `${baseURL}/public/v1/settings/load`,
  },
  content: {
    load: `${baseURL}/public/v1/pages/load`,
  },
};

exports.position = {
  latitude: 36.8065,
  longtitude: 10.1815,
};
exports.maps = (lat, lng) => ({
  url: `https://maps.google.com/?q=${lat},${lng}`,
});
exports.images = {
  logo: "/assets/image/logo.png",
  logoLight: "/assets/image/logo-light.png",
  auth: "/assets/image/auth.jpg",
  accountProfile: "/assets/image/prods-sm/1.png",
  newsLetterBgImage: "/assets/image/bg/1.jpg",
  bannerImage1: "/assets/image/banner/1.jpg",
  bannerImage2: "/assets/image/banner/2.jpg",
  productsSubHeaderBgImage: "/assets/image/subheader.jpg",
  aboutSubHeaderBgImage: "/assets/image/subheader-locations.jpg",
  cartSubHeaderBgImage: "/assets/image/subheader-2.jpg",
  checkoutSubHeaderBgImage: "/assets/image/subheader-2.jpg",
  contactSubHeaderBgImage: "/assets/image/subheader-locations.jpg",
  productDescriptionSubHeaderBgImage: "/assets/image/subheader.jpg",
};
//exports.REACT_APP_GOOGLE_API_KEY = "AIzaSyCDJ_8QPJUFbK_v_CAeY3J1T9ehvMoJq2A";

exports.REACT_APP_GOOGLE_API_KEY = "AIzaSyCep1aEs90BdL71_GmqJi5bHQgBaZDWHg4";
