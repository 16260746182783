import React from "react";
import PayementSucceed from "../../components/PaymentSucceed/index";
function PaymentSuccedPage() {
  return (
    <div>
      <PayementSucceed />
    </div>
  );
}

export default PaymentSuccedPage;
