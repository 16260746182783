import { FETCH_PRODUCTS_REQUEST,
    FETCH_PRODUCTS_SUCCESS,
    FETCH_PRODUCTS_FAILURE,
    SET_TODAY_PRODUCTS,
    SET_CATEGRORIES,
    SELECT_CATEGORY,
    SET_PRODUCTS_TO_SHOW,
    SET_ASC_PRODUCTS_TO_SHOW,
    SET_DESC_PRODUCTS_TO_SHOW
    
} from './action-types/products-action';
import {API} from '../../constants/env';
export const fetchProductsRequest = () => {
    return {
        type: FETCH_PRODUCTS_REQUEST
    }
}
export const fetchProductsSuccess = (products) => {
    return {
        type: FETCH_PRODUCTS_SUCCESS,
        payload:{
            products
        }
        
    }
}
export const fetchProductsFailure = error => {
    return{
        type: FETCH_PRODUCTS_FAILURE,
        payload:{
            error
        }
    }
}

export const setTodayProducts = (products) => {
    return {
        type: SET_TODAY_PRODUCTS,
        payload:{
            products
        }
        
    }
}

export const setCategories = (products) => {
    return {
        type: SET_CATEGRORIES,
        payload:{
            products
        }
       
    }
}
export const selectCategory = (id) => {
    return {
        type: SELECT_CATEGORY,
        payload:{
            id
        }
        
    }
}
export const setProductsToShow = () => {
    return {
        type: SET_PRODUCTS_TO_SHOW,
    }
}

export const set_asc_products_to_show = () => {
    return {
        type: SET_ASC_PRODUCTS_TO_SHOW
    }
}


export const set_desc_products_to_show = () => {
    return {
        type: SET_DESC_PRODUCTS_TO_SHOW
    }
}



export const fetchProducts = () => {
   return (dispatch) => {
        dispatch(fetchProductsRequest())
        fetch(API.products.list)
        .then(res => res.json())
        .then(res => {
            if(res.error){
                dispatch(fetchProductsFailure(res.message))

            } else {
                dispatch(fetchProductsSuccess(res));
            /*
            dispatch(setTodayProducts(res));
            dispatch(setCategories(res));
            dispatch(setProductsToShow())
                */
            }
        })
        .catch(err => console.log(err))
   }  
}