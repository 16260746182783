import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

const TrendingContainer = styled.div`
`;
//         padding-bottom: 13px;

const GlobalStyle = createGlobalStyle `
    .swiper-container{
    }
    .swiper-slide{
        height: auto;
    }

`

const TitleSection= styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    margin-bottom: 50px;
    h5{
        margin-bottom:15px;
        color:${(props)=>props.theme.trending.TitleSection.subTitleColor};
        font-size:18px;
        font-weight: 700;
        line-height: 28px;
    }
    h2{
        font-size:42px;
        margin-bottom:20px;
        line-height:48px;
        color:${(props)=>props.theme.trending.TitleSection.titleColor};
        font-weight:700;
    }
    p{
        max-width: 600px;
        margin:0 auto 20px;
        line-height: 28px;
        font-size:16px;
        color:${(props)=>props.theme.trending.TitleSection.paragraphColor};
    }
`
const SliderSection = styled.div`
   
`
const NavigationButtonContainer = styled.div`
    text-align:center;
    justify-content:center;
    display:flex;
`
const NavigateButton = styled.button`
    display:flex;
    align-items:center;
    justify-content:center;
    background: transparent;
    border: none;
    cursor: pointer;
    width: 70px;
    height:70px;
    padding:0;
    margin:0 20px 40px;
    margin-top:20px;
    box-sizing: border-box;
    color: ${(props)=>props.theme.trending.NavigationBottom.color};
    &:focus{
        outline: none;
    };
    border-radius:50%;
    border:1px solid ${(props)=>props.theme.trending.NavigationBottom.borderColor};
    &:hover{
        background-color: ${(props)=>props.theme.trending.NavigationBottom.onHoverBgColor};
        color:${(props)=>props.theme.trending.NavigationBottom.onHoverColor};
    }
`
const NoButton= styled.div`
    height: 40px;
`


const ProductSwiperContainer = styled.div`
  padding:13px;
`
export {TrendingContainer, TitleSection,SliderSection, NavigationButtonContainer, NavigateButton , GlobalStyle, NoButton, ProductSwiperContainer};