import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setSubHeader } from "../../store/actions/stickyNavbarAction";
import Login from "../../components/Login/index";
import { loginUser, resetState } from "../../store/actions/authActions";
import { API } from "../../constants/env";
const LoginPage = (props) => {
  const [content, setContent] = useState(
    localStorage.getItem("LOGIN")
      ? JSON.parse(localStorage.getItem("LOGIN"))
      : {}
  );

  useEffect(() => {
    const fetchData = () => {
      fetch(API.content.load, {
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: "LOGIN",
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (JSON.stringify(response) !== localStorage.getItem("LOGIN")) {
            localStorage.setItem("LOGIN", JSON.stringify(response));
            setContent(response);
          }
        })
        .catch((e) => {});
    };
    fetchData();
  }, []);
  React.useEffect(() => {
    props.setSubHeader(false);
    props.resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogin = async (creds, history, rememberMe) => {
    props.loginUser(creds, history, rememberMe);
  };
  return (
    <div>
      <Login
        handleLogin={handleLogin}
        errorMessage={props.errorMessage}
        isFetching={props.isFetching}
        content={content}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    errorMessage: state.authReducer.errorMessage,
    isAutentificated: state.authReducer.isAutentificated,
    isFetching: state.authReducer.isFetching,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (creds, history, rememberMe) => {
      dispatch(loginUser(creds, history, rememberMe));
    },
    setSubHeader: (show) => {
      dispatch(setSubHeader(show));
    },
    resetState: () => {
      dispatch(resetState());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
