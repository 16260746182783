import styled from "styled-components";
const CartModalContainer = styled.nav`
  height: 100vh;
  background: ${(props) => props.theme.cart.modal.bgColor};
  box-shadow: 0 2px 32px rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  right: 0;
  width: 60vw;
  z-index: 1200;
  display: flex;
  flex-direction: column;
  transform: translateX(160vh);
  transition: transform 0.4s ease-out;
  &.drawer-transtion {
    transform: translateX(0);
  }

  @media (max-width: 991px) {
    width: 100%;
    height: 100%;
  }
`;
const HeaderCartModal = styled.div`
  display: flex;
  flex: 1;
  padding: 40px;
  justify-content: center;
  justify-content: space-between;
  h3 {
    font-size: 35px;
    line-height: 43px;
    color: ${(props) => props.theme.cart.modal.headerCartModal.h3.color};
    font-weight: 700;
    text-transform: uppercase;
  }
  @media (max-width: 768px) {
    padding: 20px;
    h3 {
      font-size: 26px;
      line-height: 32px;
    }
  }
`;
const BodyCartModal = styled.div`
  display: flex;
  flex-direction: column;
  flex: 10;
  padding: 0 40px;
  overflow: auto;
  margin-right: 40px;
  margin-bottom: 40px;

  @media (max-width: 786px) {
    margin-right: 15px;
    margin-bottom: 20px;
  }
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) =>
      props.theme.cart.modal.scroll.scrollbarTrackBgColor};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) =>
      props.theme.cart.modal.scroll.scrollbarThumbBgColor};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) =>
      props.theme.cart.modal.scroll.scrollbarThumbHoverBgColor};
  }
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;
const FooterCartModal = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${(props) =>
    props.theme.cart.modal.footerCartModal.bgColor};
  padding: 40px;
  h4 {
    margin: 0;
    font-size: 20px;
    line-height: 32px;
  }
  h4 span {
    font-size: 28px;
    margin-left: 10px;
  }
  @media (max-width: 768px) {
    padding: 20px;
    h4 {
      font-size: 22px;
      line-height: 30px;
    }
  }
  @media (max-width: 575px) {
    display: block;
  }
`;
const CloseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-color: ${(props) => props.theme.cart.modal.closeButton.bgColor};
  &:hover {
    span:nth-child(1) {
      transform: rotate(-90deg);
    }
    span:nth-child(2) {
      transform: rotate(90deg);
    }
  }
  span {
    background-color: ${(props) =>
      props.theme.cart.modal.closeButton.spanBgColor};
    width: 3px;
    height: 30px;
    transform: rotate(45deg);
    transform-origin: center;
    opacity: 1;
    position: absolute;
    display: block;
  }
  span:nth-child(2) {
    transform: rotate(-45deg);
  }
  &.details-cart-item {
    position: relative;
    border: 1px dashed
      ${(props) =>
        props.theme.cart.modal.closeButton.detailsCartItem.borderColor};
    border-radius: 50%;
    margin-left: 25px;

    span {
      position: absolute;
      background-color: ${(props) =>
        props.theme.cart.modal.closeButton.detailsCartItem.spanBgColor};
      width: 2px;
      height: 15px;
    }
  }
  &.table-cart {
    padding: 0;
    span {
      background-color: red;
      width: 2px;
      height: 15px;
      opacity: 0.6;
      transform-origin: center;
    }
  }
  &.product-options {
    position: fixed;
    top: -16px;
    left: auto;
    right: 16px;
    height: 25px;
    width: 25px;
    z-index: 1001;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 2px 32px rgba(0, 0, 0, 0.15);
    padding: 5px;

    span {
      background-color: red;
      width: 2px;
      height: 20px;
      opacity: 0.6;
      transform-origin: center;
    }
    @media (max-width: 991px) {
      top: 8px;
    }
  }
`;

export {
  CartModalContainer,
  HeaderCartModal,
  BodyCartModal,
  FooterCartModal,
  CloseButton,
};
