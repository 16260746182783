import styled from 'styled-components';
import {createGlobalStyle} from 'styled-components';
const NoticeLoginContainer = styled.div`
    background-color: ${(props)=>props.theme.checkout.NoticeLogin.bgColor};
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 8px;
p{
    margin: 0;
    font-weight: 600;
    font-size: 15px;
    line-height: 28px;
    color: ${(props)=>props.theme.checkout.NoticeLogin.textColor};
    
}
button{
    margin-left:2px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
    &:focus {
        outline: none;
    }
    color: ${(props)=>props.theme.checkout.NoticeLogin.clickButtonColor};

}
`
const FormContainer = styled.div`
        overflow: hidden;

        &.show{
            height: auto; 
        }
        
    
    

`

const GlobalStyle = createGlobalStyle`
/*
  Slide animation styles.
  You may need to add vendor prefixes for transform depending on your desired browser support.
*/

.slide-enter {
  transform: translateY(-100%);
  transition: .4s ease-in-out;

  &.slide-enter-active {
    transform: translateY(0%);
  }
}

.slide-leave {
  transform: translateY(0%);
  transition: .4s ease-in-out;

  &.slide-leave-active {
    transform: translateY(-100%);
  }
}

/*
  CSS for the submenu container needed to adjust the behavior to our needs.
  Try commenting out this part to see how the animation looks without the container involved.
*/
`
export {NoticeLoginContainer, FormContainer, GlobalStyle}