import {
    LOGIN_REQUEST, 
    LOGIN_SUCCESS, 
    LOGIN_FAILURE, 
    LOGOUT_SUCCESS, 
    RESET_STATE,
  } from '../actions/action-types/auth-actions';

const initialState = {
    isFetching:false,
    isAutentificated: localStorage.getItem('id_token') ? true : sessionStorage.getItem('id_token')? true: false
}

const authReducer = (state= initialState, action)=>{

    if(action.type === LOGIN_REQUEST){
        return{
            ...state,
            isFetching: true,
            isAutentificated: false,
            user: action.creds,
        
        }
    }
   


    if(action.type === LOGIN_SUCCESS){
        return{
            ...state,
            isFetching: false,
            isAutentificated: true,
            errorMessage: ''
            
        }
    }
  
    if(action.type === RESET_STATE){
        return {
            ...state,
            isFetching: false,
            errorMessage: ''
        }
    }
    if(action.type === LOGIN_FAILURE){
        return{
            ...state,
            isFetching: false,
            isAutentificated: false,
            errorMessage: action.message
            
        }
    }
    
    if(action.type === LOGOUT_SUCCESS){
        return{
            ...state,
            isFetching: true,
            isAutentificated: false,            
        }
    }
    else {
        return state
    }



}

export default authReducer;