import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker, Circle } from "google-maps-react";
import Geocode from "react-geocode";

const mapStyles = {
  width: "100%",
  height: "100%",
  
};
const modalStyles = {
  width: "100%",
  height: "95%",
}

export class MapComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lat: 0,
      lng: 0,
      clientLat: 0,
      clientLng: 0,
      distance: 0
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    console.log(nextProps);
    if (
      nextProps.lat !== prevState.lat ||
      nextProps.lng !== prevState.lng ||
      nextProps.distance !== prevState.distance
    ) {
      return {
        lat: nextProps.lat,
        lng: nextProps.lng,
        clientLat: nextProps.lat,
        clientLng: nextProps.clientLng,
        distance: nextProps.distance,
      };
    } else return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.lat !== this.props.lat ||
      prevProps.lng !== this.props.lng ||
      prevProps.distance !== this.props.distance

    ) {
      //Perform some operation here
      this.setState({
        lat: this.props.lat,
        lng: this.props.lng,
        distance: this.props.distance,

      });
      //this.classMethod();
    }
  }
  componentDidMount(){
    this.setState({
      clientLat:this.props.lat,
      clientLng:this.props.lng,
    })
  }
  async onMarkerDragEnd(coord, index) {
    Geocode.setApiKey("AIzaSyCep1aEs90BdL71_GmqJi5bHQgBaZDWHg4");
    Geocode.setLanguage("fr");
    Geocode.enableDebug();

    //this.props.setLocation(coord);
    const { latLng } = coord;
    let formatedAdress = "";
    let city = "";
    let zipCode = "";
    await Geocode.fromLatLng(latLng.lat(), latLng.lng()).then(
      (response) => {
        const address = response.results[0].formatted_address;
        console.log(address);
        formatedAdress = address;
        city= response.results[0].address_components.find(elem => elem.types.includes('locality') === true);
        zipCode = response.results[0].address_components.find(elem => elem.types.includes('postal_code') === true);
      },
      (error) => {
        console.error(error);
      }
    );
    this.setState((prevState) => {
      return {
        ...this.state,
        clientLat: latLng.lat(),
        clientLng: latLng.lng(),
      };
    });
    this.props.setLocation(latLng.lat(), latLng.lng(), formatedAdress, city, zipCode);
  }
  render() {
    return (
      <Map
        google={this.props.google}
        zoom={9}
        style={this.props.modal ? modalStyles: mapStyles}
        initialCenter={{
          lat: this.state.lat,
          lng: this.state.lng
        }}
        center={{
          lat: this.state.clientLat ? this.state.clientLat : this.state.lat ,
          lng: this.state.clientLng ? this.state.clientLng : this.state.lng
        }}
      >
        <Marker
          title="Location"
          id={1}
          position={{
            lat: this.state.clientLat ? this.state.clientLat : this.state.lat ,
            lng: this.state.clientLng ? this.state.clientLng : this.state.lng  
          }}
          draggable={this.props.drag ? true : false}
          onDragend={(t, map, coord) => {
            this.onMarkerDragEnd(coord);
          }}
        ></Marker>
         {this.props.delivery && (
          <Circle
            radius={this.state.distance}
            center={{
              lat: Number(this.state.lat),
              lng: Number(this.state.lng),
            }}
            onMouseover={() => console.log("mouseover")}
            onClick={() => console.log("click")}
            onMouseout={() => console.log("mouseout")}
            strokeColor="transparent"
            strokeOpacity={0}
            strokeWeight={5}
            fillColor="#FF0000"
            fillOpacity={0.2}
          />
        )}
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCep1aEs90BdL71_GmqJi5bHQgBaZDWHg4"
})(MapComponent);
