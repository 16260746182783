import React, { useState } from "react";
import {
  ProductOptionsContainer,
  ProductOptionBody,
  ProductOptionFooter,
  ProductInformation,
  ProductHeader,
  ProductDescription,
  ProductInstructions,
  ProductVariation,
  ProductToppingTitle,
  ProductToppingItem,
  Quantity,
  QuantityContainer,
  QuantiyButtonContainer,
  CancelContainer,
  AddContainer,
  RequiredItem,
  Border,
  ErrorContainer,
} from "./style";
import { CloseButton } from "../Cart/Modal/style";
import { StyledButton } from "../CustomButton/style";
import { v4 as uuidv4 } from "uuid";
import { ProductOptionsText } from "../../constants/texts";
import { optionError } from "../../constants/errors";
import { LabelInput, Input } from "../Description/style";
const ProductOptions = (props) => {
  const [total, setTotal] = useState(props.product.price);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedSupplements, setSelectedSupplements] = useState([]);
  const [instructions, setInstructions] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [errors, setErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [requiredOptions, setRequiredOptions] = useState([]);
  React.useEffect(() => {
      console.log('Event for selected supp', selectedSupplements);
      let total = props.product.price;
      selectedOptions.forEach((elem) => {
        elem.items.forEach((item) => (total += item.price));
      });
      selectedSupplements.forEach((elem) => {
        elem.items.forEach((item) => (total += item.price));
      });
      setTotal(total * quantity)
  }, [selectedSupplements, selectedOptions])
  React.useEffect(() => {
    let arrayOptions = [];
    // eslint-disable-next-line array-callback-return
    props.product.options.map((elem) => {
      if (elem.requiredItems > 0) {
        arrayOptions.push(elem);
      }
    });
    setRequiredOptions(arrayOptions);
  }, [props.product]);
  const handleAddQuantity = () => {
    setQuantity((prevState) => prevState + 1);
  };
  const handleSubQuantity = () => {
    if (quantity > 1) {
      setQuantity((prevState) => prevState - 1);
    }
  };
  const onOptionChange = (e, option, item) => {
    let options = [...selectedOptions];

    if (e.target.type === "radio") {
      const optionSelected = {
        _id: option._id,
        name: e.target.name,
        requiredItems: option.requiredItems,
        items: [item],
      };
      if (selectedOptions.length > 0) {
        const findedElement = options.findIndex((x) => x._id === option._id);
        if (findedElement === -1) {
          setSelectedOptions([...selectedOptions, optionSelected]);
        } else {
          options[findedElement] = optionSelected;
          setSelectedOptions(options);
        }
      } else {
        setSelectedOptions([...selectedOptions, optionSelected]);
      }
    }
    if (e.target.type === "checkbox") {
      if (selectedOptions.length > 0) {
        const findedElement = options.findIndex((x) => x._id === option._id);
        if (findedElement === -1) {
          const optionSelected = {
            _id: option._id,
            name: e.target.name,
            requiredItems: option.requiredItems,
            items: [item],
          };
          setSelectedOptions([...selectedOptions, optionSelected]);
        } else {
          const oldItems = options[findedElement].items;
          const findedItem = oldItems.findIndex((x) => x._id === item._id);
          if (findedItem === -1) {
            options[findedElement].items.push(item);
            setSelectedOptions(options);
          } else {
            if (options[findedElement].items.length === 1) {
              options.splice(findedElement, 1);
              setSelectedOptions(options);
            } else {
              const items = options[findedElement].items;
              items.splice(findedItem, 1);
              options[findedElement].items = items;

              setSelectedOptions(options);
            }
          }
        }
      } else {
        const optionSelected = {
          _id: option._id,
          name: e.target.name,
          requiredItems: option.requiredItems,

          items: [item],
        };
        setSelectedOptions([...selectedOptions, optionSelected]);
      }
    }
  };
  const onSupplementChange = (e, supplement, item) => {
    console.log('you have slected an item')
    let supplements = [...selectedSupplements];
    if (selectedSupplements.length > 0) {
      const findedElement = supplements.findIndex(
        (x) => x._id === supplement._id
      );
      if (findedElement === -1) {
        const supplementSelected = {
          _id: supplement._id,
          name: e.target.name,
          items: [item],
        };
        setSelectedSupplements([...selectedSupplements, supplementSelected]);
      } else {
        const oldItems = supplements[findedElement].items;
        const findedItem = oldItems.findIndex((x) => x._id === item._id);
        if (findedItem === -1) {
          supplements[findedElement].items.push(item);
          setSelectedSupplements(supplements);
        } else {
          if (supplements[findedElement].items.length === 1) {
            supplements.splice(findedElement, 1);
            setSelectedSupplements(supplements);
          } else {
            const items = supplements[findedElement].items;
            items.splice(findedItem, 1);
            supplements[findedElement].items = items;
            setSelectedSupplements(supplements);
          }
        }
      }
    } else {
      console.log('fist time selection');
      const supplementSelected = {
        _id: supplement._id,
        name: e.target.name,
        items: [item],
      };
      console.log('setting items to ', [supplementSelected]);
      setSelectedSupplements([supplementSelected]);
    }
  };
  const handleAddToCart = (e) => {
    e.preventDefault();
    let ok = false;
    if (requiredOptions.length > 0 && selectedOptions.length === 0) {
      setErrors(true);
      setErrorMessage(optionError.noOption);
    } else if (selectedOptions.length > 0 && requiredOptions.length > 0) {
      for (let [i, option] of requiredOptions.entries()) {
        const resultIndex = selectedOptions.findIndex(
          (elem) => elem.name === option.name
        );
        if (resultIndex === -1) {
          setErrors(true);
          setErrorMessage(
            `${optionError.requiredSpecificOption} ${option.requiredItems} ${option.name}`
          );
          break;
        } else {
          if (
            selectedOptions[resultIndex].requiredItems >
            selectedOptions[resultIndex].items.length
          ) {
            setErrors(true);
            setErrorMessage(
              `${optionError.requiredSpecificOption} ${selectedOptions[resultIndex].requiredItems} ${option.name}`
            );
            break;
          }
          if (i + 1 === requiredOptions.length) {
            if (
              selectedOptions[resultIndex].requiredItems <=
              selectedOptions[resultIndex].items.length
            ) {
              ok = true;
            }
          }
        }
      }
    } else {
      ok = true;
    }
    if (ok) {
      setErrors(false);
      const product = {
        key_id: uuidv4(),
        _id: props.product._id,
        selectedOptions: selectedOptions,
        selectedSupplements: selectedSupplements,
        quantity,
        instructions,
      };
      props.addToCart(product);
      props.closeOption();
    }
  };
  const calcTotal = () => {
    let total = props.product.price;
    selectedOptions.forEach((elem) => {
      elem.items.forEach((item) => (total += item.price));
    });
    selectedSupplements.forEach((elem) => {
      elem.items.forEach((item) => (total += item.price));
    });

    return total * quantity;
  };
  
  function createMarkup(text) {
    return { __html: text };
  }
  return (
    <ProductOptionsContainer>
      {/* <CloseButton className="product-options" onClick={props.closeOption}>
        <span />
        <span />
      </CloseButton> */}
      <ProductInformation>
        <ProductHeader>
          <h2>{props.product.name}</h2>
          <span>{props.product.price.toFixed(2)}€</span>
        </ProductHeader>
        <ProductDescription
          dangerouslySetInnerHTML={createMarkup(props.product.description)}
        />
      </ProductInformation>
      <ProductOptionBody>
        {props.product.options.length > 0
          ? props.product.options.map((elem, index) => (
            <ProductVariation key={index}>
              <ProductToppingTitle>
                <span>{elem.name}</span>
                {elem.requiredItems > 0 && (
                  <RequiredItem>
                    {elem.requiredItems} {ProductOptionsText.REQUIRED_TEXT}
                  </RequiredItem>
                )}
              </ProductToppingTitle>
              <ProductToppingItem className="product-topping-item">
                {elem.items.map((element, index) => (
                  <div
                    className={`custom-control ${
                      elem.requiredItems === 1
                        ? "custom-radio"
                        : "custom-checkbox"
                      }`}
                    key={index}
                  >
                    <Input
                      type={elem.requiredItems === 1 ? "radio" : "checkbox"}
                      className="options"
                      name={elem.name}
                      id={`${elem.name}_${element.name}`}
                      value={element.name}
                      onChange={(e) => onOptionChange(e, elem, element)}
                    />
                    <LabelInput
                      className="form-check-label options"
                      type={elem.requiredItems === 1 ? "radio" : "checkbox"}
                      for={`${elem.name}_${element.name}`}
                    >
                      <span>{element.name}</span>
                      <span>
                        {element.price > 0 && (
                          <span>(+{element.price.toFixed(2)}€)</span>
                        )}
                      </span>
                    </LabelInput>
                  </div>
                ))}
              </ProductToppingItem>
            </ProductVariation>
          ))
          : ""}
        {props.product.supplements.length > 0
          ? props.product.supplements.map((elem, index) => (
            <ProductVariation key={index}>
              <ProductToppingTitle>
                <span>{elem.name}</span>
              </ProductToppingTitle>
              <ProductToppingItem className="product-topping-item">
                {elem.items.map((element, index) => (
                  <div className="custom-control custom-checkbox" key={index}>
                    <Input
                      type="checkbox"
                      name={elem.name}
                      id={`${elem.name}_${element.name}`}
                      className="options"
                      value={element.name}
                      onChange={(e) => onSupplementChange(e, elem, element)}
                    />
                    <LabelInput
                      for={`${elem.name}_${element.name}`}
                      className="form-check-label options"
                      type={elem.requiredItems === 1 ? "radio" : "checkbox"}
                    >
                      <span>{element.name}</span>
                      <span>
                        {element.price > 0 && (
                          <span>(+{element.price.toFixed(2)}€)</span>
                        )}
                      </span>
                    </LabelInput>
                  </div>
                ))}
              </ProductToppingItem>
            </ProductVariation>
          ))
          : ""}
        {(props.product.options.length > 0 ||
          props.product.supplements.length > 0) && <Border />}

        <ProductInstructions>
          <h3>{ProductOptionsText.SPECIAL_INSTRUCTIONS_TEXT}</h3>
          <p>{ProductOptionsText.SPECIAL_INSTRUCTIONS_PARAGRAPH}</p>
          <textarea
            className="form-control"
            placeholder={ProductOptionsText.SPECIAL_INSTRUCTIONS_PLACEHOLDER}
            onChange={(e) => setInstructions(e.target.value)}
          ></textarea>
        </ProductInstructions>
      </ProductOptionBody>

      <ProductInstructions>
        <QuantityContainer className={errors ? "error" : ""}>
          <Quantity>
            <button onClick={handleSubQuantity}>
              <QuantiyButtonContainer>
                <svg height="24" width="24" viewBox="0 0 24 24">
                  <path d="M12 2C17.5228 2 22 6.47725 22 12C22 17.5228 17.5228 22 12 22C6.47717 22 2 17.5228 2 12C2 6.47725 6.47717 2 12 2ZM12 20C16.4113 20 20 16.4113 20 12C20 7.58875 16.4113 4 12 4C7.58875 4 4 7.58875 4 12C4 16.4113 7.58875 20 12 20ZM7 13.5V10.5H17V13.5H7Z"></path>
                </svg>
              </QuantiyButtonContainer>
            </button>
            <span>{quantity}</span>
            <button onClick={handleAddQuantity}>
              <QuantiyButtonContainer>
                <svg height="24" width="24" viewBox="0 0 24 24">
                  <path d="M12 2C17.5228 2 22 6.47725 22 12C22 17.5228 17.5228 22 12 22C6.47717 22 2 17.5228 2 12C2 6.47725 6.47717 2 12 2ZM12 20C16.4113 20 20 16.4113 20 12C20 7.58875 16.4113 4 12 4C7.58875 4 4 7.58875 4 12C4 16.4113 7.58875 20 12 20ZM13.5 7V10.4999H17V13.5H13.5V17H10.5V13.5H7V10.4999H10.5V7H13.5Z"></path>
                </svg>
              </QuantiyButtonContainer>
            </button>
          </Quantity>
        </QuantityContainer>
      </ProductInstructions>
      {errors && (
        <ProductInstructions>
          <ErrorContainer>
            <span>{errorMessage}</span>
          </ErrorContainer>
        </ProductInstructions>
      )}
      <ProductOptionFooter>
        <CancelContainer>
          <StyledButton className="cancel" onClick={props.closeOption}>
            {ProductOptionsText.CANCEL_BUTTON_TEXT}
          </StyledButton>
        </CancelContainer>

        <AddContainer>
          <StyledButton
            className="addToCart"
            onClick={(e) => handleAddToCart(e)}
          >
            {ProductOptionsText.ADD_BUTTON_TEXT}{" "}
            {Number(total).toFixed(2)}€
          </StyledButton>
        </AddContainer>
      </ProductOptionFooter>
    </ProductOptionsContainer>
  );
};

export default ProductOptions;
