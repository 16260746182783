import moment from "moment";
//console.log(moment().isoWeekday()); // returns 1-7 where 1 is Monday and 7 is Sunday
import { API } from "../constants/env";
const verifyDelivery = () =>
  new Promise(async (resolve, reject) => {
    return fetch(API.settings.load, {
      method: "POST",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        key: "DELIVERY",
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        if (response.err) {
          resolve(false);
        }
        resolve(response.active);
      })
      .catch((e) => reject(e.err));
  });

const verifyOrder = () =>
  new Promise((resolve, reject) => {
    return fetch(API.settings.load, {
      method: "POST",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        key: "GENERAL",
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        let format = "hh:mm";
        if (response.err) {
          resolve(false);
        }
        const todayIndex = moment().isoWeekday();
        const now = moment();
        const currentDay = response.planningCommade[todayIndex - 1];
        const beforeTime = moment(currentDay.from, format);
        const afterTime = moment(currentDay.to, format);
        resolve(now.isBetween(beforeTime, afterTime));
      })
      .catch((e) => reject(e.err));
  });
const verifyDeliveryTime = (time) =>
  new Promise((resolve, reject) => {
    return fetch(API.settings.load, {
      method: "POST",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        key: "DELIVERY",
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        const format = "hh:mm";
        if (response.err) {
          resolve(false);
        }
        const todayIndex = moment().isoWeekday();
        const now = moment(time, format);
        const currentDay = response.planning[todayIndex - 1];
        const beforeTime = moment(currentDay.from, format);
        const afterTime = moment(currentDay.to, format);
        resolve({
          canDeliver: now.isBetween(beforeTime, afterTime),
          planDelivery: currentDay,
        });
      })
      .catch((e) => reject(e.err));
  });
const verifyOpenClose = (time) =>
  new Promise((resolve, reject) => {
    return fetch(API.settings.load, {
      method: "POST",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        key: "GENERAL",
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        const format = "hh:mm";
        if (response.err) {
          resolve(false);
        }
        const todayIndex = moment().isoWeekday();
        const pickupTime = moment(time, format);
        const currentDay = response.planningOpenClose[todayIndex - 1];
        const beforeTime = moment(currentDay.from, format);
        const afterTime = moment(currentDay.to, format);
        resolve({
          canPickup: pickupTime.isBetween(beforeTime, afterTime),
          planOpenClose: currentDay,
        });
      })
      .catch((e) => reject(e.err));
  });

export { verifyDelivery, verifyOpenClose, verifyDeliveryTime, verifyOrder };
