import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { setSubHeader } from "../../store/actions/stickyNavbarAction";
import Contact from "../../components/ContactUs/index";
import { SubHeaderText } from "../../constants/texts";
import { images, API, STATIC } from "../../constants/env";
const ContactPage = (props) => {
  const [restaurantLocation, setRestaurantLocation] = useState({});
  const [siteInfo, setSiteInfo] = useState(
    localStorage.getItem("siteInfo")
      ? JSON.parse(localStorage.getItem("siteInfo"))
      : {}
  );
  const menus = [
    {
      link: "/",
      title: "Home",
    },
    {
      link: "/contact",
      title: "Contact",
    },
  ];
  const [content, setContent] = useState(
    localStorage.getItem("CONTACT")
      ? JSON.parse(localStorage.getItem("CONTACT"))
      : {}
  );

  useEffect(() => {
    const fetchData = () => {
      fetch(API.content.load, {
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: "CONTACT",
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (JSON.stringify(response) !== localStorage.getItem("CONTACT")) {
            localStorage.setItem("CONTACT", JSON.stringify(response));
            setContent(response);
          }
        })
        .catch((e) => {});
    };
    fetchData();
  }, []);
  React.useEffect(() => {
    props.setSubHeader(
      true,
      content.title,
      STATIC.images(content.subHeaderImage),
      menus
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);
  React.useEffect(() => {
    const fetchData = async () => {
      await fetch(API.settings.load, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key: "GENERAL",
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.err) {
            console.log(response.err);
          }
          if (response.location) {
            setRestaurantLocation(response.location);
          }
          if (JSON.stringify(response) !== localStorage.getItem("siteInfo")) {
            localStorage.setItem("siteInfo", JSON.stringify(response));
            setSiteInfo(response);
          }
        })
        .catch((e) => console.log(e));
    };
    fetchData();
  }, []);

  return (
    <Contact
      location={restaurantLocation}
      siteInfo={siteInfo}
      content={content}
    >
      This is contact page
    </Contact>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSubHeader: (show, title, bgImage, menus) => {
      dispatch(setSubHeader(show, title, bgImage, menus));
    },
  };
};
export default connect(null, mapDispatchToProps)(ContactPage);
