import React from "react";
import {
  LoginContainer,
  AuthDescription,
  AuthForm,
  AuthDescriptionContent,
  LoginSection,
} from "../Login/style";

import { useForm } from "react-hook-form";
import { ForgetPasswordText } from "../../constants/texts";
import { loginFormErrors } from "../../constants/errors";
import { StyledButton } from "../CustomButton/style";
import { API } from "../../constants/env";
import { resetMessage } from "../../constants/succesMessage";
import { Error } from "../FormError/style";
import { STATIC } from "../../constants/env";

const ForgetPassword = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const [showSucces, setShowSucces] = React.useState(false);
  const onSubmit = async (data, e) => {
    fetch(API.auth.reset, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json)
      .then((res) => {
        setShowSucces(true);
        e.target.reset();

        setTimeout(() => {
          setShowSucces(false);
        }, 3000);
      })
      .catch((err) => console.log(err));
  };
  return (
    <LoginSection>
      <div className="container">
        <LoginContainer>
          <AuthDescription
            image={
              props.content.image ? STATIC.images(props.content.image) : ""
            }
          >
            <AuthDescriptionContent>
              <i className="flaticon-chili"></i>
              <h2>
                {props.content.titleImage ? props.content.titleImage : ""}
              </h2>
              <p>
                {props.content.subTitleImage ? props.content.subTitleImage : ""}
              </p>
            </AuthDescriptionContent>
          </AuthDescription>
          <AuthForm>
            <h2>{props.content.title ? props.content.title : ""}</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              {showSucces ? (
                <div className="alert alert-success" role="alert">
                  {resetMessage}{" "}
                </div>
              ) : (
                <div />
              )}
              <div className="form-group">
                <input
                  type="text"
                  className="form-control form-control-light"
                  placeholder={ForgetPasswordText.USERNAME_PLACEHOLDER}
                  name="email"
                  ref={register({
                    required: {
                      value: true,
                      message: loginFormErrors.emailRequired,
                    },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: loginFormErrors.emailValidity,
                    },
                  })}
                />
                <Error>{errors.email && errors.email.message}</Error>
              </div>

              <StyledButton className="primary">
                {props.content.buttonContent ? props.content.buttonContent : ""}
              </StyledButton>
            </form>
          </AuthForm>
        </LoginContainer>
      </div>
    </LoginSection>
  );
};

export default ForgetPassword;
