import styled, { createGlobalStyle } from "styled-components";
const BannerContainer = styled.div`
  display: block;
   
`;
const CarousselItemContent = styled.div`
  display: flex;
  min-height: 700px;
  @media (max-width: 768px) {
    height: auto;
  }

`;
const CarousselItemContentImage = styled.div`
  display: flex;
  flex: 3;
  background-image: url(${(props)=>props.bgImage});
  background-size: cover;
  @media (max-width: 1200px) {
    display: none;
  }
`;
const CarousselItemText = styled.div`
  display: flex;
  flex: 4;
  flex-direction: column;
  background-color: ${(props)=> props.theme.bannerV1.carousselItemText.bgColor};
  padding: 120px 40px;
  h1 {
    line-height: 80px;
    font-family: "Oswald", sans-serif;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 62px;
    margin: 0 0 20px;
    color: ${(props)=> props.theme.bannerV1.carousselItemText.h1.color};
    max-width: 700px;
  }
  h4 {
    font-size: 26px;
    line-height: 32px;
    font-weight: 700;
    color: ${(props)=> props.theme.bannerV1.carousselItemText.h4.color};
  }
  p {
    color: ${(props)=> props.theme.bannerV1.carousselItemText.p.color};
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 25px;
    text-align: "center";
    max-width: 700px;
  }
  a {
    display: inline-block;
    background-color: ${(props)=> props.theme.bannerV1.carousselItemText.a.bgColor};
    box-shadow: 4px 3px 24px rgba(187,208,71,0.5);
    cursor: pointer;
    text-align: center;
    border: 0;
    color: ${(props)=> props.theme.bannerV1.carousselItemText.a.color};
    font-size: 14px;
    font-weight: 600;
    padding: 12px 30px;
    transition: 0.3s;
    text-transform: uppercase;
    border-radius: 25px;
    z-index: 1;
    text-decoration: none;
  }
  @media (max-width: 1200px) {
    display: flex;
    flex: 1;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url(${(props)=>props.bgImage});
    background-size: cover;
    background-color: transparent;
    padding: 90px 20px 160px;
    padding: 90px 20px 160px;
    h1 {
      color: ${(props)=> props.theme.bannerV1.carousselItemText.mediaMaxWidth1200.color};
    }
    h4 {
      color: ${(props)=> props.theme.bannerV1.carousselItemText.mediaMaxWidth1200.color};
    }
    p {
      color: ${(props)=> props.theme.bannerV1.carousselItemText.mediaMaxWidth1200.color};
    }
  }
  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
    padding: 60px 15px;

    h1 {
      font-size: 32px;
      line-height: 40px;
    }
    h4 {
      font-size: 22px;
      line-height: 30px;
    }
    p {
      font-size: 16px;
    }
    a {
      margin: 0 auto;
    }
  }
`;

const Dots = styled.ul`
  display: flex;
  align-items: center;
  position: absolute;
  background-color: ${(props)=> props.theme.bannerV1.dots.bgColor};
  border-radius: 8px;
  left: 40px;
  bottom: 43px;
  display: block;
  padding: 15px 20px 20px;
  width: 100px;
  list-style: none;
  text-align: center;

  li {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
  }
  & li.active > button {
    background-color: ${(props)=> props.theme.bannerV1.dots.slickActiveButtonBgColor};
  }
  li button {
    background-color: ${(props)=> props.theme.bannerV1.dots.listButtonBgColor};
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    width: 12px;
    height: 12px;
  }
  li > button:before {
    content: "";
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
const GlobalStyle= createGlobalStyle`
  .swiper-container-horizontal > .swiper-pagination-bullets{
    background-color: ${(props)=> props.theme.bannerV1.dots.bgColor};
    width:100px;
    left:40px;
    bottom:43px;
    padding: 15px 20px 20px;
    border-radius: 8px;

  }
  .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    margin: 0 5px;
    background: ${(props)=> props.theme.bannerV1.dots.listButtonBgColor};


  }
  .swiper-pagination-bullet {
    background: ${(props)=> props.theme.bannerV1.dots.listButtonBgColor};

  }
  .swiper-pagination-bullet-active {
    opacity: 1;
    background: ${(props)=> props.theme.bannerV1.dots.listButtonBgColor};
}

`
export {
  CarousselItemContent,
  CarousselItemContentImage,
  CarousselItemText,
  Dots,
  BannerContainer,
  GlobalStyle
};
