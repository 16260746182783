import { createSelector } from "reselect";

const productsSelector = (state) => state.productsReducer.productsList;
const addedItemsSelector = (state) => state.cartReducer.addedItems;

const getProducts = createSelector(
  [productsSelector, addedItemsSelector],
  (products, items) => {
    let finalProducts = [];
    items.forEach((element) => {
      const currentproduct = products.find((item) => item._id === element._id);
      if (currentproduct !== undefined) {
        const product = {
          ...currentproduct,
          quantity: element.quantity,
          selectedOptions: element.selectedOptions,
          selectedSupplements: element.selectedSupplements,
          instructions: element.instructions,
        };
        finalProducts.push(product);
      }
    });
    return finalProducts;
  }
);

const getTotal = createSelector([getProducts], (products) => {
  return products.reduce((result, item) => {
    let total = 0;
    if (item.selectedOptions && item.selectedOptions.length > 0) {
      item.selectedOptions.forEach((k) => {
        if (k.items.length > 0) {
          k.items.forEach((elem) => (total += elem.price));
        }
      });
    }
    if (item.selectedSupplements && item.selectedSupplements.length > 0) {
      item.selectedSupplements.forEach((h) => {
        if (h.items.length > 0) {
          h.items.forEach((elem) => (total += elem.price));
        }
      });
    }

    return item.quantity * (item.price + total) + result;
  }, 0);
});

const getQuantityTotal = createSelector([addedItemsSelector], (items) => {
  let quantityTotal = 0;
  items.forEach((elem) => {
    quantityTotal = quantityTotal + elem.quantity;
  });
  return quantityTotal;
});
/*

const getProducts = (items, products) => {
    let finalProducts= [];
    items.forEach(element => {
        const currentproduct = products.find(item=>item._id === element._id);
        if(currentproduct !== undefined){
            const product = {
                ...currentproduct,
                quantity: element.quantity
              } 
              finalProducts.push(product);
        }
        
    });
    /*
    items.forEach(elem => {
      let currentProduct = products.find(item => item._id === elem._id);
     console.log(currentProduct);
      //currentProduct.quantity = elem.quantity;
      finalProducts.push(currentProduct);
    })
    */
/*
    return finalProducts;

  }
  */

/*

const getTotal = (items, products)=>{

 return getProducts(items, products).reduce((result, item) => item.quantity * item.price + result, 0)
}
*/

export { getTotal, getProducts, getQuantityTotal };
