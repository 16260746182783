import styled from "styled-components";

const FooterContainer = styled.footer`
  background-color: ${(props) => props.theme.footer.bgColor};
  padding: 80px 0 0;
`;
const FooterTop = styled.div`
  margin-bottom: 60px;
  @media (max-width: 769px) {
    text-align: center;
  }
  img {
    height: 75px;
  }
`;
const FooterMiddle = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  @media (max-width: 769px) {
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }
`;

const ListContainer = styled.div`
  padding: 0px 15px 60px;
  h5 {
    color: ${(props) => props.theme.footer.titleColor};
    margin-bottom: 22px;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    text-transform: uppercase;
  }
  ul {
    list-style: none;
  }
  ul li {
    margin-top: 10px;
  }
  ul li a {
    color: ${(props) => props.theme.footer.listColor};
    text-decoration: none;
    transition: 0.3s;
  }
  &.footer-widget ul li a{
    position: relative;
    display: block;
    transform: translate(-15px);
  }
  &.footer-widget ul li a::before{
    font-family: "Font Awesome 5 Free";
    content: "\f054";
    font-style: normal;
    font-variant: normal;
    font-weight: 900;
    text-rendering: auto;
    line-height: 28px;
    margin-left: auto;
    font-size: 10px;
    opacity: 0;
    visibility: hidden;
    transform: translateX(-10px);
    transition: 0.3s;
    padding-right: 10px;
  }
  &.footer-widget ul li a:hover{
    transform: translateX(0);
  }
  &.footer-widget ul li a:hover::before {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
   }
 
  ul.social-media {
    display: flex;
    margin-top: -25px;
  }
  ul.social-media li a {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    font-size: 18px;
    &.facebook {
      color: ${(props) => props.theme.footer.iconColor};
      background-color: ${(props) => props.theme.footer.facebookBgColor};
    }
    &.instagram {
      color: ${(props) => props.theme.footer.iconColor};
      background-color: ${(props) => props.theme.footer.instagramBgColor};
    }
    &.twitter {
      color: ${(props) => props.theme.footer.iconColor};
      background-color: ${(props) => props.theme.footer.twitterBgColor};
    }
    &.pinterest {
      color: ${(props) => props.theme.footer.iconColor};
      background-color: ${(props) => props.theme.footer.pinterestBgColor};
    }
  }
  p {
    max-width: 300px;
    color: ${(props) => props.theme.footer.paragraphTextColor};
    margin: 15px 0 15px;
  }
  @media (max-width: 769px) {
    text-align: center;
    ul.social-media {
      justify-content: center;
    }
  }
`;

const FooterBottom = styled.div`
  padding-bottom: 20px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);

  p {
    color: #fff;
    font-size: 15px;
    line-height: 28px;
  }
  @media (max-width: 769px) {
    text-align: center;
  }
`;
export {
  FooterContainer,
  FooterTop,
  FooterMiddle,
  FooterBottom,
  ListContainer,
};
