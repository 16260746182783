import styled from 'styled-components';

const InfographicContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    p{
        text-align:center;
    }
    i{
        color: ${(props)=> props.theme.Infographic.icon.color};
        margin-bottom: 30px;
        font-size: 60px;
    }
    h4{
        font-size: 26px;
        line-height: 32px;
        margin-bottom: 10px;
        font-weight: 700;
        line-height: 1.2;
        color: ${(props)=> props.theme.Infographic.title.color};

    }
    p{
        color: ${(props)=> props.theme.Infographic.text.color};
        font-weight: 400;
        margin-bottom: 0;
        text-align:center;
    }
`
export {InfographicContainer}