import React from "react";
import {
  LoginContainer,
  AuthDescription,
  AuthForm,
  AuthDescriptionContent,
  LoginSection,
} from "./style";
import { StyledButton } from "../CustomButton/style";
import { LoginText } from "../../constants/texts";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { loginFormErrors } from "../../constants/errors";
import { useHistory } from "react-router-dom";
import { Error } from "../FormError/style";
import { STATIC } from "../../constants/env";
const Login = (props) => {
  let history = useHistory();
  const { register, handleSubmit, errors } = useForm();
  const [buttonWidth, setButtonWidth] = React.useState("");
  const [rememberMe, setRememberMe] = React.useState(false);
  React.useEffect(() => {
    const width = document.getElementById("update").offsetWidth;
    setButtonWidth(width + "px");
  }, []);

  const onSubmit = async (data) => {
    props.handleLogin(data, history, rememberMe);
  };
  const handleChange = (e) => {
    setRememberMe(!rememberMe);
  };
  return (
    <LoginSection>
      <div className="container">
        <LoginContainer>
          <AuthDescription
            image={
              props.content.image ? STATIC.images(props.content.image) : ""
            }
          >
            <AuthDescriptionContent>
              <i className="flaticon-chili"></i>
              <h2>
                {props.content.titleImage ? props.content.titleImage : ""}
              </h2>
              <p>
                {props.content.subTitleImage ? props.content.subTitleImage : ""}
              </p>
            </AuthDescriptionContent>
          </AuthDescription>
          <AuthForm>
            <h2>{props.content.title ? props.content.title : ""}</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              {props.errorMessage && (
                <div className="alert alert-danger" role="alert">
                  {props.errorMessage}
                </div>
              )}

              <div className="form-group">
                <input
                  type="text"
                  className="form-control form-control-light"
                  placeholder={LoginText.USERNAME_PLACEHOLDER}
                  name="email"
                  ref={register({
                    required: {
                      value: true,
                      message: loginFormErrors.emailRequired,
                    },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: loginFormErrors.emailValidity,
                    },
                  })}
                />
                <Error>{errors.email && errors.email.message}</Error>
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control form-control-light"
                  placeholder={LoginText.PASSWORD_PLACEHOLDER}
                  name="password"
                  ref={register({ required: true })}
                />
                <Error>
                  {errors.password && loginFormErrors.passwordRequired}
                </Error>
              </div>
              <div className="col-12 form-group">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    checked={rememberMe}
                    onChange={handleChange}
                    id="rememberMe"
                  />
                  <label className="custom-control-label" htmlFor="rememberMe">
                    {LoginText.REMEMBER_ME_LABEL}
                  </label>
                </div>
              </div>

              <Link to="/reset" className="forget-password">
                {LoginText.FORGOT_PASSWORD}
              </Link>
              <StyledButton
                className={`primary auth-form ${
                  props.isFetching ? "loading" : ""
                }`}
                width={buttonWidth}
                id="update"
                disabled={props.isFetching ? true : false}
              >
                {props.isFetching ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : props.content.buttonContent ? (
                  props.content.buttonContent
                ) : (
                  ""
                )}
                {
                  // LoginText.LOGIN_BUTTON
                }
              </StyledButton>

              <p>
                {LoginText.DONT_HAVE_ACCOUNT}{" "}
                <Link to="/register" className="forget-password inline-link">
                  {" "}
                  {LoginText.CREATE_ACCOUNT}
                </Link>{" "}
              </p>
            </form>
          </AuthForm>
        </LoginContainer>
      </div>
    </LoginSection>
  );
};

export default Login;
