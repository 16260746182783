import styled from 'styled-components';
import {Link} from 'react-router-dom';
const TopHeaderContainer = styled.div`
    background-color: ${(props) => props.theme.topHeader.backgroundColor};

`

const TopHeaderInner = styled.div`
    display:flex;
    justify-content:space-between;
`
const TopHeaderContacts = styled.div`
    display:flex;
    padding:10px 0px; 
    `
const TopHeaderNav = styled.div`
    display:flex;
    align-content:center;
    background-color: ${(props) => props.theme.topHeader.topHeaderNav.BgColor};
    padding:10px 0px;
    &:hover{
        background-color:${(props) => props.theme.topHeader.topHeaderNav.aHoverBgColor};
    }
`
const PhoneLink = styled.a`
    color:${(props)=> props.theme.topHeader.topHeaderContacts.a.color};
    font-size:12px;
    font-weight: 700;
    text-decoration:none;
    &:hover{
        color:${(props)=> props.theme.topHeader.topHeaderContacts.a.hover};
        text-decoration:none;
    }
`
const Linkto = styled(Link)`
    color:${(props)=> props.theme.topHeader.topHeaderContacts.a.color};
    font-size:12px;
    font-weight: 700;
    text-decoration:none;
    &:hover{
        color:${(props)=> props.theme.topHeader.topHeaderContacts.a.hover};
        text-decoration:none;
    }
    &.header-cta{
        color:${(props) => props.theme.topHeader.topHeaderNav.aColor};
        font-size:12px;
        font-weight: 700;
        text-decoration:none;
        padding:0px 10px;
    }
    &.header-cta:hover{
        color:${(props) => props.theme.topHeader.topHeaderNav.aHoverColor};
    }
`



export {TopHeaderContainer, TopHeaderInner,TopHeaderContacts, TopHeaderNav,Linkto, PhoneLink  }