import React from "react";
import PayementFail from "../../components/PaymentFail/index";
function PaymentFailPage() {
  return (
    <div>
      <PayementFail />
    </div>
  );
}

export default PaymentFailPage;
