import React from "react";
import { NotFoundContainer, NotFoundText } from "../NotFound/style";
import { StyledLink } from "../CustomButton/style";
import { PaymentSucceedText } from "../../constants/texts";
import { destroyCart } from "../../store/actions/cartActions";
import { withRouter, useLocation } from "react-router-dom";
import { connect } from "react-redux";

function PaymentSucceed(props) {
  React.useEffect(() => props.destroyCart(), []);
  return (
    <NotFoundContainer>
      <NotFoundText>
        <h1>{PaymentSucceedText.TITLE_TEXT}</h1>
        <p>{PaymentSucceedText.CONTENT_TEXT}</p>
        <StyledLink to="/">{PaymentSucceedText.BUTTON_TEXT}</StyledLink>
      </NotFoundText>
    </NotFoundContainer>
  );
}
const mapStateToProps = (state) => {
  return {
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    destroyCart: () => {
      dispatch(destroyCart());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PaymentSucceed));
