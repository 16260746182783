import styled from "styled-components";

const AboutContainer = styled.div`
  position: relative;
  padding: 80px 0;
`;

const ImageWrapper = styled.div`
  img {
    border-radius: 8px;
    max-width: 100%;
    height: auto;
  }
`;
const Dots = styled.div`
  width: 100%;
  height: 100%;
  content: "";
  background-position: 0 0.6px 6px;
  background-size: 12px 12px;
  position: absolute;
  display: block;
  background-image: radial-gradient(${props => props.theme.about.dotsColor} 1px, transparent 1px),
    radial-gradient(${props => props.theme.about.dotsColor} 1px, transparent 1px);
  background-color: transparent;
  top: -17px;
  left: 17px;
  opacity: 1;
  z-index: -1;
`;
const DescriptionSection = styled.div`
    margin-left: 30px;
    h5{
        margin-bottom:10px;
        color: ${(props) => props.theme.about.descriptionSection.titleColor};
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
    }
    h2{
        font-size: 42px;
        margin-bottom: 20px;
        line-height: 48px;
        font-weight:700;
        color: ${(props) => props.theme.about.descriptionSection.subTitleColor};
    }
    p{
        max-width: 600px;
        margin-bottom: 20px;
        font-size: 16px;
        color: ${(props) => props.theme.about.descriptionSection.paragraphColor};
        line-height: 28px;
    }
`
export { AboutContainer, ImageWrapper, Dots, DescriptionSection};
