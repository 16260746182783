import styled from 'styled-components';

const Container = styled.div`
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: rgba(0, 0, 0, 0.6);
   z-index: 1500;

   &.display-block {
       display: block;
   }
   &.display-none {
    display: none;
   }

`

const ModalMain = styled.div`
    position: fixed;
    background: white;
    width: 80%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
    border-radius: 3px;
    padding: 7px;


`


export {Container,ModalMain }