import React from "react";
import {
  ProductContainer,
  ProductBody,
  ProductDescription,
  ProductControls,
  ImageContainer,
  DescriptionContent,
  Price,
} from "./style";
import { StyledButton } from "../CustomButton/style";
import { TrendingText } from "../../constants/texts";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { addToCart } from "../../store/actions/cartActions";

const Product = (props) => {
  function createMarkup() {
    return { __html: props.text };
  }
  const history = useHistory();

  return (
    <>
      <ProductContainer>
        <ImageContainer style={{ backgroundImage: `url(${props.img})` }} onClick={() => history.push(`/description/${props.item._id}`)}>
        </ImageContainer>

        <ProductBody>
          <ProductDescription>
            <h4>
              <Link to={`/description/${props.item._id}`}>{props.title}</Link>
            </h4>
            <DescriptionContent>
              <div dangerouslySetInnerHTML={createMarkup()} />
              
            </DescriptionContent>
          </ProductDescription>
          <ProductControls>
            <Price>{Number(props.price).toFixed(2)} €</Price>
            <StyledButton
              className="btn-sm notice-login"
              onClick={() => props.handleShowOptions(props.item)}
            >
              {TrendingText.PRODUCT_BUTTON}{" "}
              <i className="fas fa-shopping-cart" />
            </StyledButton>
          </ProductControls>
        </ProductBody>
      </ProductContainer>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    items: state.cartReducer.addedItems,
    todayProducts: state.productsReducer.todayProducts,
    productsList: state.productsReducer.productsList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (item) => {
      dispatch(addToCart(item));
    },
  };
};
//export default Product;
export default connect(mapStateToProps, mapDispatchToProps)(Product);
