import styled from 'styled-components'
const PreloaderContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.Preloader.bgColor};
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
`;
const Ripple = styled.div`
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    div{
        position: absolute;
        border: 4px solid ${props => props.theme.Preloader.borderColor};
        opacity: 1;
        border-radius: 50%;
        
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
        @keyframes lds-ripple {
            0% {
                top: 36px;
                left: 36px;
                width: 0;
                height: 0;
                opacity: 1;
            }
            100% {
                top: 0px;
                left: 0px;
                width: 72px;
                height: 72px;
                opacity: 0;
            }
            }

    }
    div:nth-child(2) {
        animation-delay: -0.5s;
    }
    
    
`

export {PreloaderContainer, Ripple};