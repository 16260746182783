import React, { useState } from "react";
import {
  ModalContainer,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Alert,
} from "./style";
import { CloseButton } from "../Cart/Modal/style";
import { StyledButton } from "../CustomButton/style";
import MapComponent from "../MapComponent/index";
import Geocode from "react-geocode";

const MapsModal = (props) => {
  const [errors, setErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [formatedAdress, setFormatedAdress] = useState("");
  React.useEffect(() => {
    const getLocation = async () => {
      Geocode.setApiKey("AIzaSyCep1aEs90BdL71_GmqJi5bHQgBaZDWHg4");
      Geocode.setLanguage("fr");
      Geocode.enableDebug();
      await Geocode.fromLatLng(
        props.restaurantLocation.lat,
        props.restaurantLocation.lng
      ).then(
        (res) => {
          console.log(res);
          const address = res.results[0].formatted_address;
          console.log(address);
          setFormatedAdress(address);
          const city = res.results[0].address_components.find(
            (elem) => elem.types.includes("locality") === true
          );
          console.log(city);
          const zipCode = res.results[0].address_components.find(
            (elem) => elem.types.includes("postal_code") === true
          );
          console.log(zipCode);
          setCity(city ? city.long_name : "");
          setZipCode(zipCode ? zipCode.long_name : "");
        },
        (error) => {
          console.error(error);
        }
      );
    };
    getLocation();
  }, [props.restaurantLocation.lat, props.restaurantLocation.lng]);
  const setLocation = (lat, lng, formatedAdress, city, zipCode) => {
    props.setClientLocation({ lat, lng });
    setFormatedAdress(formatedAdress);
    setCity(city ? city.long_name : "");
    setZipCode(zipCode ? zipCode.long_name : "");
  };

  const verifyDistance = () => {
    const p1 = new window.google.maps.LatLng(
      props.location.lat,
      props.location.lng
    );

    const p2 = new window.google.maps.LatLng(
      props.restaurantLocation.lat,
      props.restaurantLocation.lng
    );

    const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
      p1,
      p2
    );
    if (distance > props.zoneDistance) {
      setErrors(true);
      setErrorMessage("Desole! Notre restaurant ne livre pas à votre zone!");
    } else {
      props.confirmAddressPicker(formatedAdress, city, zipCode);
    }
  };
  return (
    <ModalContainer>
      <CloseButton className="product-options" onClick={props.closeOption}>
        <span />
        <span />
      </CloseButton>
      <ModalHeader>
        <h2>Confirmez votre adresse</h2>
      </ModalHeader>

      <ModalBody>
        <span>Drag the pin to your exact location</span>
        <div
          style={{
            width: "100%",
            height: "50vh",
            position: "relative",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <MapComponent
            modal={true}
            drag={true}
            setLocation={setLocation}
            lat={props.restaurantLocation.lat}
            lng={props.restaurantLocation.lng}
            distance={props.zoneDistance}
            delivery={props.delivery}
          />
        </div>
        {errors && (
          <Alert className="alert alert-danger" role="alert">
            {errorMessage}
          </Alert>
        )}
      </ModalBody>

      <ModalFooter>
        <StyledButton onClick={() => verifyDistance()} className="mapsModal">
          confirmer
        </StyledButton>
      </ModalFooter>
    </ModalContainer>
  );
};

export default MapsModal;
