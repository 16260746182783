import { SET_SUB_HEADER } from '../actions/action-types/cart-actions';

const initialState = {
    subHeader: false,
    title: '',
    bgImage: '',
    menus: [],
}
const stickyNavbarReducer = (
    state = initialState,
    action
) => {
    if(action.type === SET_SUB_HEADER) {
        return{
            ...state,
            subHeader: action.payload.show,
            title: action.payload.title,
            bgImage: action.payload.bgImage,
            menus: action.payload.menus,
        }
    }
    return state;
}
export default stickyNavbarReducer;


