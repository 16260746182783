import React from "react";
import { Table } from "./style";
import { CheckoutText } from "../../../constants/texts";
const OrderDetails = (props) => {
  return (
    <Table>
      <thead>
        <tr>
          <th>{CheckoutText.orderDetails.PRODUCT_TEXT}</th>
          <th>{CheckoutText.orderDetails.QUANTITY_TEXT}</th>

          <th>{CheckoutText.orderDetails.TOTAL_TEXT}</th>
        </tr>
      </thead>
      <tbody>
        {props.items.map((elem) => {
          let items = [];
          let total = 0;
          if (elem.selectedOptions) {
            elem.selectedOptions.forEach((elem) => {
              elem.items.forEach((elem, index, array) => {
                items.push(elem.name);
                items.push(" , ");
                total += elem.price;
              });
            });
          }
          if (elem.selectedSupplements) {
            elem.selectedSupplements.forEach((elem, index, array) => {
              elem.items.forEach((elem, index, array) => {
                items.push(elem.name);

                items.push(" , ");
                total += elem.price;
              });
            });
          }

          items.pop();
          return (
            <tr key={elem._id}>
              <td data-title="Product">
                <div>
                  <div>
                    <h6>
                      {" "}
                      <a href="/">{elem.name}</a>{" "}
                    </h6>
                    <span>{items}</span>
                    <span>{elem.instructions}</span>
                  </div>
                </div>
              </td>

              <td data-title="Quantity">x{elem.quantity}</td>
              <td data-title="Total">
                {" "}
                <strong>
                  {Number((elem.price + total) * elem.quantity).toFixed(2)}€
                </strong>{" "}
              </td>
            </tr>
          );
        })}
        {
          props.selectedDelivery ?
            (<tr className="total">
              <td>
                <h6 className="mb-0">{CheckoutText.orderDetails.DELIVERY_TEXT}</h6>
              </td>
              <td></td>
              <td>
                {" "}
                <strong>{props.deliveryPrice === 0 ? CheckoutText.orderDetails.FREE : `${Number.parseFloat(props.deliveryPrice).toFixed(2)}€`}</strong>{" "}
              </td>
            </tr>)
            :
            (<></>)
        }
        <tr className="total">
          <td>
            <h6 className="mb-0">{CheckoutText.orderDetails.BIG_TOTAL}</h6>
          </td>
          <td></td>
          <td>
            {" "}
            <strong>{props.selectedDelivery ? Number.parseFloat(parseFloat(props.deliveryPrice) + parseFloat(props.total)).toFixed(2) : props.total}€</strong>{" "}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default OrderDetails;
