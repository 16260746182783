import styled from 'styled-components';
const CartFormContainer = styled.div`
    margin-top: 20px;
    margin-bottom: 30px;
    h4{
        font-size: 26px;
        line-height: 32px;
        margin: 0 0 20px;
        font-family: 'Oswald', serif;
        font-weight: 700;
        color: ${(props)=>props.theme.cart.CartForm.titleColor};
    }

`
const Table = styled.table`
    width: 100%;
    margin-bottom: 30px;
    th{
        border-bottom: 1px solid ${(props)=>props.theme.cart.CartForm.table.BorderBottomColor};
        width: 200px;
        font-weight: 600;
        color: ${(props)=>props.theme.cart.CartForm.table.thColor};
        background-color: ${(props)=>props.theme.cart.CartForm.table.thBgColor};
        font-family: "Oswald", sans-serif;
        padding: 15px;
        vertical-align: middle

    }
    td{
        background-color: ${(props)=>props.theme.cart.CartForm.table.tdBgColor};
        border-bottom: 1px solid ${(props)=>props.theme.cart.CartForm.table.BorderBottomColor};
        padding: 15px;
        vertical-align: middle;

    }
`
const TextArea = styled.textarea`
    margin-bottom: 20px;
`
export {CartFormContainer, Table, TextArea}