import React from "react";
import {
  HowWeDoContainer,
  TitleSection,
  InfographicsSection,
  ButtonSection,
} from "./style";
import Infographic from "./Infographic/index";
import { HowWeDeliverText } from "../../constants/texts";
import { StyledLink } from "../CustomButton/style";
const HowWeDo = (props) => {
  const content = props.content;
  return (
    <div className="container">
      <HowWeDoContainer>
        <TitleSection>
          <h5>{content.title ? content.title : ""}</h5>
          <h2>{content.subTitle ? content.subTitle : ""}</h2>
          <p>{content.contentParagraph ? content.contentParagraph : ""}</p>
        </TitleSection>
        <InfographicsSection>
          <Infographic
            icon={HowWeDeliverText.InfographicItems.ONE.icon}
            title={content.step1Title ? content.step1Title : ""}
            text={content.step1Content ? content.step1Content : ""}
          />
          <Infographic
            icon={HowWeDeliverText.InfographicItems.TWO.icon}
            title={content.step2Title ? content.step2Title : ""}
            text={content.step2Title ? content.step2Content : ""}
          />
          <Infographic
            icon={HowWeDeliverText.InfographicItems.THREE.icon}
            title={content.step3Title ? content.step3Title : ""}
            text={content.step3Title ? content.step3Content : ""}
          />
          {/* <Infographic 
                        icon={HowWeDeliverText.InfographicItems.FOUR.icon} 
                        title={HowWeDeliverText.InfographicItems.FOUR.title}
                        text={HowWeDeliverText.InfographicItems.FOUR.text}/> */}
        </InfographicsSection>
        <ButtonSection>
          <StyledLink to="/products">
            {content.buttonContent ? content.buttonContent : ""}
          </StyledLink>
        </ButtonSection>
      </HowWeDoContainer>
    </div>
  );
};

export default HowWeDo;
