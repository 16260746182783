import React from 'react'
import {InfographicContainer} from './style';
const Infographic = (props) => {
    return (
        <InfographicContainer className="col-md-4 col-sm-6">
            <i className={props.icon}></i>
            <h4>{props.title}</h4>
            <p>{props.text}</p>
        </InfographicContainer>
    )
}

export default Infographic
