import React from "react";

import HomePage from "../pages/Home/HomePage";
import CartPage from "../pages/Cart/CartPage";
import ProductsPage from "../pages/Products/ProductsPage";
import CheckoutPage from "../pages/Checkout/CheckoutPage";
import AboutPage from "../pages/AboutUs/AboutPage";
import Contact from "../pages/Contact/Contact";
import RegisterPage from "../pages/Register/RegisterPage";
import LoginPage from "../pages/Login/LoginPage";
import AccountPage from "../pages/Account/AccountPage";
import ForgetPasswordPage from "../pages/FogetPassword/ForgetPasswordPage";
import ResetPasswordPage from "../pages/ResetPassword/ResetPasswordPage";
import ProductDescription from "../pages/ProductDescription/ProductDescription";
import NotFoundPage from "../pages/page-404/NotFoundPage";
import PaymentSucceed from "../pages/payement-succeed/PaymentSucceedPage";
import PaymentFail from "../pages/payement-fail/PaymentFailPage";

export const navbarRoutes = [
  {
    path: "/",
    name: "Accueil",
  },
  {
    path: "/products",
    name: "Notre Carte",
  },
  {
    path: "/contact",
    name: "Contact",
  },
  {
    path: "/about",
    name: "A propos",
  },
];

export const routes = [
  {
    path: "/",
    exact: true,
    main: () => <HomePage />,
  },
  {
    path: "/cart",
    main: () => <CartPage />,
  },
  {
    path: "/products",
    main: () => <ProductsPage />,
  },
  {
    path: "/checkout",
    main: () => <CheckoutPage />,
  },
  {
    path: "/description/:id",
    main: () => <ProductDescription />,
  },
  {
    path: "/about",
    main: () => <AboutPage />,
  },
  {
    path: "/contact",
    main: () => <Contact />,
  },
  {
    path: "/register",
    main: () => <RegisterPage />,
  },
  {
    path: "/login",
    main: () => <LoginPage />,
  },
  {
    path: "/account",
    main: () => <AccountPage />,
  },
  {
    path: "/reset",
    main: () => <ForgetPasswordPage />,
  },
  {
    path: "/reset-password/:id",
    exact: true,
    main: () => <ResetPasswordPage />,
  },
  {
    path: "/404",
    main: () => <NotFoundPage />,
  },
  {
    path: "/payment-succeed",
    main: () => <PaymentSucceed />,
  },
  {
    path: "/payment-fail",
    main: () => <PaymentFail />,
  },
];

export const informationFooterRoutes = [
  {
    path: "/",
    name: "Accueil",
  },
  {
    path: "/about",
    name: "A propos",
  },
  {
    path: "/contact",
    name: "Contact",
  },
];
export const OthersFooterRoutes = [
  {
    path: "/checkout",
    name: "Check-out",
  },
  {
    path: "/cart",
    name: "Carte",
  },
  {
    path: "/products",
    name: "Menu",
  },
];

export const TopItemsRoutes = [
  {
    path: "/food-description",
    name: "Perperoni",
  },
  {
    path: "/food-description",
    name: "Swiss Mushroom",
  },
  {
    path: "/food-description",
    name: "Vegetarian",
  },
];
